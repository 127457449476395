import React from 'react';
// import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Event from './Components/Event';

import {LoginScreen, SignupScreen, FindPasswordScreen, FindEmailScreen, FindEmailResultScreen, VerifyEmailScreen, KakaoLoginScreen} from './Screens/Auth';
import {IntroScreen, ProductScreen, ProductConfirmScreen, EventScreen} from './Screens/MainPage';
import {MyPageMainScreen, ResignScreen, ResignResultScreen, ModifyPasswordScreen} from './Screens/MyPage';
import AdminMainScreen from './Screens/Admin/AdminMainScreen.js';

import {useRecoilValue} from 'recoil';
import {authState} from 'GlobalState';
import {TossLoadingScreen, TossFailScreen, TossResultScreen, Terms, Privacy, VocaTerms, BusinessScreen, TravelScreen} from './Screens/Misc';


function AdminRoute() {
  const auth = useRecoilValue(authState);
  // only if user is logged in
  // if (!auth) {
  //   return <Navigate to='/auth/login?redirect=/admin' repalce/>;
  // }
  if (auth.emailVerified == false && !auth.uid.startsWith('kakao:')) {
    return <Navigate to='/verifyEmail'/>;
  }
  if (!auth.email.includes('musioenglish@akaai.kr')) {
    return <Navigate to='/'/>;
  }

  console.log('auth');

  // only if user is admin
  return <Outlet/>;
}

function UserRoute() {
  const auth = useRecoilValue(authState);
  // only if user is logged in
  if (!auth) {
    return <Navigate to='/auth/login' repalce/>;
  }
  if (auth.emailVerified == false && !auth.uid.startsWith('kakao:')) {
    return <Navigate to='/verifyEmail'/>;
  }

  return (
    <div>
      <Navbar/>
      <div className='under-nav'>
        <Outlet/>
      </div>
    </div>
  );
}

function NoUserRoute() {
  // only if user is not logged in
  // const auth = useRecoilValue(authState);

  // if (auth) {
  //   return <Navigate to='/mypage' repalce/>;
  // }
  return (
    <div>
      <Navbar/>
      <div className='under-nav'>
        <Outlet/>
      </div>
      <Footer/>
    </div>
  );

}

function DefaultRoute() {
  const {pathname} = useLocation();
  const pathData = ['/', '/product', '/eventPage', '/business', '/travel'];
  return (
    <div>
      <Navbar/>
      <div className='under-nav'>
        <Outlet/>
      </div>
      {pathData.includes(pathname) ?
        <div>
          <Footer/>
        </div>
        : null
      }
    </div>
  );
}

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<DefaultRoute/>}>
          <Route index element={<IntroScreen/>}/>
          <Route path='product' element={<ProductScreen/>}/>
          <Route path='confirmProduct' element={<ProductConfirmScreen/>}/>
          <Route path='event' element={<EventScreen/>}/>
          <Route path='eventPage' element={<Event/>}/>
          <Route path='terms' element={<Terms/>}/>
          <Route path='privacy' element={<Privacy/>}/>
          <Route path='vocaterms' element={<VocaTerms/>}/>
          <Route path='business' element={<BusinessScreen/>}/>
          <Route path='travel' element={<TravelScreen/>}/>
        </Route>

        <Route path='admin' element={<AdminRoute/>}>
          <Route index element={<AdminMainScreen/>}/>
          <Route path=':content' element={<AdminMainScreen/>}/>
        </Route>

        <Route path='mypage' element={<UserRoute/>}>
          <Route index element={<MyPageMainScreen/>}/>
          <Route path=':content' element={<MyPageMainScreen/>}/>
          <Route path='resign' element={<ResignScreen/>}/>
          <Route path='resignResult' element={<ResignResultScreen/>}/>
          <Route path ='modifyPassword' element={<ModifyPasswordScreen/>}/>
        </Route>


        <Route path='toss' element={<DefaultRoute/>}>
          <Route path='result' element={<TossResultScreen/>}/>
          <Route path='loading' element={<TossLoadingScreen/>}/>
          <Route path='fail' element={<TossFailScreen/>}/>
        </Route>

        {/* only before login */}
        <Route path='verifyEmail' element={<VerifyEmailScreen/>}/>

        <Route path='auth' element={<NoUserRoute/>}>
          <Route path='kakaologin' element={<KakaoLoginScreen/>}/>
          <Route path='login' element={<LoginScreen/>}/>
          <Route path='signup' element={<SignupScreen/>}/>
          <Route path='findpassword' element={<FindPasswordScreen/>}/>
          <Route path='findemail' element={<FindEmailScreen/>}/>
          <Route path='findemailresult' element={<FindEmailResultScreen/>}/>
          <Route path='signupscreen' element={<SignupScreen/>}/>
        </Route>
      </Routes>
    </BrowserRouter>

  );
}