import React from 'react';
import Faq from 'Components/Faq.js';
import 'Styles/Faq.css';

export default function FaqScreen () {
  return (
    <div id='mypage-faq'>
      <h3>자주 묻는 질문</h3>
      <hr/>
      <Faq/>
    </div>
  );
}