import React, {useEffect, useState} from 'react';
import {Button, Radio, TextField, Divider, FormControlLabel} from '@mui/material';
import {Dialog, DialogTitle, DialogActions, DialogContent, IconButton} from '@mui/material';
import {Navigate} from 'react-router-dom';
import {requestPaymentKey} from 'Utils/Tosspayments';
import {useRecoilValue, useRecoilState} from 'recoil';
import {currentProductState, myState, myShippingState} from 'GlobalState';
import {getRecentShipping, createShipping} from 'Utils/Shipping';
import {prettyPrice} from 'Utils/Misc';
import {createOrder} from 'Utils/Order';
import CloseIcon from '@mui/icons-material/Close';
import Postcode from 'react-daum-postcode';
import {useResponsive} from 'Hooks';
import 'Styles/Product.css';



export function ProductConfirmScreen () {
  const {device} = useResponsive();
  const [senderName, setSenderName] = useState('');
  const [senderPhone, setSenderPhone] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [receiverPhone, setReceiverPhone] = useState('');
  const [roadAddress, setRoadAddress] = useState('');
  const [addressDetail, setAddressDetail] = useState('');
  const [postcode, setPostcode] = useState('');
  const [memo, setMemo] = useState('');
  const [onceSubmitted, setOnceSubmitted] = useState(false);

  const [postcodeOpen, setPostcodeOpen] = useState(false);
  const [senderEditOpen, setSenderEditOpen] = useState(false);

  const me = useRecoilValue(myState);
  const [myShipping, setMyShipping] = useRecoilState(myShippingState);

  if (!me) {
    return <Navigate to='/auth/kakaologin?redirect=confirmProduct'/>;
  }

  const validator = {
    address: () => {
      if(addressDetail.length < 1 ) return '상세주소를 입력해 주세요.';
      return null;
    },
    phone: () => {
      if(receiverPhone.length < 1 ) return '받는 분 휴대전화를 입력해 주세요.';
      return null;
    }
  };

  const checkFormat = () => {
    if (validator.address() != null) {
      return false;
    }
    if (validator.phone() != null) {
      return false;
    }
    return true;
  };


  useEffect(() => {
    const bringRecentShipping = async () => {
      try {
        const recent = await getRecentShipping();
        if (recent) {
          setMyShipping(recent);
        }
      } catch (e) {

        if (e.response.data.error.code == 'NO_MATCHING_ITEMS') {
          return;
        }
      }
    };

    if (me) {
      setSenderName(me.lastName + me.firstName);
      // setSenderPhone(me.phoneNumber);
      setReceiverName(me.lastName + me.firstName);
      // setReceiverPhone(me.phoneNumber);
    }
    if (myShipping) {
      setSenderName(myShipping.senderName);
      // setSenderPhone(myShipping.senderPhone);
      setReceiverName(myShipping.receiverName);
      // setReceiverPhone(myShipping.receiverPhone);
      setRoadAddress(myShipping.roadAddress);
      setAddressDetail(myShipping.addressDetail);
      setPostcode(myShipping.postcode);
      setMemo(myShipping.memo);
    } else {
      bringRecentShipping();
    }
  }, [me, myShipping]);


  const currentProduct = useRecoilValue(currentProductState);

  const onPostcodeComplete = (address) => {
    setPostcodeOpen(false);
    setRoadAddress(address.roadAddress);
    setPostcode(address.zonecode);
  };

  const onSubmit = async () => {
    setOnceSubmitted(true);
    if (checkFormat() == false) {
      return ;
    }
    try {
      const shippingData = {
        senderName: senderName,
        senderPhone: senderPhone,
        receiverName: receiverName,
        receiverPhone: receiverPhone,
        roadAddress: roadAddress,
        addressDetail: addressDetail,
        postcode: postcode,
        memo: memo,
      };
      const newShipping = await createShipping(shippingData);
      if (!newShipping) {
        return null;
      }
      const orderId = `${me.id}_${Date.now()}`.replace(':', '');

      const orderData = {
        id: orderId,
        shippingId: newShipping.id,
        productId: currentProduct.id,
      };
      const orderp = await createOrder(orderData);
      if (!orderp) {
        return null;
      }
      setMyShipping(newShipping);
      await requestPaymentKey(orderId, currentProduct.name, currentProduct.price, me.lastName + me.firstName);
    } catch (e) {
      console.warn(e.response.data);
    }
  };

  function ProductDetail() {
    const {device} = useResponsive();

    const renderProductDetailWeb = () => {
      const prod = currentProduct;
      if (prod == undefined) {
        return (
          <div className='product-info-container'>
            <span>선택된 상품이 없습니다.</span>
          </div>
        );
      }
      return (
        <div className='product-info-container'>
          <div className='info-image'>
            {prod.meta?.englishType == 'travel' ?
              <img src='/images/mainPage/productTravelSmall.png'/>:
              <img src='/images/mainPage/productBusinessSmall.png'/>
            }
          </div>
          <div className='info-detail'>
            <span>{prod.meta?.englishType == 'travel'? '여행 영어' : '비즈니스 영어'}</span>
            <span>iPad{prod.meta.ipad == 'ipad64GB' ? ' Air (5세대) 64GB' : '아이패드 선택 안함'}</span>
            <span>온라인 영어수업 Kimini: {prod.meta.kimini == 0 ? '선택 안함' : `월 ${prod.meta.kimini}회`}</span>
          </div>
          <div className='info-price'>
            <span className='annual-price'>{prettyPrice(prod.price)} 원</span>
            <span>(12개월 무이자 할부 시) 매달 {prettyPrice(prod.price / 12)} 원</span>

          </div>

        </div>
      );
    };

    const renderProductDetailMobile = () => {
      const prod = currentProduct;
      if (prod == undefined) {
        return (
          <div className='product-info-container'>
            <span>선택된 상품이 없습니다.</span>
          </div>
        );
      }
      return (
        <div className='product-info-container'>
          <div className='info-detail'>
            <div className='info-image'>
              {prod.meta?.englishType == 'travel' ?
                <img src='/images/mainPageMobile/productTravelSmall_m.png'/>:
                <img src='/images/mainPageMobile/productBusinessSmall_m.png'/>
              }
            </div>
            <div className='info-product'>
              <span>{prod.meta?.englishType == 'travel'? '여행 영어' : '비즈니스 영어'}</span><br/>
              <span>iPad{prod.meta.ipad == 'ipad64GB' ? ' Air (5세대) 64GB' : ' 선택 안함'}</span><br/>
              <span>온라인 영어수업 Kimini: {prod.meta.kimini == 0 ? '선택 안함' : `월 ${prod.meta.kimini}회`}</span>
            </div>
          </div>
          <hr/>
          <div className='info-price'>
            <span>상품 금액</span>
            <span className='total-price'>{prettyPrice(prod.price)} 원</span>
          </div>
          <span className='installment-price'>(12개월 무이자 할부 시) 매달 {prettyPrice(prod.price / 12)} 원</span>
          <br/>
        </div>
      );
    };

    if (device =='mobile')
      return renderProductDetailMobile();
    else
      return renderProductDetailWeb();
  }

  if (!me) {
    return <div>user not logged in!</div>;
  }
  const renderWeb = () => {
    return (
      <div id='product-confirm-screen'>
        <h2>상품 정보</h2>
        <hr/>
        {ProductDetail()}
        <hr/>
        <div style={{marginTop: '60px'}}></div>
        <h2>배송 정보</h2>
        <hr/>
        <div className='shipping-container'>
          <div className="user-info-grid">
            <div className="info-field">
              <span>받는 분<span className='red'>*</span></span>
              <TextField

                hiddenLabel
                fullWidth
                value={receiverName}
                onChange={(e) => setReceiverName(e.target.value)}
              />
            </div>

            <div className="info-field">
              <span>휴대전화<span className='red'>*</span></span>
              <TextField
                hiddenLabel
                fullWidth
                value={receiverPhone}
                onChange={(e) => {
                  const val = e.target.value;
                  let isNum = /^\d+$/.test(val);
                  if (!val|| isNum) {
                    setReceiverPhone(val);
                  }
                }}
                required
                placeholder="휴대전화번호를 입력해 주세요."
                error={onceSubmitted && validator.phone() != null}
                helperText={onceSubmitted ? validator.phone() : undefined}
              />
            </div>


            <div className='info-field'>
              <span>배송지 주소<span className='red'>*</span></span>
              <div className="user-address-field">
                <TextField
                  hiddenLabel
                  value={postcode}
                  fullWidth
                />
                <Button
                  className='postcode-btn'
                  variant="outlined"
                  color='black'
                  onClick={() => setPostcodeOpen(true)}
                >
                우편번호
                </Button>
              </div>
            </div>

            {/* for empty space */}
            <div/>

            <TextField
              hiddenLabel
              className="user-address-specific"
              placeholder='주소'
              value={roadAddress}
            />
            <TextField
              hiddenLabel
              placeholder="상세주소를 입력하세요"
              className="user-address-specific"
              value={addressDetail}
              onChange={(e) => setAddressDetail(e.target.value)}
              error={onceSubmitted && validator.address() != null}
              helperText={onceSubmitted ? validator.address() : undefined}
            />
            <TextField
              className='g'
              placeholder='배송 메모'
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </div>


          {/* <div className="customer-info highlight-box">
            <span>주문자 정보</span><br/>

            <div style={{marginTop: '16px'}}></div>

            <span>{senderName}</span><br/>

            <div style={{marginTop: '16px'}}></div>

            <span>{senderPhone}</span><br/>

            <div style={{marginTop: '16px'}}></div>

            <Button
              className="change-sender"
              variant="outlined"
              onClick={()=> setSenderEditOpen(true)}
            >
              휴대폰 번호 변경
            </Button>
          </div> */}
        </div>


        <div style={{marginTop: '60px'}}></div>
        <h2>결제 수단</h2>
        <hr/>
        <div className="shipping-route">
          <span>결제 수단 선택</span>
          <FormControlLabel
            className="check-shipping-route"
            value="end"
            control={<Radio/>}
            label="신용/체크 카드"
          />
        </div>

        <div className='price-box highlight-box'>
          <span>상품금액</span>
          <h3>총 {prettyPrice(currentProduct?.price)} 원</h3>
        </div>

        <div className='submit-box'>
          <Button
            variant='contained'
            className='submit-btn'
            onClick={onSubmit}
            disabled={!currentProduct}
          >
          결제 진행하기
          </Button>

        </div>
        <Dialog
          open={postcodeOpen}
          onClose={() => setPostcodeOpen(false)}
        >
          <Postcode onComplete={onPostcodeComplete} />
        </Dialog>

        <SenderEditor
          open={senderEditOpen}
          onClose={() => setSenderEditOpen(false)}
          senderName={senderName}
          senderPhone={senderPhone}
          onEdit={(name, phone) => {
            setSenderName(name);
            setSenderPhone(phone);
          }}
        />

      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div id='product-confirm-screen'>
        <div className='shipping-container'>
          <div style={{marginTop:'80px'}}/>
          <h2>상품 정보</h2>
          <hr/>
          {ProductDetail()}
          <hr/>
          <div style={{marginTop:'80px'}}/>

          <span>주문자 정보</span><br/><hr/>
          <div className="customer-info">
            <span>주문하는 분</span><br/>
            <TextField
              className="input-text"
              disabled
              hiddenLabel
              fullWidth
              value={senderName}
              variant="filled"
            /><br/>
            <div style={{marginBottom:'10px'}}/>
            <span>휴대전화</span><br/>
            <div className='modify-phone'>
              <TextField
                className="input-text"
                disabled
                hiddenLabel
                value={senderPhone}
                variant="filled"
              />
              <Button
                className="change-sender"
                variant="outlined"
                onClick={()=> setSenderEditOpen(true)}
              >
            변경
              </Button>
            </div>
          </div>
          <div style={{marginBottom: '80px'}}/>
          <h2>배송 정보</h2>
          <hr/>
          <div className="user-info-grid">
            <div className="info-field">
              <span>받는 분<span className='red'>*</span></span>
              <TextField
                className="input-text"
                hiddenLabel
                fullWidth
                value={receiverName}
                onChange={(e) => setReceiverName(e.target.value)}
              />
            </div>
            <div className="info-field">
              <span>휴대전화<span className='red'>*</span></span>
              <TextField
                className="input-text"
                hiddenLabel
                fullWidth
                value={receiverPhone}
                onChange={(e) => {
                  const val = e.target.value;
                  let isNum = /^\d+$/.test(val);
                  if (!val|| isNum) {
                    setReceiverPhone(val);
                  }
                }}
                required
                placeholder="휴대전화번호를 입력해 주세요."
                error={onceSubmitted && validator.phone() != null}
                helperText={onceSubmitted ? validator.phone() : undefined}
              />
            </div>

            <div className='info-field'>
              <span>배송지 주소<span className='red'>*</span></span>
              <div className="user-address-field">
                <TextField
                  className="input-text"
                  hiddenLabel
                  value={postcode}
                  fullWidth
                />
                <Button
                  className='postcode-btn'
                  variant="outlined"
                  color='black'
                  onClick={() => setPostcodeOpen(true)}
                >
              우편번호
                </Button>
              </div>
            </div>

            {/* for empty space */}
            <div/>

            <TextField
              hiddenLabel
              className="user-address-specific"
              placeholder='주소'
              value={roadAddress}
            />
            <div style={{marginTop: '8px'}}/>
            <TextField
              hiddenLabel
              placeholder="상세주소를 입력하세요"
              className="user-address-specific"
              value={addressDetail}
              onChange={(e) => setAddressDetail(e.target.value)}
              error={onceSubmitted && validator.address() != null}
              helperText={onceSubmitted ? validator.address() : undefined}
            />
            <div style={{marginTop: '16px'}}/>
            <span className="delivery-memo">배송메모</span><br/>
            <TextField
              className='g input-text'
              fullWidth
              placeholder='배송 메모'
              value={memo}
              multiline
              rows={4}
              onChange={(e) => setMemo(e.target.value)}
            />
            <div style={{marginBottom: '80px'}}/>
          </div>
        </div>

        <h2>결제 수단</h2>
        <hr/>
        <div className="shipping-route">
          <span>결제 수단 선택</span>
          <FormControlLabel
            className="check-shipping-route"
            value="end"
            control={<Radio/>}
            label="신용/체크 카드"
          />
        </div>

        <div className='price-box highlight-box'>
          <span>상품금액</span>
          <h3>총 {prettyPrice(currentProduct?.price)} 원</h3>
        </div>

        <div className='submit-box'>
          <Button
            variant='contained'
            className='submit-btn'
            onClick={onSubmit}
            disabled={currentProduct == undefined ? window.scrollTo({top:500, behavior:'smooth'}) : false}
          >
            결제 진행하기
          </Button>

        </div>
        <Dialog
          open={postcodeOpen}
          onClose={() => setPostcodeOpen(false)}
        >
          <Postcode onComplete={onPostcodeComplete} />
        </Dialog>

        <SenderEditor
          open={senderEditOpen}
          onClose={() => setSenderEditOpen(false)}
          senderName={senderName}
          senderPhone={senderPhone}
          onEdit={(name, phone) => {
            setSenderName(name);
            setSenderPhone(phone);
          }}
        />

      </div>
    );
  };
  if (device == 'mobile')
  // if (width < 500)
    return renderMobile();
  else
    return  renderWeb();

}

function SenderEditor({open, onClose, senderName, senderPhone, onEdit}) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    setName(senderName);
    setPhone(senderPhone);
  }, [senderName, senderPhone]);

  const onSubmit = () => {
    onEdit(name, phone);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth="xs"
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon/>
      </IconButton>
      <DialogTitle>휴대폰 번호 수정</DialogTitle>
      <DialogContent>
        <TextField
          hiddenLabel
          fullWidth
          value={phone}
          onChange={(e) => {
            const val = e.target.value;
            let isNum = /^\d+$/.test(val);
            if (!val|| isNum) {
              setPhone(val);
            }
          }}
        />
      </DialogContent>
      <Divider/>
      <DialogActions sx={{ margin: 1 }}>
        <Button
          color='cancel'
          variant='contained'
          onClick={() => onClose()}
        >
          취소
        </Button>
        <Button
          variant='contained'
          onClick={() => onSubmit()}
        >
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}