import React, {Fragment} from 'react';
import 'Styles/MyPage.css';
import {Button} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import ProductInfoScreen from './ProductInfoScreen';
import OrderManagementScreen from './OrderManagementScreen';
import UserManagementScreen from './UserManagementScreen';
import StudyDownloadLogScreen from './StudyDownloadLogScreen';
import StibeeLogScreen from './StibeeLogScreen';


const paneList = [
  {
    path: 'productInfo',
    title: '상품 정보',
  },
  {
    path: 'orderManagement',
    title: '구매자 관리',
  },
  {
    path: 'userManagement',
    title: '가입자 관리',
  },
  {
    path: 'studyDownloadLog',
    title: '파일다운로드 관리',
  },
  {
    path: 'stibeeLog',
    title: '스티비메일 발송 로그',
  },
];


export default function AdminMainScreen() {
  const {pathname} = useLocation();
  const navigate = useNavigate();

  function renderMenuItem(menu) {
    const isSelected = pathname.includes(menu.path);
    return (
      <Fragment>
        <Button
          variant='text'
          onClick={() => navigate(`/admin/${menu.path}`)}
          className={isSelected ? 'menu-btn selected' : 'menu-btn'}
          fullWidth
          size='large'
        >
          {menu.title}
        </Button>
        <hr/>
      </Fragment>
    );
  }

  function renderContent() {
    if (pathname.includes('productInfo')) {
      return <ProductInfoScreen/>;
    }
    if (pathname.includes('orderManagement')) {
      return <OrderManagementScreen/>;
    }
    if (pathname.includes('userManagement')) {
      return <UserManagementScreen/>;
    }
    if (pathname.includes('studyDownloadLog')) {
      return <StudyDownloadLogScreen/>;
    }
    if (pathname.includes('stibeeLog')) {
      return <StibeeLogScreen/>;
    }
    return (
      <div>
        좌측 메뉴바에서 이용하시려는 메뉴를 클릭해주세요.
      </div>
    );
  }

  return (
    <div id='admin-main'>
      <div className='menu-container'>
        <div className='menu-pane'>
          <hr/>
          {paneList.map(e => {
            return (
              <div key={e.path}>
                {renderMenuItem(e)}
              </div>
            );
          })

          }
        </div>
      </div>

      <div style={{width: '20px'}}/>

      <div className='content-container'>
        {renderContent()}
      </div>
    </div>
  );
}