import {atom} from 'recoil';

export const myState = atom({
  key: 'me',
  default: null,
});


export const myShippingState = atom({
  key: 'myShipping',
  default: null,
});