import {server} from './Axios';
import { getAuthHeader } from './Auth';

export async function signupMail(user_data) {
  const rsp = await server.post('/signup-mail', user_data, {headers: getAuthHeader()});
  return rsp.data;
}

export async function sendShippingMail(user_data) {
  const rsp = await server.post('/admin', user_data, {headers: getAuthHeader()});
  return rsp.data;
}