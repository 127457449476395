import React, {useEffect, useState, useRef} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import {IconButton, Button} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';
import Aos from 'aos';
import {useResponsive} from 'Hooks';
import Faq from 'Components/Faq';
import {SlideImage} from 'Components/SlideImage';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'aos/dist/aos.css';
import 'Styles/MainPage.css';

var section1 = [
  '/images/mainPageMobile/main1IntroSlider1_m.svg',
  '/images/mainPageMobile/main1IntroSlider2_m.svg',
  '/images/mainPageMobile/main1IntroSlider3_m.svg',
  '/images/mainPageMobile/main1IntroSlider4_m.svg',
  '/images/mainPageMobile/main1IntroSlider5_m.svg',
  '/images/mainPageMobile/main1IntroSlider6_m.svg'
];

var section2 = [
  '/images/mainPageMobile/mainAddIntroSlider1_m.svg',
  '/images/mainPageMobile/mainAddIntroSlider2_m.svg',
  '/images/mainPageMobile/mainAddIntroSlider3_m.svg'
];

// var section3 = [
//   '/images/mainPageMobile/main2IntroSlider1_m.svg',
//   '/images/mainPageMobile/main2IntroSlider2_m.svg',
//   '/images/mainPageMobile/main2IntroSlider3_m.svg'
// ];

// var section4 = [
//   'images/mainPageMobile/main7IntroSlider1_m.png',
//   'images/mainPageMobile/main7IntroSlider2_m.png'
// ];

export function IntroScreen() {
  const {device} = useResponsive();
  const faqRef = useRef(null);
  const worksheetRef = useRef(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const delay = 10;
    // console.log('this is search param', searchParams);
    const tab = searchParams.get('tab');
    // console.log('tab=', tab);
    if (tab == 'faq') {
      setTimeout(
        () => faqRef.current?.scrollIntoView({ behavior: 'smooth' }),
        delay,
      );
    } else if (tab == 'worksheet') {
      setTimeout(
        () => worksheetRef.current?.scrollIntoView({ behavior: 'smooth' }),
        delay,
      );
    } else {
      setTimeout(
        () => window.scrollTo({top: 0, behavior: 'smooth'}),
        delay,
      );
    }
  }, [searchParams.get('tab')]);

  useEffect(() => {
    Aos.init({duration: 1000});
    Aos.refresh();
  }, []);

  const renderWeb = () => {
    return (
      <div id='intro-screen'>
        <UnderlineBox/>
        <div className='intro-slider'>
          <Carousel showStatus={false} showThumbs={false}>
            {/* event slide */}
            <div style={{cursor: 'pointer'}} onClick={() => navigate('/eventPage')}>
              <img src='/images/mainPage/welcomeEventMain.svg'></img>
            </div>
            {/* slide 1 */}
            <div>
              <video playsInline={true} autoPlay muted loop className="back-video">
                <source src="/videos/introSlider2.mp4" type="video/mp4"/>
              </video>
              {/* <img src='/images/mainPage/introSlider1Text.svg' className='intro-text'/>
              <div className='title-container'>
                <span className='sub-title'>뮤지오</span><br/>
                <span className='title'>
                안드로메 <br/>
                학습지 <br/>
                </span>
              </div> */}
            </div>


            {/* slide 2 */}
            <div>
              <video playsInline={true} autoPlay muted loop className="back-video">
                <source src="/videos/introSlider3wide.mp4" type="video/mp4"/>
              </video>
            </div>
            {/* <div>
              <img className='intro-image' src='/images/mainPage/introSlider2.png'/>
              <video playsInline={true} autoPlay muted loop className="front-video">
                <source src="/videos/introSlider3.mp4" type="video/mp4"/>
              </video>
            </div> */}

            {/*  slide 3 */}
            <div>
              <img src='/images/mainPage/introSlider3.png'/>
            </div>
          </Carousel>
        </div>


        {/* main1 */}
        <div className='section main1' >
          <img src='/images/mainPage/introMain1.svg' className='img-bg limit-img'/>
        </div>

        {/* main add */}
        <div className='section main-add'>
          <div className='limit-section'>
            <img src='/images/mainPage/introMainAddText.svg' className='main-add-text'/>
            <div className='gift-img'>
              <img src='/images/mainPage/introMainAddGift1.svg' className='gift-img-specific'/>
              <img src='/images/mainPage/introMainAddGift2.svg' className='gift-img-specific'/>
              <img src='/images/mainPage/introMainAddGift3.svg' className='gift-img-specific'/>
            </div>
          </div>
        </div>

        {/*blog review*/}
        <div className='section blog-review'>
          <div className='limit-section'>
            <img src='/images/mainPage/blogReview.svg' className='img-bg limit-img'/>
          </div>
          <div
            style={{
              position: 'absolute',
              left: '55%',
              top: '37%',
              transform: 'translate(-50%, 0)',
              zIndex: 10,
            }}>
            <Button
              className='review-btn'
              variant='contained'
              size='small'
              sx={{pl: 3, pr: 3, borderRadius: 2}}
              onClick={() => window.open('https://blog.naver.com/royal309/222931529799')}
            >
              후기 자세히 보기
            </Button>
          </div>
          <div
            style={{
              position: 'absolute',
              left: '55%',
              top: '62.5%',
              transform: 'translate(-50%, 0)',
              zIndex: 10,
            }}>
            <Button
              className='review-btn'
              variant='contained'
              size='small'
              sx={{pl: 3, pr: 3, borderRadius: 2}}
              onClick={() => window.open('https://blog.naver.com/ahyun35/222931531316')}
            >
              후기 자세히 보기
            </Button>
          </div>
          <div
            style={{
              position: 'absolute',
              left: '55%',
              top: '88%',
              transform: 'translate(-50%, 0)',
              zIndex: 10,
            }}>
            <Button
              className='review-btn'
              variant='contained'
              size='small'
              sx={{pl: 3, pr: 3, borderRadius: 2}}
              onClick={() => window.open('https://blog.naver.com/sailorsaturn/222930718160')}
            >
              후기 자세히 보기
            </Button>
          </div>
        </div>

        {/* main2 */}
        {/* <div className='section main2'>
          <div className='limit-section'>
            <div data-aos='fade-up'
              className='popup-box'
              style={{
                top: '15%',
                width: '25%',
                left: '38%',
              }}
            >
              <img className='popup-text' src='/images/mainPage/introMain2Text1.svg'/>
            </div>

            <div style={{paddingTop:'300px'}}/>

            <div className='ranking-img'>
              <img src='/images/mainPage/introMain2Img1.svg' className='ranking-img-specific'/>
              <img src='/images/mainPage/introMain2Img2.png' className='ranking-img-specific'/>
              <img src='/images/mainPage/introMain2Img3.png' className='ranking-img-specific'/>
            </div>
            <div style={{marginTop: '40px'}}/>
            <div className="ranking-text">
              <h2>한정 수량 소진 시 조기 종료될 수 있으니 빠르게 신청하세요!</h2>
              <Button
                className='purchase-btn'
                variant='contained'
                size='small'
                sx={{pl: 3, pr: 3, borderRadius: 2}}
                onClick={() => navigate('/product')}
              >
              지금 바로 구매하기 &gt;
              </Button>
              <h3>비즈니스 학습자의 경우, 돈과 대박이 흐르는 실리콘밸리(샌프란시스코)로 변경 가능</h3>
              <span>*결제를 마친 수강자분들 중 이벤트 응모자에 한해 추첨합니다.</span>
            </div>
          </div>
        </div> */}

        {/* main3 */}
        {/*div for  https://chana.tistory.com/entry/Scroll-Magic-Failed-to-execute-removeChild-on-Node-%EC%97%90%EB%9F%AC-%ED%95%B4%EA%B2%B0 */}
        {/* <div>
          <Controller>
            <Scene pin triggerHook='onLeave'>
              <div className='section' style={{zIndex: 0}}>
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '65%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 10,
                  }}
                >
                  <a
                    className='pretty-btn'
                    style={{
                      padding: '15px',
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      fontSize: '24px',
                      zIndex: 10,
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/product')}
                  >
                지금 바로 구매하기 〉
                  </a>
                </div> */}
        {/* popup text 1 */}
        {/* <div data-aos='fade-right'
                  className='popup-box'
                  style={{
                    top: '20%',
                    width: '40%',
                    left: '30%',
                  }}
                >
                  <img className='popup-text' src='/images/mainPage/introMain3Text1.png'/>
                </div>

                <img src='/images/mainPage/introMain3.png' className='img-bg'/>
              </div>
            </Scene>
          </Controller>

        </div> */}


        {/* main4 */}
        <div className='section main4' >
          <img src='/images/mainPage/introMain4.png' className='img-bg limit-img'/>
        </div>

        {/* main5 */}
        <div className='section main5' ref={worksheetRef}>
          {/* popup text 1 */}
          <div className='limit-section'>
            <div data-aos='fade-right'
              className='popup-box'
              style={{
                top: '27%',
                width: '28%',
                left: '18%',
              }}
            >
              <img className='popup-text' src='/images/mainPage/introMain5Text1.svg'/>
            </div>

            {/* popup text 2 */}
            <div data-aos='fade-right' data-aos-delay='200'
              className='popup-box'
              style={{
                top: '64%',
                width: '28%',
                right: '15%',
              }}
            >
              <img className='popup-text' src='/images/mainPage/introMain5Text2.svg'/>
            </div>
            <img src='/images/mainPage/introMain5.svg' className='img-bg'/>
          </div>
        </div>


        {/* <div className='section main6'>

          <div className='limit-section'>
            <div data-aos='fade-up'
              className='popup-box'
              style={{
                top: '35%',
                width: '20.3%',
                left: '20%',
              }}
            >
              <img className='popup-text' src='/images/mainPage/introMain6Text1.png'/>
            </div>


            <div data-aos='fade-up' data-aos-delay='200'
              className='popup-box'
              style={{
                top: '35%',
                width: '29%',
                right: '16%',
              }}
            >
              <img className='popup-text' src='/images/mainPage/introMain6Text2.png'/>
              <div style={{marginBottom:'200px'}}/>
            </div>
            <img src='/images/mainPage/introMain6.png' className='img-bg'/>
          </div>
        </div> */}

        <div className='section main7'>
          <div className='limit-section'>
            <img src='/images/mainPage/introMain7.png' className='img-bg'/>
          </div>
        </div>

        <div className='section'>
          <div className='limit-section'>
            <img src='/images/mainPage/introMain8.svg' className='img-bg'/>
          </div>
        </div>

        <div className='section main9'>
          <div className='limit-section'>
            <img src='/images/mainPage/introMain9.png' className='img-bg'/>
            <video playsInline={true} autoPlay muted loop className="orgel-video">
              <source src="/videos/AppVideo.mp4" type="video/mp4"/>
            </video>
            <a href="https://apps.apple.com/kr/app/andromeda/id6443482041?l=en">
              <img src='/images/mainPage/downloadApp.svg' className='download-app'/>
            </a>
          </div>
          <div className='limit-section'>
            <img src='/images/mainPage/introMain9-2.svg' className='img-bg'/>
            <a href="https://apps.apple.com/kr/app/andromeda-vocab/id694965626?l=en">
              <img src='/images/mainPage/downloadApp2.svg' className='download-app'/>
            </a>
          </div>
        </div>

        <div className='section main10'>
          <div className='limit-section'>
            <img src='/images/mainPage/introMain10.svg' className='img-bg'/>
            <a href='https://youtube.com/shorts/i_VMms0_KKc?feature=share'>
              <img className='figure-link' src='/images/mainPage/figureLink.svg'/>
            </a>
            <video playsInline={true} autoPlay muted loop className="orgel-video">
              <source src="/videos/introSlider3.mp4" type="video/mp4"/>
            </video>
            <img className='orgel-text' src='/images/mainPage/orgelText.svg'/>
            <a href='https://apps.apple.com/kr/app/%EC%98%A4%EB%A5%B4%EA%B3%A8-3d-%EB%94%94%EC%A7%80%ED%84%B8-%ED%94%BC%EA%B7%9C%EC%96%B4/id1475872737'>
              <img className='orgel-link' src='/images/mainPage/orgelLink.svg'/>
            </a>

          </div>
        </div>

        <div className='section main11'>
          <div className='limit-section'>
            <img src='/images/mainPage/introMain11.svg' className='img-bg'/>
            <div
              style={{
                position: 'absolute',
                left: '26%',
                top: '65%',
                transform: 'translate(-50%, 0)',
                zIndex: 10,
              }}>
              <Button
                className='purchase-btn'
                variant='contained'
                size='small'
                sx={{pl: 3, pr: 3, borderRadius: 2}}
                onClick={() => navigate('/product')}
              >
              지금 바로 구매하기 &gt;
              </Button>
            </div>
          </div>
        </div>


        {/* <div className='section main12'>
          <div className='limit-section'>
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '80%',
                transform: 'translate(-50%, 0)',
                zIndex: 10,
              }}>
              <a
                className='pretty-btn'
                style={{
                  padding: '20px',
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  fontSize: '24px',
                  zIndex: 10,
                }}
                onClick={() => navigate('/product')}
              >
                지금 바로 구매하기 〉
              </a>
            </div>
            <img src='/images/mainPage/introMain12.png' className='img-bg'/>
            <div style={{marginBottom:'-50px'}}/>
          </div>
        </div> */}
        <div style={{marginBottom:'-50px'}}/>

        <div className='section faq-section'>
          <h1 ref={faqRef}>자주 묻는 질문</h1>
          <div className="faq">
            <hr/>
            <Faq/>
          </div>
        </div>

      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div id='intro-screen'>
        <UnderlineBox/>
        <div className='intro-slider'>
          <Carousel
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            stopAutoPlayOnHover={true}
            infiniteLoop={true}
            swipeable={false}
          >
            {/* event slide */}
            <div onClick={() => navigate('/eventPage')}>
              <img src='/images/mainPageMobile/welcomeEventMain_m.svg'></img>
            </div>
            {/* slide 1 */}
            <div>
              {/* <div className='title-container'> */}
              {/* <div style={{marginTop: '40px'}}/> */}
              {/* <span className='title'>
                  뮤지오 안드로메다 학습지
                </span> */}
              {/* </div> */}
              {/* <div style={{marginTop:'70px'}}/> */}
              <video playsInline={true} autoPlay muted loop className="back-video">
                <source src="/videos/introSlider2_m.mp4" type="video/mp4"/>
              </video>
            </div>

            {/*  slide 2 */}
            <div>
              {/* <img src='/images/mainPageMobile/introSlider3_m.png'/> */}
              <video playsInline={true} autoPlay muted loop className="back-video">
                <source src="/videos/introSlider3_m.mp4" type="video/mp4"/>
              </video>
            </div>

            {/*  slide 3 */}
            <div>
              <img src='/images/mainPageMobile/introSlider2_m.png'/>
            </div>
          </Carousel>
        </div>

        {/* main1 */}
        <div className='section main1'>
          <div style={{paddingTop: '80px'}}/>
          <span>안드로메다 팩</span>
          <div style={{paddingTop: '20px'}}/>
          <div>
            <div className='normal'>아래 6가지 구성품이 안드로메다 팩에 <span style={{fontSize: '15px',
              textDecoration: 'underline'}}>모두 포함</span>됩니다. </div>
            <div style={{paddingTop: '10px'}}/>
            <div className='normal'>화상 영어 수업의 경우, 추가 구매 항목입니다.</div>
          </div>

          <div style={{paddingTop: '40px'}}/>
          <SlideImage name={section1}/>
          <div style={{paddingTop: '40px'}}/>
          <img src='/images/mainPageMobile/introSection1Background_m.svg'/>
        </div>

        {/* main add */}
        <div className='section main-add'>

          <div style={{paddingTop: '80px'}}/>
          <span>지금 구입하면, <br/>추가 행사 상품 증정!</span>

          <div style={{marginTop: '20px'}}/>
          <SlideImage name={section2}/>

          <div style={{paddingTop: '60px'}}/>
        </div>

        {/*blog review*/}
        <div className='section blog-review'>
          <div className='limit-section'>
            <img src='/images/mainPageMobile/blogReview_m.svg' className='img-bg limit-img'/>
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '32.5%',
                transform: 'translate(-50%, 0)',
                zIndex: 10,
              }}>
              <Button
                className='review-btn'
                variant='contained'
                size='small'
                sx={{pl: 3, pr: 3, borderRadius: 2}}
                onClick={() => window.open('https://blog.naver.com/royal309/222931529799')}
              >
              후기 자세히 보기
              </Button>
            </div>
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '59.7%',
                transform: 'translate(-50%, 0)',
                zIndex: 10,
              }}>
              <Button
                className='review-btn'
                variant='contained'
                size='small'
                sx={{pl: 3, pr: 3, borderRadius: 2}}
                onClick={() => window.open('https://blog.naver.com/ahyun35/222931531316')}
              >
              후기 자세히 보기
              </Button>
            </div>
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '86.9%',
                transform: 'translate(-50%, 0)',
                zIndex: 10,
              }}>
              <Button
                className='review-btn'
                variant='contained'
                size='small'
                sx={{pl: 3, pr: 3, borderRadius: 2}}
                onClick={() => window.open('https://blog.naver.com/sailorsaturn/222930718160')}
              >
              후기 자세히 보기
              </Button>
            </div>
          </div>
        </div>

        {/* main2  하와이 부분 주석처리*/}
        {/* <div className='section main2'>

          <div style={{paddingTop: '80px'}}/>
          <span>&apos;니가 가라 하와이!&apos;</span>

          <div style={{marginTop: '20px'}}/>
          <SlideImage name={section3}/>

          <div style={{paddingTop:'10px'}}/>
          <span><span>한정 수량 소진 시 조기 종료될 수 있으니<br/>빠르게 신청하세요!</span></span>

          <div style={{paddingTop: '60px'}}/>
        </div> */}

        {/* main3 */}
        {/* <div className='section main3'>

          <div style={{paddingTop: '80px'}}/>
          <span className="header">하와이 티켓, 내가 갖겠어.</span>

          <div style={{paddingTop: '24px'}}/>
          <span className="title">한정 수량 소진 시 조기 종료될 수 있으니<br/>
            빠르게 신청하세요!
          </span>

          <div style={{paddingTop: '40px'}}/>
          <a
            className='pretty-btn'
            style={{
              padding: '15px',
              paddingLeft: '30px',
              paddingRight: '30px',
              fontSize: '18px',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/product')}
          >
                지금 바로 구매하기 〉
          </a>
          <div style={{paddingTop: '24px'}}/>
          <span className="text">비즈니스를 선택한 학습자의 경우, <br/>
            샌프란시스코 (실리콘벨리) 변경 가능
          </span>

          <div style={{paddingTop: '80px'}}/>
        </div> */}
        <div className='section main4'>
          <img src='/images/mainPageMobile/main4_m.svg' className='mobile-section'/>
        </div>

        <div className='section main5'>
          <img src='/images/mainPageMobile/main5_m.svg' className='mobile-section'/>
        </div>

        {/* <div className='section main6' ref={worksheetRef}>
          <div style={{paddingTop: '80px'}}/>
          <span className='header'>실전에 강한 학습지.</span>
          <div style={{paddingTop: '80px'}}/>
        </div> */}

        {/* <div className='section main7'>
          <SlideImage name={section4}/>
          <div style={{paddingTop: '80px'}}/>
        </div> */}

        <div className='section main8'>
          <img src='/images/mainPageMobile/main8_m.png' className='mobile-section'/>
        </div>

        <div className='section main9'>
          <img src='/images/mainPageMobile/main9_m.svg' className='mobile-section'/>
        </div>

        <div className='section main10'>
          <img src='/images/mainPageMobile/main10_m.svg' className='mobile-section'/>
          <video playsInline={true} autoPlay muted loop className="orgel-video">
            <source src="/videos/AppVideo_M.mp4" type="video/mp4"/>
          </video>
          <a href="https://apps.apple.com/kr/app/andromeda/id6443482041?l=en">
            <img src='/images/mainPageMobile/downloadApp_m.svg' className='download-app'/>
          </a>
          <img src='/images/mainPageMobile/main10-2_m.svg' className='mobile-section'/>
          <a href="https://apps.apple.com/kr/app/andromeda-vocab/id694965626?l=en">
            <img src='/images/mainPageMobile/downloadApp2_m.svg' className='download-app'/>
          </a>
        </div>

        <div className='section main11'>
          <img src='/images/mainPageMobile/main11_m.svg' className='mobile-section'/>
          <a href='https://youtube.com/shorts/i_VMms0_KKc?feature=share'>
            <img className='figure-link' src='/images/mainPage/figureLink.svg'/>
          </a>
          <video playsInline={true} autoPlay muted loop className="front-video">
            <source src="/videos/introSlider3.mp4" type="video/mp4"/>
          </video>
          <div style={{marginTop:'-30px'}}/>
          <a href='https://apps.apple.com/kr/app/%EC%98%A4%EB%A5%B4%EA%B3%A8-3d-%EB%94%94%EC%A7%80%ED%84%B8-%ED%94%BC%EA%B7%9C%EC%96%B4/id1475872737'>
            <img src='/images/mainPageMobile/orgelDownloadBlack_m.svg'/>
          </a>
        </div>

        <div className='section main12'>
          <img src='/images/mainPageMobile/main12_m.svg' className='mobile-section'/>
          <a
            className='pretty-btn'
            onClick={() => navigate('/product')}
          >
                지금 바로 구매하기 〉
          </a>
        </div>

        <div className='section main13' ref={faqRef}>
          <div style={{paddingTop:'80px'}}/>
          <span className="header">자주 묻는 질문</span>
          <div style={{paddingTop:'40px'}}/>
          <Faq/>
          <div style={{paddingTop:'80px'}}/>
        </div>
      </div>
    );
  };

  // return renderMobile();
  if (device == 'mobile')
  // if (width < 500)
    return renderMobile();
  else
    return  renderWeb();
}


function UnderlineBox () {
  const [closed, setClosed] = useState(false);
  const navigate = useNavigate();
  function onClose() {
    setClosed(true);
  }
  if (closed) {
    return null;
  }
  return (
    <div id='underline-box'>
      <span>신규 회원 0원 혜택!</span>
      <div style={{width: '20px'}}/>
      <a
        className="pretty-btn custom-btn"
        onClick={() => navigate('/product')}
      >
        혜택받고 시작하기
      </a>
      <IconButton
        aria-label="close"
        className='close-btn'
        onClick = {() => {onClose();}}
      >
        <CloseIcon sx={{color: '#888888'}}/>
      </IconButton>
    </div>
  );
}