import {atom} from 'recoil';

export const snackbarState = atom({
  key: 'snackbar',
  default: {
    'severity': 'warning', // ['error', 'warning', 'info', 'success']
    'message': 'this is snackbar',
    'isOpen': false,
  },
});

// export function showSnackbar(message, severity='success') {
//   const [snackbar, setSnackbar] = useRecoilState(snackbarState);
//   setSnackbar({...snackbar, severity: severity, message: message, isOpen: true});
// }


