import React from 'react';
import 'Styles/Travel.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TextbookSlide } from 'Components/SlideImage';
import {useResponsive} from 'Hooks';

const textbook = [
  '/images/mainPage/travelTextbook1.svg',
  '/images/mainPage/travelTextbook2.svg',
  '/images/mainPage/travelTextbook3.svg',
  '/images/mainPage/travelTextbook4.svg'
];
const textbookM = [
  '/images/mainPageMobile/travelTextbook1_m.svg',
  '/images/mainPageMobile/travelTextbook2_m.svg',
  '/images/mainPageMobile/travelTextbook3_m.svg',
  '/images/mainPageMobile/travelTextbook4_m.svg'
];

export function TravelScreen() {
  const {device} = useResponsive();

  const renderWeb = () => {
    return (
      <div id='travel-screen'>
        <div className='section main1'>

        </div>
        <div className='section main2'>
          <img src='/images/mainPage/travelMain2.svg'/>
        </div>
        <div className='section main3'>
          <img src='/images/mainPage/travelMain3-1.svg'/>
          <img src='/images/mainPage/travelMain3-2.svg'/>
        </div>
        <div className='section main4'>
          <TextbookSlide name={textbook} autoplay={false}/>
        </div>
        <div className='section main5'>
          <img src='/images/mainPage/travelMain5.svg'/>
        </div>
        <div className='section main6'>
          <img src='/images/mainPage/travelMain6.svg'/>
        </div>
        <div className='section main7'>
          <img src='/images/mainPage/travelMain7.svg'/>
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div id='travel-screen'>
        <div className='section main1'>
          <img src='/images/mainPageMobile/travelMain1_m.svg'/>
        </div>
        <div className='section main2'>
          <img src='/images/mainPageMobile/travelMain2_m.svg'/>
        </div>
        <div className='section main3'>
          <TextbookSlide name={textbookM} autoplay={false}/>
        </div>
        <div className='section'>
          <img src='/images/mainPageMobile/travelMain4_m.svg'/>
        </div>
      </div>
    );
  };

  if (device == 'mobile')
  // if (width < 500)
    return renderMobile();
  else
    return  renderWeb();

}