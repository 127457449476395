import {server} from './Axios';
import {getAuthHeader} from './Auth';

export async function getOrder(id) {
  const rsp = await server.get('/order', {headers: getAuthHeader(), params: {id: id}});
  return rsp.data;
}

export async function createOrder(orderData) {
  const rsp = await server.post('/order', orderData, {headers: getAuthHeader()});
  return rsp.data;
}

export async function updatePaymentOrder(orderId, amount, paymentKey) {
  const data = {
    'orderId': orderId,
    'amount': amount,
    'paymentKey': paymentKey
  };
  const rsp = await server.post('/updatePayment', data, {headers: getAuthHeader()});
  return rsp.data;
}

export async function updateOrder(shippingId, updateData) {
  const body = {
    shippingId: shippingId,
    updateData: updateData,
  };
  const rsp = await server.put('/order', body, {headers: getAuthHeader()});
  return rsp.data;
}

export async function listCustomerOrder() {
  const rsp = await server.post('/order/listCustomer', {}, {headers: getAuthHeader()});
  return rsp.data;
}