import React, {useState, useEffect} from 'react';
import {Button} from '@mui/material';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getOrder} from 'Utils/Order';
import {kiminiNumToPlan, kiminiRegister, kiminiRevoke} from 'Utils/Kimini';
import Order from 'Components/Order';
import {authState, myState} from 'GlobalState';
import {useRecoilValue} from 'recoil';
import 'Styles/TossResult.css';

// Result url 은 토스 잘못(잔액부족 카드 금지 등)

export function TossResultScreen() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [order, setOrder]  = useState();

  const auth = useRecoilValue(authState);
  const me = useRecoilValue(myState);

  useEffect(() => {
    const mGetOrder = async () => {
      const orderId = searchParams.get('orderId');
      try {
        const newOrder = await getOrder(orderId);
        // console.log(newOrder);
        setOrder(newOrder);
      } catch (e) {
        console.warn(e);
      }
    };
    if (auth) {
      mGetOrder();
    }
  }, [auth]);

  // success product loaded callback
  useEffect(() => {
    const mRegisterKimini = async () => {
      const plan = kiminiNumToPlan(order?.product.meta?.kimini);
      if (!plan) {
        return;
      }
      try {
        await kiminiRegister(plan, me.lastName, me.firstName, me.email);
        const nextYear = new Date().setFullYear(new Date().getFullYear() + 1);
        await kiminiRevoke(nextYear);
      } catch (e) {
        console.warn(e);
      }
    };
    if (order?.isSuccess) {
      mRegisterKimini();
    }
  }, [order]);

  const renderSuccess = () => {
    return (
      <div id='toss-result-screen'>
        <h1>결제가 완료되었습니다.</h1>

        <div style={{margin: '10px'}}/>

        <h3>주문 내역</h3>
        <hr/>

        {
          order ? <Order data={order}/> : null
        }
        <div style={{marginBottom: '24px'}}/>
        <span className='ipad-terms'>※iPad와 안드로메다 팩은 별도로 배송됩니다. <br/>영업일 3-5일 이내, iPad와 안드로메다 팩을 수령하실 수 있습니다.</span><br/>
        <div style={{marginBottom: '40px'}}/>

        <div className='action-container'>
          <Button
            variant='contained'
            className='go-btn'
            onClick={() => navigate('/mypage')}
          >
          마이페이지 이동
          </Button>
        </div>
      </div>
    );
  };

  const renderFail = () => {
    return (
      <div id='toss-result-screen'>
        <h1>다음과 같은 이유로 결제에 실패하였습니다.</h1>

        <div style={{margin: '10px'}}/>
        <div>{JSON.stringify(order?.tossRsp, null, 2)}</div>
      </div>
    );
  };

  if (!order) {
    return (
      <div>loading..</div>
    );
  } else if (order.isSuccess) {
    return renderSuccess();
  } else {
    return renderFail();
  }
}