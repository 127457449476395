import {server} from './Axios';
import { getAuthHeader } from './Auth';


export async function updateProduct(productId, updateData) {
  const body = {
    productId: productId,
    updateData: updateData,
  };
  const rsp = await server.put('/product', body, {headers: getAuthHeader()});
  return rsp.data;

}