import {snackbarState} from 'GlobalState';
import {useRecoilState} from 'recoil';

export function useSnackbar() {
  const [snackbar, setSnackbar] = useRecoilState(snackbarState);

  const showSnackbar = (message, severity='success') => {
    setSnackbar({...snackbar, severity: severity, message: message, isOpen: true});
  };

  return showSnackbar;
}