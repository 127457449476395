import {server} from './Axios';
import {signInCustomToken} from 'Utils/Auth.js';


async function getKakaoLogin(code) {
  const rsp = await server.get('/kakao', {params: code});
  return rsp.data;
}

export async function kakaoAuth(code) {
  const userData = await getKakaoLogin(code);
  await signInCustomToken(userData);
}