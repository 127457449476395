import React from 'react';
import 'Styles/MyPageCheckOrder.css';
import Divider from '@mui/material/Divider';
import {prettyPrice} from 'Utils/Misc';
import {useResponsive} from 'Hooks';

export default function Order({data}) {
  const {device} = useResponsive();
  const product = data.product;
  const shipping = data.shipping;
  const renderWeb = () => {
    return (
      <div className='orderp-container'>
        <div className='detail-container'>
          <div className='detail-image'>
            {product.meta?.englishType == 'travel' ?
              <img className='full' src='/images/mainPage/productTravelSmall.png'/>:
              <img className='full' src='/images/mainPage/productBusinessSmall.png'/>
            }
          </div>
          <div className='detail-box'>
            <span>{ product.meta?.englishType == 'travel'? '여행 영어' : '비즈니스 영어'}</span>
            <span>{ product.meta?.ipad == 'ipad64GB'? 'iPad Air(5세대) 64GB' : 'iPad 선택 안함'}</span>
            <span>온라인 영어수업 Kimini: 월 {product.meta?.kimini}회</span>

          </div>
          <div className='price-box'>
            <span  className='blue'>결제 완료</span>
            <span>{prettyPrice(product.price)}<span className='price-detail'> 원</span></span>
          </div>
        </div>
        <span>배송지 주소: {shipping.roadAddress} {shipping.addressDetail}</span>
        <span className="delivery-memo">배송 메모: {shipping.memo}</span>
        <Divider/>
      </div>
    );
  };
  const renderMobile = () => {
    return (
      <div className='orderp-container'>
        <div className='detail-container'>
          <div className='detail-image'>
            {product.meta?.englishType == 'travel' ?
              <img className='full' src='/images/mainPage/productTravelSmall.png'/>:
              <img className='full' src='/images/mainPage/productBusinessSmall.png'/>
            }
          </div>
          <div className='detail-box'>
            <span>{ product.meta?.englishType == 'travel'? '여행 영어' : '비즈니스 영어'}</span>
            <span>{product.meta?.ipad}</span>
            <span>온라인 영어수업 Kimini: 월 {product.meta?.kimini}회</span>

          </div>
        </div>
        <span>배송지 주소: {shipping.roadAddress} {shipping.addressDetail}</span>
        <span className="delivery-memo">배송 메모: {shipping.memo}</span>
        <div className='price-box'>
          <span  className='blue'>결제 완료 &nbsp;</span>
          <span>{prettyPrice(product.price)}<span className='price-detail'> 원</span></span>
        </div>
        <Divider/>
      </div>
    );
  };
  // return renderMobile();
  if (device == 'mobile')
  // if (width < 500)
    return renderMobile();
  else
    return renderWeb();
}