import {atom} from 'recoil';

export const productListState = atom({
  key: 'productList',
  default: [],
});

export const currentProductState = atom({
  key: 'currentProduct',
  default: undefined,
});




