import React from 'react';

export default function MarketingTerms() {
  return (
    <div style={{margin:'50px'}}>
      <h1 style={{fontSize:'21px'}}>마케팅 정보 수신 동의</h1>
      <h3>제 1조 광고성 정보의 이용목적</h3>
      <h4>주식회사 아카에이아이 (이하 “회사” 또는 “당사”)는 회원에게 다음의 정보를 제공합니다.</h4>
      <ul>
        <li>
          <p>이용자 맞춤형 서비스 및 상품 추천, 각종 경품 행사, 이벤트 등의 광고성 정보</p>
        </li>
      </ul>
      <h4>또한, 당사는 다음의 방식을 통해 이용자에게 상기 정보를 제공합니다.</h4>
      <ul>
        <li>
          <p> 전자우편이나 서신우편, 문자(SMS, 카카오톡, 카카오 알림톡 등), 푸시, 전화 등</p>
        </li>
      </ul>
      <h3>제 2조 개인정보 수집 항목</h3>
      <h4>당사는 제 1조의 이용목적을 위해 다음의 개인정보를 수집합니다.</h4>
      <ul>
        <li>
          <p>이름, 이메일 (서비스 ID), 휴대전화번호, 배송주소</p>
        </li>
      </ul>
      <h3>제 3조 미동의 시 불이익 사항</h3>
      <h4>개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의 거부하시더라도 서비스 이용에 제한되지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.</h4>
      <h3>제 4조 보유 및 이용기간</h3>
      <h4>당사는 이용자가 동의를 철회하거나, 회원 탈퇴 시까지 수집정보를 보유 및 이용합니다.</h4>

      <p>마케팅 정보 수신 동의 버전정보 : 1.0</p>
    </div>



  );

}