import {useState, useEffect} from 'react';

const brSm = 480;
const brMd = 768;
// const brLg = 1024;
// const brXl = 1440;

export function useResponsive() {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    const w = window.innerWidth;
    setWidth(w);
  };

  const getDevice = () => {
    if (width < brSm) {
      return 'mobile';
    } else if (width < brMd) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return {width: width, device: getDevice()};
}