import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputAdornment} from '@mui/material';
import {Button, TextField, Dialog, DialogTitle, DialogActions, DialogContent} from '@mui/material';
import {productListState} from 'GlobalState';
import {useRecoilState} from 'recoil';
import {listProduct} from 'Utils/Default';
import {updateProduct} from 'Utils/Product';


export default function ProductInfoScreen() {
  const [productList, setProductList] = useRecoilState(productListState);
  const [clickedProduct, setClickedProduct] = useState(null);

  const onRefresh = async () => {
    try {
      setProductList([]);
      const data = await listProduct();
      if (data) {
        setProductList(data);
      }
    } catch (e) {
      console.warn(e);
    }
  };


  return (
    <div>
      <Button variant='contained' onClick={onRefresh}>
        상품 다시 불러오기
      </Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>name</TableCell>
              <TableCell>description</TableCell>
              <TableCell>price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productList.map((row) => (
              <TableRow key={row.id} onClick={() => setClickedProduct(row)}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdateProductDialog
        clickedProduct={clickedProduct}
        onClose={() => setClickedProduct(null)}
        onUpdate={() => {
          onRefresh();
          setClickedProduct(null);
        }}
      />
    </div>
  );
}


function UpdateProductDialog({clickedProduct, onClose, onUpdate}) {
  const [description, setDescription] = useState();
  const [name, setName] = useState();
  const [price, setPrice] = useState();

  useEffect(() => {
    if (clickedProduct) {
      setPrice(clickedProduct.price);
      setDescription(clickedProduct.description);
      setName(clickedProduct.name);
    }
  }, [clickedProduct]);

  const onApply = async () => {
    if (!clickedProduct) return;
    try {
      const updateData = {
        price: price,
        name: name,
        description: description,
      };
      const data = await updateProduct(clickedProduct.id, updateData);
      if (data && onUpdate) {
        onUpdate();
      }
    } catch (e) {
      console.warn(e);
    }
  };


  if (!clickedProduct) {
    return null;
  }
  return (
    <Dialog id='product-info-update' open={clickedProduct != null} onClose={onClose}>
      <DialogTitle>{clickedProduct.id}</DialogTitle>
      <DialogContent>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='price'
            value={price}
            type='number'
            onChange={(e) => setPrice(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">won</InputAdornment>
            }}

          />
        </div>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='description'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={() => onApply()}
        >
            적용
        </Button>
      </DialogActions>
    </Dialog>
  );
}