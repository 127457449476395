import React, {useState, useEffect} from 'react';
import {TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl} from '@mui/material';
import {  LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {useNavigate} from 'react-router-dom';
import {myState} from 'GlobalState';
import {useSnackbar} from 'Hooks';
import {useRecoilState} from 'recoil';
import {updateCustomer} from 'Utils/Customer';
import { useResponsive } from 'Hooks';

import 'Styles/MyPageUserInfo.css';


export default function UpdateuserInfoScreen() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState(null);

  const [me, setMe] = useRecoilState(myState);
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();

  const {device} = useResponsive();

  useEffect(() => {
    if (me) {
      setFirstName(me.firstName);
      setLastName(me.lastName);
      setEmail(me.email);
      setPhoneNumber(me.phoneNumber);
      setGender(me.gender);
      setDob(me.dob ? new Date(me.dob) : null);
    }
  }, [me]);

  const onSubmit = async () => {
    const customerData = {
      'firstName' : firstName,
      'lastName': lastName,
      'phoneNumber': phoneNumber,
      'email': email,
      'dob': dob,
      'gender': gender,
    };
    try {
      const newMe = await updateCustomer(customerData);
      console.log(newMe);
      if (newMe) {
        setMe(newMe);
        showSnackbar('수정이 완료되었습니다.');
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const renderWeb = () => {
    return (
      <div id="update-info-screen">
        <h3>기본 정보</h3>
        <hr/>
        <div className="user-info-grid">
          <div className="user-info-namefield">
            <div className="username">
              <span>성</span>
              <TextField
                hiddenLabel
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="username">
              <span>이름</span>
              <TextField
                hiddenLabel
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="user-info-field">
            <span>휴대전화</span>
            <TextField
              hiddenLabel
              fullWidth
              value={phoneNumber}
              onChange={(e) => {
                const val = e.target.value;
                let isNum = /^\d+$/.test(val);
                if (!val || isNum) {
                  setPhoneNumber(val);
                }
              }}
            />
          </div>

          <div className="user-info-field">
            <span>이메일</span>
            <TextField
              hiddenLabel
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="user-info-field">
            <span>비밀번호</span>
            <div className="user-info-password">
              <TextField
                hiddenLabel
                fullWidth
                disabled
                defaultValue="***********"
                variant="filled"
              />
              <Button
                className="modify-password"
                onClick={() => navigate('/mypage/modifyPassword')}
              >
              수정
              </Button>
            </div>
          </div>
        </div>

        <div style={{marginTop: '80px'}}/>

        <h3>배송 주소</h3>
        <hr/>


        <div style={{marginTop: '24px'}}/>
        <span className='gray'>첫 결제 이후 주소지 변경을 원하신다면 카카오톡 상담으로 문의해주세요.</span>

        <div style={{marginTop: '80px'}}/>
        <h3>추가 정보</h3>
        <hr/>

        <div style={{marginTop: '24px'}}/>

        <span className="gray">성별</span>
        <div className="user-extra-field">
          <FormControl>
            <RadioGroup
              row
              onChange={(e) => setGender(e.target.value)}
              value={gender}
            >
              <FormControlLabel
                value="male"
                control={<Radio/>}
                label="남자"/>
              <FormControlLabel
                value="female"
                control={<Radio/>}
                label="여자"/>
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{marginTop: '24px'}}/>

        <span className="gray">생년월일</span>

        <div style={{marginBottom:'8px'}}/>

        <div className="user-extra-field">
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                openTo="year"
                views={['year', 'month', 'day']}
                hiddenLabel
                value={dob}
                onChange={(newValue) => setDob(newValue)}
                renderInput={(params) =>
                  <TextField {...params} helperText={null} sx={{width:'483px'}}/>}
              />
            </LocalizationProvider>
          </FormControl>

        </div>
        <div className="user-extra-field">
          <Button
            variant="contained"
            className="modify-button"
            onClick={() => onSubmit()}
          >
          수정완료
          </Button>
          <a onClick={() => navigate('/mypage/resign')}><u>계정탈퇴</u></a>
        </div>
      </div>
    );
  };
  const renderMobile = () => {
    return (
      <div id="update-info-screen">
        <h3>기본 정보</h3>
        <hr/>
        <div className="user-info-grid">
          <div className="user-info-namefield">
            <div className="username">
              <span>성</span>
              <TextField
                hiddenLabel
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="username">
              <span>이름</span>
              <TextField
                hiddenLabel
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div><br/>
          <div className="user-info-field">
            <span>휴대전화</span>
            <TextField
              hiddenLabel
              fullWidth
              value={phoneNumber}
              onChange={(e) => {
                const val = e.target.value;
                let isNum = /^\d+$/.test(val);
                if (!val || isNum) {
                  setPhoneNumber(val);
                }
              }}
            />
          </div><br/>

          <div className="user-info-field">
            <span>이메일</span>
            <TextField
              hiddenLabel
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <br/>
          <div className="user-info-field">
            <span>비밀번호</span>
            <div className="user-info-password">
              <TextField
                hiddenLabel
                fullWidth
                disabled
                defaultValue="***********"
                variant="filled"
              />
              <Button
                className="modify-password"
                onClick={() => navigate('/mypage/modifyPassword')}
              >
              수정
              </Button>
            </div>
          </div>
        </div>

        <div style={{marginTop: '80px'}}/>

        <h3>배송 주소</h3>
        <hr/>


        <div style={{marginTop: '24px'}}/>
        <span className='gray'>첫 결제 이후 주소지 변경을 원하신다면 카카오톡 상담으로 문의해주세요.</span>

        <div style={{marginTop: '80px'}}/>
        <h3>추가 정보</h3>
        <hr/>

        <div style={{marginTop: '24px'}}/>

        <span className="gray">성별</span>
        <div className="user-extra-field">
          <FormControl>
            <RadioGroup
              row
              onChange={(e) => setGender(e.target.value)}
              value={gender}
            >
              <FormControlLabel
                value="male"
                control={<Radio/>}
                label="남자"/>
              <FormControlLabel
                value="female"
                control={<Radio/>}
                label="여자"/>
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{marginTop: '24px'}}/>

        <span className="gray">생년월일</span>

        <div style={{marginBottom:'8px'}}/>

        <div className="user-extra-field">
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                openTo="year"
                views={['year', 'month', 'day']}
                hiddenLabel
                value={dob}
                onChange={(newValue) => setDob(newValue)}
                renderInput={(params) =>
                  <TextField {...params} helperText={null} sx={{width:'328px'}}/>}
              />
            </LocalizationProvider>
          </FormControl>

        </div>
        <div className="user-extra-field">
          <Button
            variant="contained"
            className="modify-button"
            onClick={() => onSubmit()}
          >
          수정완료
          </Button>
          <a onClick={() => navigate('/mypage/resign')}><u>계정탈퇴</u></a>
        </div>
      </div>
    );
  };
  // return renderMobile();
  if (device == 'mobile')
  // if (width < 500)
    return renderMobile();
  else
    return  renderWeb();
}