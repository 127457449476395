import axios from 'axios';
import {SERVER_URL, QR_URL} from '../config';


export const server = axios.create({
  baseURL: SERVER_URL,
});

export const qr = axios.create({
  baseURL: QR_URL
});