import React from 'react';
import Order from 'Components/Order';
import {useRecoilValue} from 'recoil';
import {myOrderState} from 'GlobalState';


export default function CheckOrderScreen() {
  const orderList = useRecoilValue(myOrderState);

  if (!orderList || orderList?.length == 0) {
    return (
      <div id='check-order-screen'>
        <h2>수강 신청 내역이 없습니다.</h2>
      </div>
    );
  }
  return (
    <div id='check-order-screen'>
      <h3>수강 신청 내역</h3>
      <hr/>
      {orderList.map((e) => {
        return (<Order key={e.id} data={e}/>);
      })}
    </div>
  );

}