import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import {Button} from '@mui/material';
import {downloadListState} from 'GlobalState';
import {useRecoilState} from 'recoil';
import {listDownload, convertTime} from 'Utils/Default';


export default function StudyDownloadLogScreen() {
  const [downloadList, setDownloadList] = useRecoilState(downloadListState);

  const onRefresh = async () => {
    try {
      setDownloadList([]);
      const data = await listDownload();
      if (data) {
        setDownloadList(data);
      }
    } catch (e) {
      console.warn(e);
    }
  };


  return (
    <div>
      <Button variant='contained' onClick={onRefresh}>
        다운로드 목록 다시 불러오기
      </Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>name</TableCell>
              <TableCell>download_at</TableCell>
              <TableCell>file_url</TableCell>
              <TableCell>phone_number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {downloadList.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.customer_id}</TableCell>
                <TableCell>{row.last_name}</TableCell>
                <TableCell>{row.download_at != null ? convertTime(row.download_at) : row.download_at}</TableCell>
                <TableCell>{row.file_url}</TableCell>
                <TableCell>{row.phone_number}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}