
export const grammarWorkbook = [
  {
    'section': '0',
    'title': '인트로',
    'full': 'https://youtu.be/uSPOakwNjc0',
    'shorts': null,
    'fileUrl': '/workbooks/intro.pdf',
  },
  {
    'section': '1',
    'title': '명사의 단수와 복수',
    'shorts': 'https://youtube.com/shorts/Afapirkz0f8',
    'full': 'https://youtu.be/vdV6J1fbhvY',
    'fileUrl': '/workbooks/week1.pdf',
  },
  {
    'section': '2',
    'title': '불규칙 명사',
    'shorts': 'https://youtube.com/shorts/cBSBEvJh2Jk',
    'full': 'https://youtu.be/9UTEm9S8viM',
    'fileUrl': '/workbooks/week2.pdf',
  },
  {
    'section': '3',
    'title': '셀 수 있는 명사와 셀 수 없는 명사',
    'shorts': 'https://youtube.com/shorts/37K-ttKa-jE',
    'full': 'https://youtu.be/uapn7swJM6I',
    'fileUrl': '/workbooks/week3.pdf',
  },
  {
    'section': '4',
    'title': '관사 A / An',
    'shorts': 'https://youtube.com/shorts/HNzF33CkXL0',
    'full': 'https://youtu.be/Vkypbgl3JHE',
    'fileUrl': '/workbooks/week4.pdf'
  },
  {
    'section': '5',
    'title': '관사 The',
    'shorts': 'https://youtube.com/shorts/TsQcO1bRWjw',
    'full': 'https://youtu.be/yX_63-Pwlog',
    'fileUrl': '/workbooks/week5.pdf',
  },
  {
    'section': '6',
    'title': '주격 인칭대명사',
    'shorts': 'https://youtube.com/shorts/-iOkANrTUDI',
    'full': 'https://youtu.be/lezBP4qwwek',
    'fileUrl': '/workbooks/week6.pdf',
  },
  {
    'section': '7',
    'title': '목적격 인칭대명사',
    'shorts': 'https://youtube.com/shorts/GWpRV-RYh2U',
    'full': 'https://youtu.be/7GgTjGK-Cdg',
    'fileUrl': '/workbooks/week7.pdf',
  },
  {
    'section': '8',
    'title': '다재다능한 It',
    'shorts': 'https://youtube.com/shorts/WFwjOcndGmg',
    'full': 'https://youtu.be/uNyjtgBcMuY',
    'fileUrl': '/workbooks/week8.pdf',
  },
  {
    'section': '9',
    'title': '지시대명사 This / That',
    'shorts': 'https://youtu.be/MRQv8AOM7tc',
    'full': 'https://youtu.be/SZqfnlqpxSA',
    'fileUrl': '/workbooks/week9.pdf',
  },
  {
    'section': '10',
    'title': '지시대명사 There / Those',
    'shorts': 'https://youtube.com/shorts/-RwJ2tDdyaA',
    'full': 'https://youtu.be/K9HbjZSBUwA',
    'fileUrl': '/workbooks/week10.pdf',
  },
  {
    'section': '11',
    'title': '문장형식1: 주어, 동사, 보충어',
    'shorts': 'https://youtube.com/shorts/VIGgvrMdrWg',
    'full': 'https://youtu.be/jUIdwGES0WM',
    'fileUrl': '/workbooks/week11.pdf',
  },
  {
    'section': '12',
    'title': '문장형식2: 주어, 동사, 목적어',
    'shorts': 'https://youtube.com/shorts/Js_zkAlx7QQ',
    'full': 'https://youtu.be/ISf6oH_SRv8',
    'fileUrl': '/workbooks/week12.pdf',
  },
  {
    'section': '13',
    'title': '숫자: 기수와 서수',
    'shorts': 'https://youtube.com/shorts/dfdp6Z73ufM',
    'full': 'https://youtu.be/lBInaPb7EY8',
    'fileUrl': '/workbooks/week13.pdf',
  },
  {
    'section': '14',
    'title': 'Be동사',
    'shorts': 'https://youtube.com/shorts/Qtsq-NS3eKA',
    'full': 'https://youtu.be/amtFMeX1ubA',
    'fileUrl': '/workbooks/week14.pdf',
  },
  {
    'section': '15',
    'title': 'Be동사 부정문',
    'shorts': 'https://youtube.com/shorts/MG16TKzPLsA',
    'full': 'https://youtu.be/c9m138xdXnk',
    'fileUrl': '/workbooks/week15.pdf',
  },
  {
    'section': '부록',
    'title': '부록',
    'shorts': null,
    'full': null,
    'fileUrl': '/workbooks/appendix.pdf'
  }
];

export const imageWordbook = [
  {
    'section': 'No.1',
    'title': '이미지 단어장 1권',
    'fileUrl': '/workbooks/imageVoca1.pdf',
  },
  // {
  //   'section': 'No.2',
  //   'title': '이미지 단어장 2권',
  //   'fileUrl': 'https://youtu.be/I12k1wfzEzA',
  // },
  // {
  //   'section': 'No.3',
  //   'title': '이미지 단어장 3권',
  //   'fileUrl': 'https://youtu.be/I12k1wfzEzA',
  // }
];