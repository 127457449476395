import React, {useState, useEffect, useRef} from 'react';
import {Button, Divider} from '@mui/material';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {FacebookShareButton, TwitterShareButton} from 'react-share';
import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import {useResponsive} from 'Hooks';



import 'Styles/Event.css';


export default function Event() {
  const navigate = useNavigate();
  const {device} = useResponsive();
  const currentUrl = window.location.href;
  const [copyUrlOpen, setCopyUrlOpen] = useState();
  const benefit1Ref = useRef(null);
  const benefit2Ref = useRef(null);
  const benefit3Ref = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const [scrollActive, setScrollActive] = useState(false);
  const [benefitActive, setBenefitActive] = useState([false,false,false]);
  const [searchParams] = useSearchParams();

  // const handleScroll = () => {
  //   setScrollY(window.scrollY);
  //   if((scrollY > 850 && device != 'mobile') || (scrollY > 533 && device == 'mobile')){
  //     setScrollActive(true);
  //   } else {
  //     setScrollActive(false);
  //   }
  //   if(scrollActive){
  //     if((scrollY > 870 && scrollY < 1933 && device != 'mobile') ||
  //       (scrollY > 533 && scrollY < 1540 && device == 'mobile')){
  //       setBenefitActive([true, false, false]);
  //     } else if((scrollY > 1933 && scrollY < 3088 && device != 'mobile') ||
  //       (scrollY > 1540 && scrollY < 2373 && device == 'mobile')){
  //       setBenefitActive([false, true, false]);
  //     } else if((scrollY > 3005 && device != 'mobile') ||
  //       (scrollY > 2373 && device == 'mobile')){
  //       setBenefitActive([false, false, true]);
  //     } else {
  //       setBenefitActive([false, false, false]);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   function scrollListener() {
  //     window.addEventListener('scroll', handleScroll);
  //   }
  //   scrollListener();
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // });
  // const handleCopyClipBoard = async (text) => {
  //   try {
  //     await navigator.clipboard.writeText(text);
  //   }
  // catch (error) {
  //   alert('복사에 실패하였습니다.');
  //   }
  // };
  const handleCopyClipBoard = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  useEffect(() => {
    const delay = 10;
    // console.log('this is search param', searchParams);
    const tab = searchParams.get('tab');
    // console.log('tab=', tab);
    if (tab == 'benefit1Ref') {
      setTimeout(
        () => benefit1Ref.current?.scrollIntoView({ behavior: 'smooth' }),
        delay,
      );
    } else if (tab == 'benefit2Ref') {
      setTimeout(
        () => benefit2Ref.current?.scrollIntoView({ behavior: 'smooth' }),
        delay,
      );
    } else if (tab == 'benefit3Ref') {
      setTimeout(
        () => benefit3Ref.current?.scrollIntoView({ behavior: 'smooth' }),
        delay,
      );
    } else {
      setTimeout(
        () => window.scrollTo({top: 0, behavior: 'smooth'}),
        delay,
      );
    }
  }, [searchParams.get('tab')]);

  // useEffect(() => {
  //   if(userQRImage) {
  //     setQrImage(userQRImage);
  //   }
  // },[]);

  // useEffect(() => {
  //   fetch('https://eb8751kgxb.execute-api.ap-northeast-1.amazonaws.com/api/qr')
  //     .then(res => {
  //       console.log(res);
  //       return res.json;
  //     })
  //     .then(qrImage => {
  //       setQrImage(qrImage);
  //     });
  // },[]);



  const renderWeb = () => {
    return (
      <div id='hawaii-event'>
        <div className='hawaii-header'>
          <div style={{marginBottom: '100px'}}/>
          <span className='title'>신규 회원 웰컴 이벤트</span>
          <div className='sns-row'>
            <FacebookShareButton url={currentUrl}>
              <img className='facebook-icon' src='/images/misc/facebookIcon.png' />
            </FacebookShareButton>
            <TwitterShareButton url={currentUrl}>
              <img className='twitter-icon' src='/images/misc/twitterIcon.png'/>
            </TwitterShareButton>
            <Button onClick={() => {
              handleCopyClipBoard(currentUrl);
              setCopyUrlOpen(true);
            }}>
              <img className='link-icon' src='/images/misc/linkIcon.png'/>
            </Button>
            <Dialog
              open={copyUrlOpen}
              onClose={() => setCopyUrlOpen(false)}
              fullWidth
              maxWidth="xs"
            >
              <div style={{marginBottom:'40px'}}/>
              <DialogTitle
                sx={{
                  background:'#F6F8FA',
                  margin:'0 20px 0 20px',
                  textAlign:'center'
                }}
              >
          URL이 복사 되었습니다.
              </DialogTitle>
              <div style={{marginBottom:'10px'}}/>
              <Divider sx={{ background:'#F6F8FA'}}/>
              <DialogActions sx={{ margin: 1 }}>
                <Button
                  variant='contained'
                  onClick={() => setCopyUrlOpen(false)}
                >
            확인
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <br/>

          <div style={{marginBottom: '24px'}}/>

          <span><span>기간&nbsp;</span> 2023-01-01 ~ 선착순 소진시 마감 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/* <span>당첨자 발표&nbsp;</span> 매월 10일 (마지막 당첨발표 2023-01-10) */}
          </span>
          <div style={{marginBottom: '24px'}}/>
          <hr/>
        </div>

        {/* main */}
        {/* <div style={{backgroundColor: 'black'}}> */}
        <div className='hawaii-main1' style={{backgroundColor: 'black'}}>
          <img className='limit-img' src='/images/mainPage/welcomeEventMain.svg'/>
        </div>
        {/* <div className={scrollActive ? 'event-bar-fixed' : 'event-bar'}>
          <ul className='item-holder'>
            <li
              className='item'
              onClick={() => navigate('/eventPage/?tab=benefit1Ref')}
            >
              <span className={benefitActive[0] ? 'item-text-fixed' : 'item-text'}>
                선착순 30명 한정<br/>
                학습지 0원 특가
              </span>
            </li>
            <li
              className='item'
              onClick={() => navigate('/eventPage/?tab=benefit2Ref')}
            >
              <span className={benefitActive[1] ? 'item-text-fixed' : 'item-text'}>
                한정수량 대형 뮤지오<br/>
                등받이 쿠션 추가 구성
              </span>
            </li>
            <li
              className='item'
              onClick={() => navigate('/eventPage/?tab=benefit3Ref')}
            >
              <span className={benefitActive[2] ? 'item-text-fixed' : 'item-text'}>
                지금 회원가입하고<br/>
                무료 샘플 받기
              </span>
            </li>
          </ul>
        </div> */}
        {/* <div style={{marginTop:'-10px'}}/> */}
        <div className='hawaii-main1' style={{backgroundColor: '#393939', marginTop: scrollActive ? '80px' : '0px'}} >
          <div className='event-ref' ref={benefit1Ref}></div>
          <img className='limit-img' src='/images/mainPage/eventBenefit1.svg'/>
          <Button
            variant='contained'
            className='back-button-green1'
            onClick={() => navigate('/product')}
          >
          선착순 0원 혜택 받기
          </Button>
        </div>
        <div className='hawaii-main1' style={{backgroundColor: '#FFACAB'}}>
          <div className='event-ref' ref={benefit2Ref}></div>
          <img className='limit-img' src='/images/mainPage/2022event3.svg'/>
          <Button
            variant='contained'
            className='back-button-red1'
            onClick={() => navigate('/product')}
          >
          구매하고 한정 구성품 받기
          </Button>
        </div>
        {/* <div className='hawaii-main1'>
          <div className='event-ref' ref={benefit3Ref}></div>
          <img className='limit-img' src='/images/mainPage/2022event4.svg'/>
          <Button
            variant='contained'
            className='back-button-black2'
            onClick={() => navigate('/auth/kakaologin')}
          >
          지금 가입하고 샘플 받기
          </Button>
        </div> */}

        <div style={{paddingLeft: '180px'}}/>
        <div className='hawaii-main1' style={{backgroundColor: '#F7F7F8'}}>
          <img src='/images/mainPage/eventNotice.svg'/>
        </div>

        <div style={{marginTop: '80px'}}/>

        <Button
          variant='contained'
          className='back-button'
          onClick={() => navigate('/event')}
        >
        목록 보기
        </Button>
        <div style={{marginBottom:'120px'}}/>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div id='hawaii-event'>
        <div className='hawaii-header'>
          <div style={{marginBottom: '40px'}}/>
          <span className='title'>신규 회원 웰컴 이벤트</span>
          <div style={{marginTop: '24px'}}/>
          <span><span>기간&nbsp;</span> 2023-01-01 ~ 선착순 소진시 마감 <br/></span>
          <div style={{marginTop: '16px'}}/>
          <div style={{marginTop: '24px'}}/>
          <div className='sns-row'>
            <FacebookShareButton url={currentUrl}>
              <img className='facebook-icon' src='/images/misc/facebookIcon.png' />
            </FacebookShareButton>
            <TwitterShareButton url={currentUrl}>
              <img className='twitter-icon' src='/images/misc/twitterIcon.png'/>
            </TwitterShareButton>
            <Button onClick={() => {
              handleCopyClipBoard(currentUrl);
              setCopyUrlOpen(true);
            }}>
              <img className='link-icon' src='/images/misc/linkIcon.png'/>
            </Button>
            <Dialog
              open={copyUrlOpen}
              onClose={() => setCopyUrlOpen(false)}
              fullWidth
              maxWidth="xs"
            >
              <div style={{marginBottom:'40px'}}/>
              <DialogTitle
                sx={{
                  background:'#F6F8FA',
                  margin:'0 20px 0 20px',
                  textAlign:'center'
                }}
              >
            URL이 복사 되었습니다.
              </DialogTitle>
              <div style={{marginBottom:'10px'}}/>
              <Divider sx={{ background:'#F6F8FA'}}/>
              <DialogActions sx={{ margin: 1 }}>
                <Button
                  variant='contained'
                  onClick={() => setCopyUrlOpen(false)}
                >
            확인
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <br/>
        </div>

        {/* 임시작업 */}
        {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLScYUiaU97iFQvJT17nuwvRKiLcCQpZClxEDdOksf2NEes-vXA/viewform?embedded=true">
        <img className='event-main' src='/images/mainPage/이벤트_1440px.png'/>
      </a> */}

        {/* main */}
        {/* main */}
        {/* <div style={{backgroundColor: 'black'}}> */}
        <div className='hawaii-main1' style={{backgroundColor: 'black', marginTop: scrollActive ? '65px' : '0px'}}>
          <img className='limit-img' src='/images/mainPageMobile/welcomeEventMain_m.svg'/>
        </div>
        {/* <div className={scrollActive ? 'event-bar-fixed' :'event-bar'}>
          <ul className='item-holder'>
            <li
              className='item'
              onClick={() => navigate('/eventPage/?tab=benefit1Ref')}
            >
              <span className={benefitActive[0] ? 'item-text-fixed' : 'item-text'}>
                혜택 1<br/>
                학습지 0원
              </span>
            </li>
            <li
              className='item'
              onClick={() => navigate('/eventPage/?tab=benefit2Ref')}
            >
              <span className={benefitActive[1] ? 'item-text-fixed' : 'item-text'}>
                혜택 2<br/>
                한정템 구성
              </span>
            </li>
            <li
              className='item'
              onClick={() => navigate('/eventPage/?tab=benefit3Ref')}
            >
              <span className={benefitActive[2] ? 'item-text-fixed' : 'item-text'}>
                혜택 3<br/>
                교재 무료 증정
              </span>
            </li>
          </ul>
        </div> */}
        {/* <div style={{marginTop:'-10px'}}/> */}
        <div className='hawaii-main1' style={{backgroundColor: '#393939'}}>
          <div className='event-ref' ref={benefit1Ref}></div>
          <img className='limit-img' src='/images/mainPageMobile/eventBenefit1_m.svg'/>
          <Button
            variant='contained'
            className='back-button-green1'
            onClick={() => navigate('/product')}
          >
          선착순 0원 혜택 받기
          </Button>
        </div>
        <div className='hawaii-main1' style={{backgroundColor: '#FFACAB'}}>
          <div className='event-ref' ref={benefit2Ref}></div>
          <img className='limit-img' src='/images/mainPageMobile/2022event3_m.svg'/>
          <Button
            variant='contained'
            className='back-button-red'
            onClick={() => navigate('/product')}
          >
          구매하고 한정 구성품 받기
          </Button>
        </div>
        {/* <div className='hawaii-main1'>
          <div className='event-ref' ref={benefit3Ref}></div>
          <img className='limit-img' src='/images/mainPageMobile/2022event4_m.svg'/>
          <Button
            variant='contained'
            className='back-button-black2'
            onClick={() => navigate('/auth/kakaologin')}
          >
          지금 가입하고 샘플 받기
          </Button>
        </div> */}
        <div style={{marginTop: '80px'}}/>
        <div className='hawaii-main1' style={{backgroundColor: '#F7F7F8'}}>
          <img src='/images/mainPageMobile/eventNotice_m.svg'/>
        </div>

        <div style={{marginTop: '80px'}}/>

        <Button
          variant='contained'
          className='back-button'
          onClick={() => navigate('/event')}
        >
        목록 보기
        </Button>
        <div style={{marginBottom:'120px'}}/>
      </div>
    );
  };


  // return renderMobile();
  if (device == 'mobile')
  // if (width < 500)
    return renderMobile();
  else
    return  renderWeb();
}
