// main config

export const STAGE = 'prod';
const VERSION = '1.3.3';
console.log('version ', VERSION);
const isProd = STAGE == 'prod';

export const SERVER_URL = isProd ?
  'https://mek-server.themusio.com' :
  'http://ec2-52-78-135-140.ap-northeast-2.compute.amazonaws.com:3101';
// export const SERVER_URL = 'http://localhost:3101';

export const QR_URL = isProd ? 'https://b8m2ak1jeg.execute-api.ap-northeast-1.amazonaws.com/api/' :
  'https://eb8751kgxb.execute-api.ap-northeast-1.amazonaws.com/api/';

// tosspayments
//export const TOSS_CLIENT_KEY = 'test_ck_ZORzdMaqN3wgvWxlROy35AkYXQGw';
export const TOSS_CLIENT_KEY = 'live_ck_qLlDJaYngrooYwGjQ4XrezGdRpXx';

export const FB_CONFIG =isProd ?
  {
    apiKey: 'AIzaSyBtPZcc4d4sv0pIq2zRrVG0nlD4S2_bHFI',
    authDomain: 'mek-auth.firebaseapp.com',
    projectId: 'mek-auth',
    storageBucket: 'mek-auth.appspot.com',
    messagingSenderId: '761165121010',
    appId: '1:761165121010:web:9ba1a49fb7ed7fcc5ad76b',
    measurementId: 'G-B7YF8H52SV'
  }:
  {
    apiKey: 'AIzaSyBASZoMJmQn2TizcmHzv_2lbeFc36OBMgw',
    authDomain: 'mek-auth-dev.firebaseapp.com',
    projectId: 'mek-auth-dev',
    storageBucket: 'mek-auth-dev.appspot.com',
    messagingSenderId: '530387957040',
    appId: '1:530387957040:web:4cb68a1ddb08183c2684f5',
    measurementId: 'G-1EM6L0MVBC'
  };
