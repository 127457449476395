import {signup} from 'Utils/Auth.js';

import React, {useState, useEffect} from 'react';
import 'Styles/AuthSignup.css';
import {
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  Button,
  Dialog,
} from '@mui/material';
import {
  CheckCircleOutline as CheckIcon
} from '@mui/icons-material';
import MarketingTerms from 'Components/MarketingTerms.js';
import ServiceTerms from 'Components/ServiceTerms.js';
import PrivacyTerms from 'Components/PrivacyTerms.js';
import {createCustomer} from 'Utils/Customer';
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from 'Hooks';

export function SignupScreen() {
  const navigate = useNavigate();

  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');

  // const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userCheckPassword, setUserCheckPassword] = useState('');

  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacyTerms, setPrivacyTerms] = useState(false);
  const [openMarketingTerms, setMarketingTerms] = useState(false);
  const [onceSubmitted, setOnceSubmitted] = useState(false);

  const showSnackbar = useSnackbar();

  const allAgreeHandler = (checked) => {
    setIsAllChecked(!isAllChecked);
    if (checked) {
      setCheckedItems([...checkedItems, 'age', 'sterms', 'pterms', 'mterms']);
    } else if ((!checked && checkedItems.includes('age')) ||
              (!checked && checkedItems.includes('sterms')) ||
              (!checked && checkedItems.includes('pterms')) ||
              (!checked && checkedItems.includes('mterms')))
    {
      setCheckedItems([]);
    }
  };

  const agreeHandler = (checked, value) => {
    if (checked) {
      setCheckedItems([...checkedItems, value]);
    } else if (!checked && checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter((el) => el !== value));
    }
  };

  useEffect(() => {
    if(checkedItems.length >= 4){
      setIsAllChecked(true);
    }else {
      setIsAllChecked(false);
    }
  }, [checkedItems]);

  const validator = {
    lastName: () => {
      if (userLastName.length < 1 ) return '성을 입력해 주새요.';
      return null;
    },
    firstName: () => {
      if (userFirstName.length < 1 ) return  '이름을 입력해 주세요.';
      return null;
    },
    // phone: () => {
    //   let isNum = /^\d+$/.test(userPhone);
    //   if (userPhone.length < 1) return '전화번호를 입력해 주세요.';
    //   if (!isNum) return '전화번호 형식이 올바르지 않습니다.';
    //   return null;
    // },
    email: () => {
      const checkEmail = /^[0-9a-zA-Z]([-_+\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      if (userEmail.length < 1) return '이메일을 입력해 주세요.';
      if (!checkEmail.test(userEmail)) return '이메일 형식이 올바르지 않습니다.';
      return null;
    },
    password: () => {
      // const checkPassword = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/;
      const checkPassword = /^(?=.*[a-zA-z])(?=.*[0-9]).{6,16}$/;
      if (userPassword.length < 1 ) return '비밀번호를 입력해 주세요.';
      if (!checkPassword.test(userPassword)) return '6~16자 영문 대 소문자, 숫자를 사용하세요.';
      return null;
    },
    recheckPassword: () => {
      if (userPassword.length < 1 ) return '비밀번호를 확인을 입력해 주세요.';
      if (userCheckPassword != userPassword) return '비밀번호 확인이 일치하지 않습니다.';
      return null;
    }
  };

  const checkFormat = () => {
    if (validator.firstName() != null) {
      return false;
    }
    if (validator.lastName() != null) {
      return false;
    }
    if (validator.email() != null) {
      return false;
    }
    // if (validator.phone() != null) {
    //   return false;
    // }
    if (validator.password() != null) {
      return false;
    }
    if (validator.recheckPassword() != null) {
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    setOnceSubmitted(true);
    if (checkFormat() == false) {
      return ;
    }
    try {
      await signup(userEmail, userPassword);
      const customerData = {
        firstName: userFirstName,
        lastName: userLastName,
        // phoneNumber: userPhone,
        phoneNumber: '휴대전화 번호를 입력해주세요',
        email: userEmail,
      };
      await createCustomer(customerData); // return newMe
      // console.log(newMe);

      navigate('/verifyEmail');
    } catch (e) {
      let errMessage = '회원가입에 실패했습니다.';
      // console.log(e.code);
      if (e.code == 'auth/email-already-in-use') { // 참조:  https://firebase.google.com/docs/reference/js/v8/firebase.auth.AuthError
        errMessage = '이미 가입된 이메일입니다.';
      }
      showSnackbar(errMessage, 'error');
    }

  };

  return(
    <div id="auth-signup">
      <div className="auth-options">
        <h1>회원가입</h1>

        <div className="auth-signup-namefield">
          <div className="auth-signup-field">
            <span>성<span>*</span></span>
            <TextField
              className='input-text'
              required
              value={userLastName}
              onChange={(e) => setUserLastName(e.target.value)}
              hiddenLabel
              fullWidth
              error={onceSubmitted && validator.lastName() != null}
              helperText={onceSubmitted ? validator.lastName() : undefined}
            />
          </div>
          <div className="auth-signup-field">
            <span>이름<span>*</span></span>
            <TextField
              className='input-text'
              required
              value={userFirstName}
              onChange={(e) => setUserFirstName(e.target.value)}
              hiddenLabel
              fullWidth
              error={onceSubmitted && validator.firstName() != null}
              helperText={onceSubmitted ? validator.firstName() : undefined}
            />
          </div>
        </div>
        {/* <div className="auth-signup-field">
          <span>휴대전화<span>*</span></span>
        </div> */}

        {/* <TextField
          hiddenLabel
          onChange={(e) => {
            const val = e.target.value;
            let isNum = /^\d+$/.test(val);
            if (!val || isNum) {
              setUserPhone(val);
            }
          }}
          value={userPhone}
          fullWidth
          required
          placeholder="‘-’ 없이 숫자만 입력해 주세요."
          error={onceSubmitted && validator.phone() != null}
          helperText={onceSubmitted ? validator.phone() : undefined}
        /> */}

        <div className="auth-signup-field">
          <span>이메일<span>*</span></span>
        </div>
        <TextField
          hiddenLabel
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          fullWidth
          required
          placeholder="이메일을 입력해 주세요."
          error={onceSubmitted && validator.email() != null}
          helperText={onceSubmitted ? validator.email() : undefined}
        />

        <div className="auth-signup-field">
          <span>비밀번호<span>*</span></span>
        </div>
        <TextField
          hiddenLabel
          value={userPassword}
          onChange={(e) => setUserPassword(e.target.value)}
          fullWidth
          required
          placeholder="비밀번호를 입력해 주세요."
          type="password"
          error={onceSubmitted && validator.password() != null}
          helperText={onceSubmitted ? validator.password() : undefined}
        />

        <div className="auth-signup-field">
          <span>비밀번호 확인<span>*</span></span>
        </div>
        <TextField
          hiddenLabel
          value={userCheckPassword}
          onChange={(e) => setUserCheckPassword(e.target.value)}
          fullWidth
          required
          placeholder="비밀번호를 한번 더 입력해 주세요."
          type="password"
          error={onceSubmitted && validator.recheckPassword() != null}
          helperText={onceSubmitted ? validator.recheckPassword() : undefined}
        />


        <div style={{margin: '12px'}}/>

        <div className="check-button">
          <Checkbox
            icon={<CheckIcon color='cancel'/>}
            checkedIcon={<CheckIcon/>}
            onClick={(e) => allAgreeHandler(e.target.checked)}
            checked={isAllChecked}
            value = 'Allchecked'
          />
          <label htmlFor="check">전체 동의</label>
        </div>

        <div className="check-button">
          <Checkbox
            icon={<CheckIcon color='cancel'/>}
            checkedIcon={<CheckIcon/>}
            checked= {checkedItems.includes('age') ? true : false}
            onClick={(e) => agreeHandler(e.target.checked , e.target.value)}
            value = 'age'
          />
          <label htmlFor="check">만 14세 이상입니다.<span>(필수)</span></label>
        </div>

        <div className="check-button">
          <Checkbox
            icon={<CheckIcon color='cancel'/>}
            checkedIcon={<CheckIcon/>}
            checked= {checkedItems.includes('sterms') ? true : false}
            onClick={(e) => agreeHandler(e.target.checked, e.target.value)}
            value = 'sterms'
          />
          <label>
            <label htmlFor="check">서비스 이용약관<span>(필수)</span></label>
          </label>
          <div className="show-terms" onClick={() => setOpenTerms(true)} >
            <span>보기 &#62;</span>
          </div>
        </div>

        <Dialog
          open={openTerms}
          onClose={() => setOpenTerms(false)}
        >
          <DialogTitle>
            {'약관 체결의 성립 동의'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <ServiceTerms/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="cancel" onClick={() => setOpenTerms(false)}>취소</Button>
            <Button variant="contained" onClick={() => setOpenTerms(false)}>확인</Button>
          </DialogActions>
        </Dialog>


        <div className="check-button">
          <Checkbox
            icon={<CheckIcon color='cancel'/>}
            checkedIcon={<CheckIcon/>}
            checked= {checkedItems.includes('pterms') ? true : false}
            onClick={(e) => agreeHandler(e.target.checked, e.target.value)}
            value = 'pterms'
          />
          <label>
            <label htmlFor="check">개인정보 수집, 이용동의<span>(필수)</span></label>
          </label>
          <div className="show-terms" onClick={() => setPrivacyTerms(true)} >
            <span>보기 &#62;</span>
          </div>
        </div>

        <Dialog
          open={openPrivacyTerms}
          onClose={() => setPrivacyTerms(false)}
        >
          <DialogTitle>
            {'개인정보 수집, 이용동의'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <PrivacyTerms/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="cancel" onClick={() => setPrivacyTerms(false)}>취소</Button>
            <Button variant="contained" onClick={() => setPrivacyTerms(false)}>확인</Button>
          </DialogActions>
        </Dialog>

        <div className="check-button">
          <Checkbox
            icon={<CheckIcon color='cancel'/>}
            checkedIcon={<CheckIcon/>}
            checked= {checkedItems.includes('mterms') ? true : false}
            onClick={(e) => agreeHandler(e.target.checked, e.target.value)}
            value = 'mterms'
          />
          <label>
            마케팅 정보 수신 동의 (선택)
          </label>
          <div className="show-terms" onClick={() => setMarketingTerms(true)} >
            <span>보기 &#62;</span>
          </div>
        </div>

        <Dialog
          open={openMarketingTerms}
          onClose={() => setMarketingTerms(false)}
        >
          <DialogTitle>
            {'마케팅 정보 수신 동의'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <MarketingTerms/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="cancel" onClick={() => setMarketingTerms(false)}>취소</Button>
            <Button variant="contained" onClick={() => setMarketingTerms(false)}>확인</Button>
          </DialogActions>
        </Dialog>

        {/* <div className="terms">
          <span>서비스 이용약관 (필수), 개인정보 수집, 이용동의 (필수)<br/> 마케팅 정보 수신 동의 (선택)</span>
        </div> */}

        <div className="submit">
          <Button
            variant="contained"
            className="auth-button"
            // disabled={() => !checkFormat() || !checkList.age || !checkList.sterms}
            disabled={(checkedItems.includes('age') && checkedItems.includes('sterms') && checkedItems.includes('pterms')) ? false : true}
            onClick = {() => onSubmit()}
          >
            가입하기
          </Button>
        </div>
      </div>
    </div>

  );
}