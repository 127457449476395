import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';


export function useAdminSignal(count=3) {
  const navigate = useNavigate();
  const [signals, setSignals] = useState([]);

  const pushSignals = () => {
    const newSignals = [Date.now(), ...signals].slice(0, count);
    if (newSignals.length >= count ) {
      const milis = newSignals[0] - newSignals[newSignals.length -1];
      if (milis < 2000) {
        navigate('/admin');
      }
    }
    setSignals(newSignals);
  };
  return pushSignals;
}

