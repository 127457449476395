import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {useResponsive} from 'Hooks';

import { useNavigate } from 'react-router';

import 'Styles/Event.css';

export function EventScreen() {
  const navigate = useNavigate();
  const {device} = useResponsive();

  return (
    <div id='event-main'>
      <div style={{marginTop: '120px'}}/>
      <div className='title'>
        <h2>이벤트</h2>
        <h4>진행중 이벤트</h4>
      </div>
      <hr/>
      <div className='event-list'>
        {device == 'desktop' ?
          <img src='/images/mainPage/eventList.svg'
            onClick={()=> navigate('/eventPage')}/>
          : <img src='/images/mainPageMobile/eventList1_m.svg' onClick={()=> navigate('/eventPage')}/>}
      </div>
      <Stack
        spacing={2}
        style={{alignItems: 'center', paddingTop: '50px'}}
      >
        <Pagination count={1}/>
      </Stack>
    </div>
  );
}