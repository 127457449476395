import React, {useState, useEffect} from 'react';
import {Button, TextField} from '@mui/material';
import {logout, sendVerification} from 'Utils/Auth.js';
import {useNavigate} from 'react-router';
import {useRecoilValue} from 'recoil';
import {authState} from 'GlobalState';
import {useSnackbar} from 'Hooks';
import {useResponsive} from 'Hooks';
import {signupMail} from 'Utils/Stibee';

import 'Styles/AuthVerifyEmailScreen.css';

export function VerifyEmailScreen() {
  const auth = useRecoilValue(authState);
  const [isVSent, setIsVSent] = useState(false);
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const {device} = useResponsive();

  useEffect(() => {
    if (auth?.emailVerified == true) {
      showSnackbar('메일 인증이 완료되었습니다.');
      signupMail(auth.uid);
      navigate('/');
    }
  }, [auth]);

  const onSendVMail = async () => {
    try {
      await sendVerification();
      setIsVSent(true);
    } catch (e) {
      console.warn(e);
    }
  };

  const onLogout = async () => {
    await logout();
    navigate('/');
  };

  const onVerify = async () => {
    window.location.reload(false);
  };

  return (
    <div id="auth-verify-email">
      <div className="auth-options">
        <img
          src='/images/navbar/logoBlack.png'
          className="auth-aka-logo"
          onClick={() => {
            navigate('/');
          }}
        />
        <h3>이메일 주소 인증</h3>
        <span>입력하신 이메일 주소로 인증 메일을 전송했습니다.
          <br/>아래 버튼을 클릭하여 이메일 인증을 완료해주세요.
        </span>

        <div className="auth-user-email">
          <span>이메일</span>
        </div>
        <TextField
          variant="filled"
          disabled
          hiddenLabel
          fullWidth
          value={auth?.email}
          InputProps={{
            readOnly: true,
          }}
        />

        <div className="verify-button">
          <Button
            variant='contained'
            onClick={onLogout}
            className="verify-button-divide logout-button"
          >
            로그아웃
          </Button>
          {device=='mobile'?<div style={{marginLeft:'32px'}}/>:undefined}
          <Button
            variant='contained'
            onClick={onVerify}
            className="verify-button-divide"
          >
            인증하기
          </Button>
        </div>
        <div className="resent-verify-email">
          <span >이메일을 확인할 수 없나요?<br/>
          스팸편지함 확인 또는</span>
          <Button
            variant='text'
            onClick={onSendVMail}
            disabled={isVSent}
          ><u>인증 메일 다시 보내기</u>
          </Button>
        </div>
      </div>
    </div>
  );
}