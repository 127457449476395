import React from 'react';
import 'Styles/AuthResign.css';
import {Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function ResignResultScreen() {
  const navigate = useNavigate();

  return(
    <div className='auth-root'>
      <div className='auth-options'>
        <img
          src='/images/navbar/logoBlack.png'
          className="auth-aka-logo"
          onClick={() => {
            navigate('/');
          }}
        />
        <div className='resign-header'>
          <span>뮤지오 잉글리시 계정 탈퇴가 완료되었습니다.</span>
        </div>
        <div className='resign-content'>
          <span>그동안 뮤지오 잉글리시 서비스를 이용해주셔서 감사합니다.</span>
        </div>
        <div>
          <Button
            variant="contained"
            className="auth-button"
            onClick={() => {
              navigate('/');
            }}
          >확인</Button>
        </div>
      </div>
    </div>
  );
}