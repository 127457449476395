import React from 'react';
import 'Styles/MyPageModifyPassword.css';
import {Button, TextField} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {sendPasswordReset} from 'Utils/Auth';
import {useRecoilValue} from 'recoil';
import {authState} from 'GlobalState';
import {useSnackbar} from 'Hooks';

export function ModifyPasswordScreen() {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const showSnackbar = useSnackbar();

  const onSubmit = async () => {
    try {
      await sendPasswordReset(auth?.email);
      showSnackbar('해당 이메일로 비밀번호 재설정 메일이 발송되었습니다.');
    } catch (e) {
      showSnackbar('비밀번호 재설정 메일을 보내는데 실패하였습니다.', 'error');
      console.warn(e);
    }
  };

  return(
    <div id="modify-password">
      <div className='auth-root'>
        <div className='auth-options'>
          <img
            src='/images/navbar/logoBlack.png'
            className="auth-aka-logo"
            onClick={() => {
              navigate('/');
            }}
          />
          <div className='modify-header'>
            <span>비밀번호 변경</span>
          </div>
          <div className='modify-content'>
            <span>뮤지오잉글리시에 등록된 메일 주소로 비밀번호 재설정 링크가 포함된 메일이 발송됩니다.
              (만약 메일을 확인하실 수 없다면, 스팸메일함을 확인해 주세요.)</span>
          </div>
          <div className='modify-field'>
            <span>이메일</span>
          </div>
          <TextField
            hiddenLabel
            disabled
            fullWidth
            variant="filled"
            value={auth?.email}
          />
          {/* <div className='resign-field'>
            <span>비밀번호</span>
          </div>
          <TextField
            hiddenLabel
            fullWidth
            placeholder ="비밀번호를 입력하세요."
          /> */}

          <Button
            className="modify-button"
            variant="contained"
            onClick={onSubmit}
          >
               이메일 전송
          </Button>
        </div>
      </div>
    </div>
  );
}