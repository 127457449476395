import React, { useEffect} from 'react';
import {updateFbExdateTime} from 'Utils/Auth';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {updatePaymentOrder} from 'Utils/Order';
import 'Styles/TossResult.css';

export function TossLoadingScreen() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let confirmSent = false;

  const orderId = searchParams.get('orderId');
  const paymentKey= searchParams.get('paymentKey');
  const amount = searchParams.get('amount');
  const requestCharge = async () => {
    try {
      if (!orderId || !paymentKey || !amount) {
        throw new Error();
      }
      await updatePaymentOrder(orderId, amount, paymentKey);
      let uid = orderId.substring(0,orderId.indexOf('_'));
      if(uid.startsWith('kakao')){
        uid = 'kakao:' + uid.substring(5);
      }
      await updateFbExdateTime(uid);
      navigate('/toss/result?orderId=' + orderId);
    } catch (e) {
      console.warn('error', e);
      navigate('/toss/result?orderId=' + orderId);
    }
  };

  useEffect(() => {
    console.log('called useEffect');
    // http://localhost:3000/toss/success?orderId=travel_ipad256GB_12_v1&paymentKey=lOR1ZwdkQD5GePWvyJnrKNyB2jPQ1VgLzN97EoqYA60XKx4a&amount=10002
    if (confirmSent == false) {
      requestCharge();
      confirmSent = true;
    }
  }, []);

  return (
    <div id='toss-loading-screen'>
      <div style={{height: '100px'}}/>
      <h1>결제가 진행중입니다. 잠시만 기다려주세요.</h1>
    </div>
  );

}