import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  updateEmail,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail,
  deleteUser,
  // EmailAuthProvider,
  // reauthenticateWithCredential,
} from 'firebase/auth';
import {doc, updateDoc} from 'firebase/firestore';
import {signupMail} from 'Utils/Stibee';
import {getCustomer, createCustomer} from 'Utils/Customer.js';
import {updateCustomer} from 'Utils/Customer';
import {db} from 'App';

export function getToken() {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    return user.accessToken;
  } else {
    return null;
  }
}

export function getAuthHeader() {
  const token = getToken();
  if (!token) {
    return null;
  }
  return {Authorization: `Bearer ${token}`};
}

export async function signup(email, password) {
  var auth = getAuth();
  const credential = await createUserWithEmailAndPassword(auth, email, password);
  await sendVerification(email);
  return credential.user;
  // .catch((error) => {
  //   const errorCode = error.code;
  //   const errorMessage = error.message;
  // });
}

export async function login(email, password) {
  var auth = getAuth();
  const credential = await signInWithEmailAndPassword(auth, email, password);
  const washingtonRef = doc(db, 'mek-users', auth.currentUser.uid);
  await updateDoc(washingtonRef, {
    uid: auth.currentUser.uid,
    email: email
  });
  return credential.user;
}

export async function logout() {
  try {
    await signOut(getAuth());
  } catch (e) {
    console.warn(e);
  }
}

export async function sendVerification(email) {
  var auth = getAuth();
  // const result =  await sendEmailVerification(auth.currentUser);
  // console.log(result);
  sendEmailVerification(auth.currentUser)
    .then(async () => {
      const washingtonRef = doc(db, 'mek-users', auth.currentUser.uid);
      await updateDoc(washingtonRef, {
        uid: auth.currentUser.uid,
        email: email
      });
      console.log('success');
    });
}

export async function sendPasswordReset(email) {
  const auth = getAuth();
  const rsp = await sendPasswordResetEmail(auth, email);
  return rsp;
}

export async function signInCustomToken(userData) {
  const auth = getAuth();
  const credential = await signInWithCustomToken(auth, userData.token);
  const userUid = 'kakao:' + userData.uid;
  const customer = await getCustomer(userUid);
  const washingtonRef = doc(db, 'mek-users', userUid);
  if(!customer || customer.state == 'I'){
    const customerData = {
      firstName: userData.name,
      lastName: '',
      phoneNumber: '휴대전화 번호를 입력해주세요',
      email: userData.email,
      state: 'A',
      grade: '7'
    };
    await createCustomer(customerData);
    await signupMail(userUid);
  }
  await updateDoc(washingtonRef, {
    uid: userUid,
    email: userData.email
  });
  // await updateUserProfile(auth, userData);

  return credential.user;
}

export async function updateFbExdateTime(uid) {
  const unixDate = createUnixTime(12);
  const washingtonRef = doc(db, 'mek-users', uid);
  await updateDoc(washingtonRef, {
    expiresDateTimestamp: unixDate
  });
}

export async function updateUserProfile(auth, userData) {
  await updateProfile(auth.currentUser, {
    displayName: userData.name
  }).then(() => {
  }).catch((e) => {
    console.log(e);
  });

  await updateEmail(auth.currentUser, userData.email).then(() => {
  }).catch((e) => {
    console.log(e);
  });

  return auth.currentUser;
}

export async function resign() {
  const auth = getAuth();
  const user = auth.currentUser;
  const updateData = {
    state: 'I'
  };
  await updateCustomer(updateData);
  deleteUser(user)
    .then(() => {
      console.log('success');
    }).catch((error) => {
      console.warn(error);
    });
}

export function createUnixTime(month) {
  const today = new Date();
  const date = new Date(
    today.getFullYear(),
    today.getMonth() + month,
    today.getDate(),
    today.getHours(),
    today.getMinutes(),
    today.getSeconds()
  );
  const extime = Math.floor(date / 1000);
  return extime;
}
// export async function reAuthenticate(email, password) {
//   const auth = getAuth();
//   const credential = EmailAuthProvider.credential(
//     auth.currentUser.email,
//     password
//   );
//   const result = await reauthenticateWithCredential(
//     auth.currentUser,
//     credential
//   );
// }