import { getAuthHeader } from './Auth';
import {server} from './Axios';

export async function listProduct() {
  const rsp = await server.post('/listProduct');
  return rsp.data;
}

export async function listOrder() {
  const rsp = await server.post('/listOrder');
  return rsp.data;
}

export async function listCustomer() {
  const rsp = await server.post('/listCustomer');
  return rsp.data;
}

export async function listDownload() {
  const rsp = await server.post('/listDownload');
  return rsp.data;
}
export async function listStibee() {
  const rsp = await server.post('/listStibee');
  return rsp.data;
}

export async function createDownLoad(downLoadData) {
  const rsp = await server.post('/fileDownload', downLoadData, {headers: getAuthHeader()});
  return rsp.data;
}

export function convertTime(date) {
  if(!date) return date;
  const temp = new Date(date);
  const result =
    temp.getFullYear() + '/' +
    (temp.getMonth() + 1) + '/' +
    temp.getDate() + ' ' +
    (temp.getHours() < 10 ? '0' + temp.getHours() : temp.getHours())+ ':' +
    (temp.getMinutes() < 10 ? '0' + temp.getMinutes() : temp.getMinutes()) + ':' +
    (temp.getSeconds() < 10 ? '0' + temp.getSeconds() : temp.getSeconds());
  return result;
}

export function getToday() {
  const today = new Date();
  const date = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    today.getHours(),
    today.getMinutes(),
    today.getSeconds()
  );
  return date;
}