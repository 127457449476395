import React, {useEffect, useState} from 'react';
import {Button} from '@mui/material';
import 'Styles/MyPageKimini.css';
import {useResponsive} from 'Hooks';
import {kiminiLogin} from 'Utils/Kimini';

export default function NavigateKiminiScreen() {
  const {device} = useResponsive();
  const [kiminiUrl, setKiminiUrl] = useState(undefined);

  useEffect(() => {
    const loadKiminiUrl = async () => {
      try {
        const rsp = await kiminiLogin();
        if (rsp.url) {
          setKiminiUrl(rsp.url);
        } else {
          setKiminiUrl(null);
        }
      } catch (e) {
        setKiminiUrl(null);
      }
    };
    loadKiminiUrl();
  }, []);

  const renderKiminiButton = () => {
    if (kiminiUrl === null) {
      return <h3>Kimini 플랜을 이용할 수 없습니다.</h3>;
    }
    return (
      <Button
        variant="contained"
        className="kimini-button"
        disabled={kiminiUrl==undefined}
        href={kiminiUrl}
        target='_blank'
      >
        강의실 접속
      </Button>
    );
  };

  return (
    <div id="kimini-main">
      <h3 className='kimini-link'>키미니 화상 영어</h3>
      {device=='mobile' ? undefined : <hr/>}
      <div className="kimini-classroom">
        <div style={{marginTop:'24px'}}/>

        <img src='/images/misc/kimini1.png'/>

        <div style={{marginTop:'24px'}}/>

        <span className="kimini-introduction">본 서비스는 추가결제 상품으로,
            패키지 외 온라인 화상 영어 수업을{device=='mobile'? undefined : <br/>} 결제하신
            고객만 사용 가능합니다.</span>

        <div style={{marginTop:'12px'}}/>

        <img src='/images/misc/kimini2.png'/>

        <div style={{marginTop:'24px'}}/>
        {renderKiminiButton()}
      </div>

      <div style={{marginBottom:'120px'}}/>

      <h3>온라인 화상 영어 수업 안내</h3>
      <hr/>
      <ul className="kimini-introduction">
        <li><span className='introduction-title'>수업 예약</span> : 수업 시작 시간 15분 전까지 예약할 수 있습니다.</li>
        <li><span className='introduction-title'>수업 취소</span> : 수업 시작 30분 전까지 가능하며, 하루 최대 5번까지만 취소가 가능합니다.</li>
        <li><span className='introduction-title'>휴강 안내</span> : 교육 담당자 현지 휴일에는 휴강을 하고 있습니다. <br/>
                                                  해당 일정에는 수강이 불가하므로 양해 부탁드립니다. <br/>
                                                  휴강 일정에 대한 공지는 온라인 화상 영어 수업 페이지에 안내하고 있습니다.
        </li>
      </ul>

      <div style={{marginBottom:'80px'}}/>

      <h2>온라인 수업 환경을 확인해주세요.</h2>

      <div style={{marginBottom:'40px'}}/>

      <h3>인터넷 및 컴퓨터 환경</h3>
      <hr/>
      <span className="kimini-introduction">원활한 수업을 위해 수업 참여 이전에 아래 사항을 확인해주세요.</span>
      <ol className="kimini-introduction">
        <li>✓ 단말기가 인터넷에 연결되어 있는지</li>
        <li>✓ 컴퓨터 운영체제와 인터넷 브라우저가 최신으로 업데이트 되어 있는지</li>
        <li>✓ 마이크와 스피커가 정상적으로 작동하고 있는지</li>
        <li>✓ 단말기의 스피커 볼륨이 적절한지 (혹은 음소거가 되어 있지 않은지)</li>
        <li>✓ 카메라와 헤드셋 등이 단말기와 정상적으로 연결되어 있는지</li>
      </ol>

      <div style={{marginBottom:'80px'}}/>

      <h3>수업을 위한 스마트 기기의 권장 사양</h3>
      <hr/>
      {device=='mobile'?<img className='kimini-qualification' src="/images/misc/kimini-specification_m.svg"/>
        : <img src="/images/misc/kimini-specification.png"/>}
    </div>
  );
}