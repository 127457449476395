import {server} from './Axios';
import { getAuthHeader } from './Auth';

export async function createShipping(shippingData) {
  const rsp = await server.post('/shipping', shippingData, {headers: getAuthHeader()});
  return rsp.data;
}

export async function getRecentShipping() {
  const rsp = await server.get('/shipping/recent', {headers: getAuthHeader()});
  return rsp.data;
}

export async function updateShipping(shippingId, updateData) {
  const body = {
    shippingId: shippingId,
    updateData: updateData,
  };
  const rsp = await server.put('/shipping', body, {headers: getAuthHeader()});
  return rsp.data;
}