import React, {useEffect, useState} from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';
import MetaTags from 'react-meta-tags';


import {initializeApp} from 'firebase/app';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {getAnalytics} from 'firebase/analytics';
import {getFirestore} from 'firebase/firestore';



import {Snackbar, Alert} from '@mui/material';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {snackbarState} from 'GlobalState';

import AppRouter from './AppRouter.js';
import {listProduct} from 'Utils/Default';
import {authState, myState, productListState} from 'GlobalState';
import {getCustomer} from 'Utils/Customer';
import {FB_CONFIG, STAGE} from './config';
// import swal from 'sweetalert';


import 'Styles/App.css';

// https://stackoverflow.com/questions/10697463/resolve-git-merge-conflicts-in-favor-of-their-changes-during-a-pull
// git merging conflict accept all 하는 방법


const theme = createTheme({
  palette: {
    primary: {
      main: '#2388ff',
      // main: '#ff0000'
    },
    error: {
      main: '#ef5350',
    },
    cancel: {
      main: '#e4e8f4',
    },
    black: {
      main: '#28292b',
    },
    white: {
      main: '#FFFFFF',
    },
    gray: {
      main: '#7E8288',
    }
  },
  shadows: shadows.map(() => 'none'),
});



// Initialize Firebase
const app = initializeApp(FB_CONFIG);
export const db = getFirestore(app);
getAnalytics(app);
getAuth(app);

const TopButton = () => {
  const [showButton, setShowButton] = useState(false);
  let hoverImage = document.getElementById('hoverimg');
  function mouseover () {
    hoverImage.setAttribute('src', '/images/mainPage/topButtonHover.svg');
  }
  function mouseleave () {
    hoverImage.setAttribute('src', '/images/mainPage/topButton.svg');
  }

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const ShowButtonClick = () => {
      if (window.scrollY > 0) {
        setShowButton(true);
      }
      else {
        setShowButton(false);
      }
    };
    window.addEventListener('scroll', ShowButtonClick);
    return () => {
      window.removeEventListener('scroll', ShowButtonClick);
    };
  }, []);
  return (
    <>
      {showButton &&
    <div className='top-button' onClick={scrollToTop} onMouseOver = {mouseover} onMouseLeave={mouseleave}>
      {/* <img src='/images/mainPage/topButton.svg' id='hoverimg' onClick={scrollToTop}></img> */}

    </div>
      }
    </>
  );
};

export default function App() {
  const [authLoading, setAuthLoading] = useState(false);
  const [snackbar, setSnackbar] = useRecoilState(snackbarState);
  const setAuth = useSetRecoilState(authState);
  const setProductList = useSetRecoilState(productListState);
  const setMe = useSetRecoilState(myState);

  const closeSnackbar = () => setSnackbar({...snackbar, isOpen: false});

  const bringMe = async () => {
    try {
      const newMe = await getCustomer(null);
      // console.log('newMe:', newMe);
      setMe(newMe);
    } catch(e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (window.Kakao) {
      const kakao = window.Kakao;
      if(!kakao.isInitialized()) {
        window.Kakao.init('6b23f84b45b5fb06d9395fee33a9e03c');
        window.Kakao.Channel.createChatButton({
          container: '#kakao-talk-channel-chat-button',
          channelPublicId: '_MCBMb',
          title: 'consult',
          size: 'small',
          color: 'yellow',
          shape: 'pc',
          supportMultipleDensities: true,
        });
      }
    }
  });

  const kakaoChat = () => {
    const script = document.createElement('script');
    script.async = true;
    try {
      window.Kakao.Channel.chat({
        channelPublicId: '_MCBMb' // 카카오톡 채널 홈 URL에 명시된 id로 설정합니다.
      });
      document.body.appendChild(script);
      document.body.removeChild(script);
    } catch (err){
      console.log('에러');
    }
  };

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        const userCopy = JSON.parse(JSON.stringify(user)); // issue: https://github.com/firebase/firebase-js-sdk/issues/5722
        // console.log(userCopy);
        setAuth(userCopy);
        bringMe();
      } else {
        setAuth(null);
        setMe(null);
      }
      setAuthLoading(false);
    });
  }, []);

  useEffect(() => {
    async function bringProductList() {
      try {
        const data = await listProduct();
        if (data) {
          setProductList(data);
        }
      } catch (e) {
        console.warn(e);
      }
    }
    bringProductList();
  }, []);

  // useEffect(() => {
  //   const host = window.location.host;
  //   const path = window.location.pathname;
  //   console.log(host, path);
  //   if (host.includes('musioenglish.kr') && path == '/'){
  //   // if (host.includes('localhost') && path == '/'){
  //     swal({
  //       title: '[Musio English KIDS 사이트 이전 안내]',
  //       text: `안녕하세요 뮤지오 잉글리시입니다. 😊\n\n
  // 8/30일부터 뮤지오 잉글리시가 키즈와 성인 서비스로 분리될 예정입니다.\n
  // 이용 방식은 기존과 동일하며, KIDS 전용 사이트 주소만 변경되는 점 참고 부탁드립니다.\n\n
  // 기존 뮤지오 잉글리시 이용자분들은 금일부로 KIDS 사이트 이용 부탁드립니다.\n\n
  // 저희 뮤지오 잉글리시를 이용해주셔서 항상 감사드립니다.\n
  // 신규 출시될 성인 회화 서비스도 많은 관심 부탁드립니다.\n\n
  // 신규 주소: http://kids.musioenglish.kr`,
  //     });
  //   }
  // }, []);

  if (authLoading) {
    return (
      <div id='root'>
        user loading...
      </div>
    );
  }
  return (
    <div id='root'>
      <div id='meta-tag'>
        <MetaTags>
          <meta name="color-scheme" content="light only"></meta>
        </MetaTags>
      </div>

      {/* <div className='top-button'>
        <img src='/images/mainPage/topButton.svg' onClick={moveToTop}></img>
      </div> */}
      <div>{TopButton()}</div>
      <div id="kakao-talk-channel-chat-button" onClick={() => kakaoChat()}/>

      <ThemeProvider theme={theme}>
        <div>
          <AppRouter/>
          {STAGE != 'prod' &&
          <div className='dev-noti'>
            <h1>DEBUG!</h1>
          </div>
          }
          <Snackbar open={snackbar.isOpen} onClose={closeSnackbar} autoHideDuration={3000} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
            <Alert severity={snackbar.severity ?? 'success'}> {snackbar.message} </Alert>
          </Snackbar>
        </div>
      </ThemeProvider>
    </div>
  );
}
