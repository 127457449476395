import React, {useState, useEffect} from 'react';
import 'Styles/AuthResign.css';
import {Button,
  TextField,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  DialogActions} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {myState} from 'GlobalState';
import { resign, reAuthenticate } from 'Utils/Auth';

export function ResignScreen() {
  const navigate = useNavigate();
  const me = useRecoilValue(myState);
  const [email, setEmail] = useState('');
  const [resignDialogOpen, setResignDialogOpen] = useState(false);

  useEffect(() => {
    if (me) {
      setEmail(me.email);
    }
  }, [me]);

  const resignAccount = async () => {
    try {
      await resign();
    } catch (e) {
      console.warn(e);
    }
  };

  // const reAuthenticateAccount = async () => {
  //   try {
  //     await reAuthenticate();
  //     setIsReAuthenticate(true);
  //   } catch (e) {
  //     console.warn(e);
  //   }
  // }

  return(
    <div id="auth-resign">
      <div className='auth-root'>
        <div className='auth-options'>
          <img
            src='/images/navbar/logoBlack.png'
            className="auth-aka-logo"
            onClick={() => {
              navigate('/');
            }}
          />
          <div className='resign-header'>
            <span>뮤지오 잉글리시 계정 탈퇴</span>
          </div>
          <div className='resign-content'>
            <span>뮤지오 잉글리시 계정 탈퇴 전 꼭 확인해 주세요!</span>
            <br/><br/>
            <span>뮤지오 잉글리시 계정을 탈퇴하면 계정 정보 및
            서비스 이용 기록을 포함한 모든 정보가 삭제됩니다.
            탈퇴된 계정 정보와 서비스 이용기록 등은
            복구할 수 없으니 신중하게 선택하세요.</span>
          </div>
          <div className='resign-field'>
            <span>이메일</span>
          </div>
          <TextField
            hiddenLabel
            disabled
            fullWidth
            value={email}
            variant="filled"
          />
          <div className='resign-field'>
            <span>비밀번호</span>
          </div>
          <TextField
            hiddenLabel
            fullWidth
            placeholder ="비밀번호를 입력하세요."
          />

          <div className='resign-button'>
            <Button
              variant="contained"
              className="resign-button-divide cancel-resign"
              onClick={() => {
                navigate('/mypage');
              }}
            >탈퇴취소</Button>
            <Button
              className="resign-button-divide"
              variant="contained"
              onClick={() => setResignDialogOpen(true)}

            >계정탈퇴</Button>
          </div>
          <Dialog
            open={resignDialogOpen}
            onClose={() => setResignDialogOpen(false)}
            fullWidth
            maxWidth="xs"
          >
            <IconButton
              aria-label="close"
              onClick={()=> setResignDialogOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon/>
            </IconButton>
            <div style={{marginBottom:'40px'}}/>
            <DialogTitle
              sx={{
                background:'#F6F8FA',
                margin:'0 20px 0 20px',
                textAlign:'center'
              }}
            >
          정말로 탈퇴하시겠습니까?
            </DialogTitle>
            <div style={{marginBottom:'10px'}}/>
            <Divider/>
            <DialogActions sx={{ margin: 1 }}>
              <Button
                variant='contained'
                color='cancel'
                onClick={() => setResignDialogOpen(false)}
              >
            취소
              </Button>
              <Button
                variant='contained'
                onClick={resignAccount}
              >
            확인
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}