import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox} from '@mui/material';
import {Button, TextField, Dialog, DialogTitle, DialogActions, DialogContent, MenuItem} from '@mui/material';
import {orderListState, productListState} from 'GlobalState';
import {useRecoilState} from 'recoil';
import {kiminiRevokeAdmin} from 'Utils/Kimini';
import {listOrder, listProduct, convertTime} from 'Utils/Default';
import {updateShipping} from 'Utils/Shipping';
import {updateOrder} from 'Utils/Order';
import {sendShippingMail} from 'Utils/Stibee';


export default function OrderManagementScreen() {
  const [orderList, setOrderList] = useRecoilState(orderListState);
  const [productList, setProductList] = useRecoilState(productListState);
  const [clickedOrder, setClickedOrder] = useState(null);

  const onRefresh = async () => {
    try {
      setOrderList([]);
      const data = await listOrder();
      const productData = await listProduct();
      if (data) {
        setOrderList(data);
      }
      if (productData) {
        setProductList(productData);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <div>
      <Button variant='contained' onClick={onRefresh}>
        구매자 내역 다시 불러오기
      </Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>고객번호</TableCell>
              <TableCell>유료결제 / 주문일</TableCell>
              <TableCell>아이패드 주문일</TableCell>
              <TableCell>안드로메다 팩 출고 등록일</TableCell>
              <TableCell>송장번호</TableCell>
              <TableCell>주문상품</TableCell>
              <TableCell>상품가격</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>우편번호</TableCell>
              <TableCell>주소</TableCell>
              <TableCell>연락처</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>해지</TableCell>
              <TableCell>배송메일발송여부</TableCell>
              <TableCell>메모</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList.map((row) => (
              <TableRow key={row.shippingId} onClick={() => setClickedOrder(row)}>
                <TableCell></TableCell>
                <TableCell>{convertTime(row.shipping__createdAt)}</TableCell>
                <TableCell>{row.shipping__ipad_order_date != null ? convertTime(row.shipping__ipad_order_date) : row.shipping__ipad_order_date}</TableCell>
                <TableCell>{row.shipping__andromeda_order_date != null ? convertTime(row.shipping__andromeda_order_date) : row.shipping__andromeda_order_date}</TableCell>
                <TableCell>{row.shipping__tracking_number}</TableCell>
                <TableCell>{row.product__name}</TableCell>
                <TableCell>{row.product__price}</TableCell>
                <TableCell>{row.shipping__receiverName}</TableCell>
                <TableCell>{row.shipping__postcode}</TableCell>
                <TableCell>{row.shipping__roadAddress}, {row.shipping__addressDetail}</TableCell>
                <TableCell>{row.shipping__receiverPhone}</TableCell>
                <TableCell>{row.customer__email}</TableCell>
                <TableCell>
                  <Checkbox disable checked={row.shipping__canceled === 'true' || row.shipping__canceled === 'checked' ? true : false}/>
                </TableCell>
                <TableCell>
                  <Checkbox disable checked={row.shipping__sendmail === 'true' || row.shipping__sendmail === 'checked' ? true : false}/>
                </TableCell>
                <TableCell>{row.shipping__admin_memo}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdateOrderDialog
        clickedOrder={clickedOrder}
        productList={productList}
        onClose={() => setClickedOrder(null)}
        onUpdate={() => {
          onRefresh();
          setClickedOrder(null);
        }}
      />
    </div>
  );
}

function UpdateOrderDialog({clickedOrder, onClose, onUpdate, productList}) {
  const [andromedaOrderDate, setAndromedaOrderDate] = useState();
  const [trackingNumber, setTrackingNumber] = useState();
  const [ipadOrderDate, setIpadOrderDate] = useState();
  const [addressDetail, setAddressDetail] = useState();
  const [receiverName, setReceiverName] = useState();
  const [roadAddress, setRoadAddress] = useState();
  const [adminMemo, setAdminMemo] = useState();
  const [productId, setProductId] = useState();
  const [canceled, setCanceled] = useState();
  const [sendMail, setSendMail] = useState();
  const [postCode, setPostCode] = useState();

  useEffect(() => {
    if (clickedOrder) {
      const canceled = clickedOrder.shipping__canceled;
      const _sendMail = clickedOrder.shipping__sendmail;
      const ipadDate = clickedOrder.shipping__ipad_order_date;
      const andromedadDate = clickedOrder.shipping__andromeda_order_date;
      setAndromedaOrderDate(andromedadDate != null ? convertTime(andromedadDate) : andromedadDate);
      setSendMail(_sendMail === 'checked' || _sendMail === 'true' ? true : false);
      setCanceled(canceled === 'checked' || canceled === 'true' ? true : false);
      setIpadOrderDate(ipadDate != null ? convertTime(ipadDate) : ipadDate);
      setTrackingNumber(clickedOrder.shipping__tracking_number);
      setAddressDetail(clickedOrder.shipping__addressDetail);
      setReceiverName(clickedOrder.shipping__receiverName);
      setRoadAddress(clickedOrder.shipping__roadAddress);
      setAdminMemo(clickedOrder.shipping__admin_memo);
      setPostCode(clickedOrder.shipping__postcode);
      setProductId(clickedOrder.product__id);
    }
  }, [clickedOrder]);

  const onApply = async () => {
    if (!clickedOrder) return;
    try {
      const shippingUpdateData = {
        andromeda_order_date: andromedaOrderDate,
        tracking_number: trackingNumber,
        ipad_order_date: ipadOrderDate,
        address_detail: addressDetail,
        receiver_name: receiverName,
        road_address: roadAddress,
        admin_memo: adminMemo,
        canceled: canceled,
        sendmail: sendMail,
        postcode: postCode,
      };
      const productUpdateData = {
        productId: productId
      };
      if(shippingUpdateData.sendmail === 'checked'){
        const today = new Date();
        const date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          today.getHours(),
          today.getMinutes(),
          today.getSeconds()
        );
        const shippingStibeeData = {
          address: clickedOrder.shipping__roadAddress + ', ' + clickedOrder.shipping__addressDetail,
          name: clickedOrder.customer__firstName + clickedOrder.customer__lastName,
          product_name: clickedOrder.product__name,
          email: clickedOrder.customer__email,
          shipping_number: trackingNumber,
          send_date: date,
        };
        await sendShippingMail(shippingStibeeData);
      }
      if(shippingUpdateData.canceled === 'checked'){
        const userData = clickedOrder.customerId;
        await kiminiRevokeAdmin(userData);
      }
      const productData = await updateOrder(clickedOrder.shippingId, productUpdateData);
      const shippingData = await updateShipping(clickedOrder.shippingId, shippingUpdateData);
      if (shippingData && productData && onUpdate) {
        onUpdate();
      }
    } catch (e) {
      console.warn(e);
    }
  };

  if (!clickedOrder) {
    return null;
  }
  return (
    <Dialog id='order-info-update' open={clickedOrder != null} onClose={onClose}>
      <DialogTitle>{clickedOrder.id}</DialogTitle>
      <DialogContent>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='IpadOrderDate'
            value={ipadOrderDate}
            type='date'
            InputLabelProps={{shrink: true}}
            onChange={(e) => setIpadOrderDate(e.target.value)}
          />
        </div>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='AndromedaOrderDate'
            value={andromedaOrderDate}
            type='date'
            InputLabelProps={{shrink: true}}
            onChange={(e) => setAndromedaOrderDate(e.target.value)}
          />
        </div>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='TrackingNumber'
            value={trackingNumber}
            InputLabelProps={{shrink: true}}
            onChange={(e) => setTrackingNumber(e.target.value)}
          />
        </div>
        <div>
          <TextField
            id="outlined-select-currency"
            variant='standard'
            fullWidth
            select
            label='ProductId'
            value={productId}
            InputLabelProps={{shrink: true}}
            onChange={(e) => setProductId(e.target.value)}
          >
            {productList.map((row) => (
              <MenuItem key={row.id} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='PostCode'
            value={postCode}
            InputLabelProps={{shrink: true}}
            onChange={(e) => setPostCode(e.target.value)}
          />
        </div>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='RoadAddress'
            value={roadAddress}
            InputLabelProps={{shrink: true}}
            onChange={(e) => setRoadAddress(e.target.value)}
          />
        </div>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='AddressDetail'
            value={addressDetail}
            InputLabelProps={{shrink: true}}
            onChange={(e) => setAddressDetail(e.target.value)}
          />
        </div>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='ReceiverName'
            value={receiverName}
            InputLabelProps={{shrink: true}}
            onChange={(e) => setReceiverName(e.target.value)}
          />
        </div>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='AdminMemo'
            value={adminMemo}
            InputLabelProps={{shrink: true}}
            onChange={(e) => setAdminMemo(e.target.value)}
          />
        </div>
        <div>
          <span>해지 여부</span>
          <Checkbox
            label='Canceled'
            checked={canceled}
            onChange={() => setCanceled('checked')}
          />
        </div>
        <div>
          <span>배송 메일 발송</span>
          <Checkbox
            label='체크 후 적용 시 메일 발송'
            checked={sendMail}
            onChange={() => setSendMail('checked')}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={() => onApply()}
        >
            적용
        </Button>
      </DialogActions>
    </Dialog>
  );
}