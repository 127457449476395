import React, {useState} from 'react';
import 'Styles/AuthFindPassword.css';
import { Button, TextField} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {sendPasswordReset} from 'Utils/Auth';
import {useSnackbar} from 'Hooks';

export function FindPasswordScreen() {
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const [userEmail, setUserEmail] = useState('');
  const [onceSubmitted, setOnceSubmitted] = useState(false);

  const validateEmail = () => {
    const check = /^[0-9a-zA-Z]([-_+\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (!check.test(userEmail)) return '올바른 이메일을 입력해 주세요.';
    return null;
  };

  const onSubmit = async () => {
    try {
      await sendPasswordReset(userEmail);
      showSnackbar('해당 이메일로 비밀번호 재설정 메일이 발송되었습니다.');
    } catch (e) {
      showSnackbar('비밀번호 재설정 메일을 보내는데 실패하였습니다.', 'error');
      console.warn(e);

    }
  };

  const checkFormat = () => {

    if (validateEmail() != null) {
      return false;
    }
    return true;
  };

  return(
    <div id="auth-find-password">
      <div className="auth-options">
        <h1>비밀번호 찾기</h1>

        <div className="auth-find">
          <span>비밀번호를 잊으셨나요?<br/>
                회원가입 시 등록한 정보를 입력해주세요.<br/>
                이메일로 임시 비밀번호를 발송해드리겠습니다.<br/>
                재설정 메일이 오지 않을 경우, 스팸메일함을 확인해주세요.
          </span>
        </div>
        <div className="auth-option-field">
          <span>이메일</span>
        </div>
        <TextField
          required
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          hiddenLabel
          fullWidth
          placeholder ="이메일을 입력해 주세요."
          error= {onceSubmitted && validateEmail() != null }
          helperText={onceSubmitted ? validateEmail() : undefined}
        />

        <Button
          variant="contained"
          fullWidth
          className="auth-button"
          onClick = {() => {
            setOnceSubmitted(true);
            if (checkFormat() == false) {
              return ;
            }
            onSubmit();
          }}
        >확인</Button>

        <div className="auth-find-id">
          <span>이메일이 기억나지 않는다면?&nbsp;&nbsp;
            <Button
              onClick={() => {
                navigate('/auth/findemail/');
              }}
              color="gray">
              <u>이메일 찾기 바로가기</u>
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
}