import {server} from './Axios';
import {getAuthHeader} from './Auth';


export function kiminiNumToPlan(num) {
  if (!num) {
    return null;
  }
  return `monthly_${num}x`;
}

export async function kiminiRegister(planCode, lastName, firstName, email) {
  const body = {planCode, lastName, firstName, email};
  const rsp = await server.post('/kimini/register', body, {headers: getAuthHeader()});
  return rsp.data;
}

export async function kiminiRegisterAdmin(uid, lastName, firstName, email) {
  const body = {uid, lastName, firstName, email};
  const rsp = await server.post('/kimini/registerAdmin', body, {headers: getAuthHeader()});
  return rsp.data;
}

export async function kiminiRevoke(expireAt) {
  const body = {expireAt};
  const rsp = await server.post('/kimini/revoke', body, {headers: getAuthHeader()});
  return rsp.data;
}

export async function kiminiRevokeAdmin(userId) {
  const body = {userId};
  const rsp = await server.post('/kimini/revokeAdmin', body, {headers: getAuthHeader()});
  return rsp.data;
}

export async function kiminiLogin() {
  const rsp = await server.post('/kimini/login', {}, {headers: getAuthHeader()});
  return rsp.data;
}
