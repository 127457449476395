import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Button, ButtonGroup, IconButton, Drawer, Divider, Dialog, DialogActions, DialogTitle} from '@mui/material';
import {Menu as MenuIcon, Close as CloseIcon} from '@mui/icons-material';
// import {KeyboardArrowDown as KeyboardArrowDownIcon} from '@material-ui/icons';
import {authState, myState, mypageMenuState} from 'GlobalState';
import {useResponsive, useAdminSignal} from 'Hooks';
import {useRecoilValue, useRecoilState} from 'recoil';
import {logout} from 'Utils/Auth.js';
import 'Styles/Navbar.css';

export default function Navbar() {
  const {device} = useResponsive();
  const navigate = useNavigate();
  const pushAdminSignal = useAdminSignal(3);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [textbookOpen, setTextbookOpen] = useState(true);

  const [showNav, setShowNav] = useState(true);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const [mypageMenuOpen, setMypageMenuOpen] = useRecoilState(mypageMenuState);

  const auth = useRecoilValue(authState);
  const me = useRecoilValue(myState);
  // const [mypageMenuOpen, setMypageMenuOpen] = useRecoilState(mypageMenuState);

  const {pathname} = useLocation();
  const isMypage = pathname.includes('mypage');

  let lastScroll = 0;

  useEffect(() => {
    const controlNavbar = () => {
      const isScrollUp = window.scrollY < lastScroll;
      lastScroll = window.scrollY;
      setShowNav(isScrollUp);
    };
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, []);

  const renderUserWebIcon = () => {
    if (auth) {
      const name = me?.lastName + me?.firstName;
      const symbol = name ? name + ' 님' : '??';
      return (
        <div
          className='user-icon-login'
          // onClick={() => {
          //   if (isMypage) {
          //     if (device == 'mobile') {
          //       setMypageMenuOpen(!mypageMenuOpen);
          //     }
          //   } else {
          //     navigate('/mypage');
          //   }
          // }}
        >
          <div className='user-dropdown'>
            <div className='open-menu'>
              <span>{symbol}</span>
              <img className='before-hover' src='/images/misc/checkDown.svg'/>
              <img className='after-hover' src='/images/misc/checkUp.svg'/>
            </div>
            <ul className='dropdown-section'>
              <div className='user-area'>
                <div style={{paddingTop:'10px'}}/>
                <li onClick={() => navigate('/mypage')}>회원 정보 수정</li>
                <li onClick={() => navigate('/mypage/studyRoom')}>학습 자료실</li>
                <Divider sx={{height: '1px', width: '78px', margin:'auto', marginTop:'20px', marginBottom:'10px'}} />
                <li onClick={() => setLogoutDialogOpen(true)}>로그아웃</li>
              </div>
            </ul>
          </div>

          <Dialog
            open={logoutDialogOpen}
            onClose={() => setLogoutDialogOpen(false)}
            fullWidth
            maxWidth="xs"
          >
            <IconButton
              aria-label="close"
              onClick={() => setLogoutDialogOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon/>
            </IconButton>
            <div style={{marginBottom:'40px'}}/>
            <DialogTitle
              sx={{
                background:'#F6F8FA',
                margin:'0 20px 0 20px',
                textAlign:'center'
              }}
            >
          로그아웃 하시겠습니까?
            </DialogTitle>
            <div style={{marginBottom:'10px'}}/>
            <Divider/>
            <DialogActions sx={{ margin: 1 }}>
              <Button
                variant='contained'
                color='cancel'
                onClick={() => setLogoutDialogOpen(false)}
              >
            취소
              </Button>
              <Button
                variant='contained'
                onClick={async () => await logout()}
              >
            확인
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else {
      return (
        <div className='before-user'>
          <ButtonGroup variant="text" aria-label="text button group">
            {/* <Button onClick={() => navigate('/auth/signup')}>회원가입</Button> */}
            <Button onClick={() => navigate('/auth/kakaologin')}>로그인</Button>
          </ButtonGroup>
        </div>
      );
    }
  };

  const renderUserMobileIcon = () => {
    if (auth) {
      const name = me?.lastName;
      const symbol = name ? name[0] : '??';
      return (
        <div
          className='user-icon-login'
          onClick={() => {
            if (isMypage) {
              if (device == 'mobile') {
                setMypageMenuOpen(!mypageMenuOpen);
              }
            } else {
              navigate('/mypage');
            }
          }}
        >
          {symbol}
        </div>
      );
    } else {
      return (
        <img
          src="/images/navbar/userBlack.png"
          className='user-icon'
          onClick={() => {
            window.scrollTo({top:0});
            navigate('/auth/kakaologin');
          }}
        />
      );
    }
  };

  const renderDrawer = () => {
    const drawerNavigate = (path) => {
      navigate(path);
      setIsMobileOpen(false);
    };
    return (
      <Drawer
        anchor='left'
        open={isMobileOpen}
        onClose={() => setIsMobileOpen(false)}
        PaperProps={{sx: {width: '272px', bgcolor: 'white'}}}
      >
        <div className='drawer'>
          <div className='drawer-header'>
            <img
              src="/images/navbar/logoBlack.png"
              className='logo'
              onClick={() => navigate('/')}
            />
            <IconButton className='close-icon' onClick={() => setIsMobileOpen(false)}>
              <CloseIcon size='large' sx={{color: 'black'}} />
            </IconButton>
          </div>
          <div style={{marginTop: '20px'}}/>
          <div className='item-box' onClick={() => drawerNavigate('/product')}>
            <span>수강신청</span>
          </div>
          <div className='item-box' onClick={() => setTextbookOpen(!textbookOpen)}>
            <span className='workbook item'>
              학습지 소개
            </span>
            {textbookOpen ?
              (<div className='workbook-dropdown'>
                <img src='/images/misc/checkUp.svg' className='dropdown-img'/>
                <ul className='dropdown-specific'>
                  <li onClick={() => drawerNavigate('/business')}>비즈니스 영어</li>
                  <div style={{marginTop:'40px'}}/>
                  <li onClick={() => drawerNavigate('/travel')}>여행 영어</li>
                </ul>
              </div>)
              :
              (<div className='workbook-dropdown'>
                <img src='/images/misc/checkDown.svg' className='dropdown-img check-down'/>
              </div>)}
          </div>

          <div className='item-box' onClick={() => drawerNavigate('/event')}>
            <span>이벤트</span>
          </div>
          <div className='item-box' onClick={() => drawerNavigate('/?tab=faq') }>
            <span>자주 묻는 질문</span>
          </div>
        </div>
      </Drawer>
    );
  };
  // console.log(showNav);
  const renderWeb = () => {
    return (
      <div id='navbar' style={{top: showNav? '0px': '-113px'}}>
        <div className='limit-section'>
          <div className='top-section'>
            <div className='open-menu'>
              <span className='family-margin'>성인 영어 회화</span>
              <div className='family-hover'>
                <img className='before-hover' src='/images/misc/checkDown.svg'/>
                <img className='after-hover' src='/images/misc/checkUp.svg'/>
              </div>
              <div style={{marginTop:'50px'}}/>
            </div>
            <ul className='family-site'>
              <li><a href='https://musioenglish.kr/'>성인 영어 회화</a></li>
              <div style={{marginTop:'-10px'}}/>
              <li><a href='https://kids.musioenglish.kr/'>초등 영어 회화</a></li>
            </ul>
            <div className='sign-box'>
              {/* <Button
              variant='outlined'
              size='large'
              sx={{borderRadius: 0, pl: 3, pr: 2}}
              onClick={() => navigate('/product')}
              className='purchase-button'
            >
            구매하기 ↗
            </Button> */}
              {renderUserWebIcon()}
            </div>
          </div>
          <div className='menu-section'>
            <img
              src="/images/navbar/logoBlack.png"
              className='logo'
              onClick={() => {
                navigate('/');
                pushAdminSignal();
              }}
            />
            <div className='item-holder'>
              <span
                className='item'
                onClick={() => navigate('/product')}
              >
              수강신청
              </span>
              <div className=''>
                <span className='workbook item'>
                학습지 소개
                </span>
                <ul className='workbook-specific'>
                  <li onClick={() => navigate('/business')}>비즈니스 영어</li>
                  <div style={{marginTop:'-10px'}}/>
                  <li onClick={() => navigate('/travel')}>여행 영어</li>
                </ul>
              </div>

              <span
                className='item'
                onClick={() => navigate('/event')}
              >
              이벤트
              </span>

              <span
                className='item'
                onClick={() => navigate('/?tab=faq')}
              >
              자주 묻는 질문
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMobile = () => (
    <div id='navbar'>
      {isMypage ? undefined :
        <IconButton
          aria-label='menu'
          size='large'
          onClick={() => setIsMobileOpen(!isMobileOpen)}
        >
          <MenuIcon sx={{color: 'black'}}/>
        </IconButton>}
      <div className='logo-box'>
        <img
          src="/images/navbar/logoBlack_m.svg"
          className='logo'
          onClick={() =>  navigate('/')}
        />

        <div className='action-container'>
          {renderUserMobileIcon()}
        </div>
        {renderDrawer()}
      </div>
    </div>
  );

  if (device == 'mobile')
    return renderMobile();
  else
    return renderWeb();
}