import React from 'react';
import 'Styles/AuthFindEmail.css';
import {Button, TextField} from '@mui/material';
import {useNavigate} from 'react-router-dom';

export function FindEmailResultScreen() {
  const navigate = useNavigate();
  return(
    <div id="auth-find-email">
      <div className="auth-options">
        <img
          src='/images/navbar/logoBlack.png'
          className="auth-aka-logo"
          onClick={() => {
            navigate('/');
          }}
        />
        <div className='auth-find'>
          <span>고객님의 정보와 일치하는 이메일입니다.</span>
        </div>
        <div className="auth-option-field">
          <span>이메일</span>
        </div>
        <TextField hiddenLabel fullWidth defaultValue="이메일"
          InputProps={{
            readOnly: true,
          }}/>

        <div>
          <Button variant="contained" className="auth-button">확인</Button>
        </div>
      </div>
    </div>
  );
}