import { loadTossPayments } from '@tosspayments/payment-sdk';
import {TOSS_CLIENT_KEY} from '../config.js';

export async function requestPaymentKey(orderId, orderName, amount, customerName) {
  const toss = await loadTossPayments(TOSS_CLIENT_KEY);
  const host = window.location.host;
  try {
    // return rsp
    await toss.requestPayment('카드', {
      amount: amount,
      orderId: orderId,
      orderName: orderName,
      customerName: customerName,
      successUrl: `http://${host}/toss/loading`,
      failUrl: `http://${host}/toss/fail`,
    });
    // console.log('rsp', rsp);
  } catch (e) {
    console.warn(e);
  }
}