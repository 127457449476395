import React, {Fragment, useState, useEffect} from 'react';
import 'Styles/MyPage.css';
import {Button, Divider,IconButton, Drawer} from '@mui/material';
import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import {useLocation, useNavigate, Navigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState, useRecoilState} from 'recoil';
import {myState, mypageMenuState, myOrderState} from 'GlobalState';
import { useResponsive } from 'Hooks';

import {listCustomerOrder} from 'Utils/Order';

import FaqScreen from './FaqScreen';
import NavigateKiminiScreen from './NavigateKiminiScreen';
import UpdateuserInfoScreen from './UpdateUserInfoScreen';
import StudyRoomScreen from './StudyRoomScreen';
import CheckOrderScreen from './CheckOrderScreen';
import CloseIcon from '@mui/icons-material/Close';

import {logout} from 'Utils/Auth.js';

const paneList = [
  {
    path: 'userInfo',
    title: '회원 정보 수정',
  },
  {
    path: 'checkOrder',
    title: '수강 신청 내역',
  },
  {
    path: 'studyRoom',
    title: '학습 자료실',
  },
  {
    path: 'faq',
    title: '자주 묻는 질문'
  },
  {
    path: 'kimini',
    title: '키미니 화상 영어',
  },
];

export function MyPageMainScreen() {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const me = useRecoilValue(myState);
  const [mobileMenuOpen, setMobileMenuOpen] = useRecoilState(mypageMenuState);
  const setOrderList = useSetRecoilState(myOrderState);
  const {device} = useResponsive();
  const name = me?.lastName + me?.firstName;

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  useEffect(() => {
    const bringMyOrders = async () => {
      try {
        const data = await listCustomerOrder();
        // console.log(data);
        setOrderList(data);
      } catch (e) {
        console.warn(e);
      }
    };
    bringMyOrders();
  }, []);

  const onLogout = async () => {
    await logout();
  };


  function renderMenuItem(menu) {
    const isSelected = pathname.includes(menu.path);
    if (device == 'mobile') {
      const drawerNavigate = (path) => {
        navigate(path);
        setMobileMenuOpen(false);
      };
      return (
        <div className='item-box' onClick={() => drawerNavigate(`/mypage/${menu.path}`)}>
          <span>{menu.title}</span>
        </div>
      );
    }
    return (
      <Fragment>
        <Button
          variant='text'
          onClick={() => navigate(`/mypage/${menu.path}`)}
          className={isSelected ? 'menu-btn selected' : 'menu-btn'}
          fullWidth
          size='large'
        >
          <span className="menu-list">{menu.title}</span>
        </Button>
        <hr/>
      </Fragment>
    );
  }

  function renderContent() {
    if (pathname.includes('checkOrder')) {
      return <CheckOrderScreen/>;
    }
    if (pathname.includes('studyRoom')) {
      return <StudyRoomScreen/>;
    }
    if (pathname.includes('faq')) {
      return <FaqScreen/>;
    }
    if (pathname.includes('kimini')) {
      return <NavigateKiminiScreen/>;
    }
    if (pathname.includes('userInfo')) {
      return <UpdateuserInfoScreen/>;
    }
    // default
    return <Navigate to='/mypage/userInfo'/>;
  }

  function renderMenuPane() {
    if (device == 'mobile') {
      return (
        <Drawer
          anchor='right'
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          PaperProps={{sx: {width: '70%', bgcolor: 'white'}}}
        >
          <div className='drawer-container'>
            <IconButton className='close-icon' onClick={() => setMobileMenuOpen(false)}>
              <CloseIcon size='large' sx={{color: '#000000'}} />
            </IconButton>
            <div style={{marginTop:'90px'}}/>
            <img src='/images/misc/musio.svg'/>
            <span className='user-name'>{name} 님</span>
            <div style={{marginTop:'20px'}}/>
            {paneList.map(e => {
              return (
                <div key={e.path}>
                  {renderMenuItem(e)}
                </div>
              );
            })}

            <Button
              variant='text'
              onClick={() => setLogoutDialogOpen(true)}
              className='logout-btn'
              fullWidth
              size='large'
            >
              로그아웃
            </Button>
          </div>
        </Drawer>

      );

    }
    if (device == 'desktop') {
      return (
        <div className='menu-container'>
          <h2 className="greet-message">안녕하세요, {me?.firstName}님!</h2>
          <div className='menu-pane'>
            <hr/>
            {paneList.map(e => {
              return (
                <div key={e.path}>
                  {renderMenuItem(e)}
                </div>
              );
            })
            }
            <Button
              variant='text'
              onClick={() => setLogoutDialogOpen(true)}
              className='logout-btn'
              fullWidth
              size='large'
            >
            로그아웃
            </Button>
          </div>
        </div>
      );
    }
  }

  return (
    <div id='mypage-main'>
      {device == 'desktop' ? <div style={{width: '20px'}}/> : ''}

      {renderMenuPane()}

      <div className='content-container'>
        {renderContent()}
      </div>

      <Dialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <IconButton
          aria-label="close"
          onClick={() => setLogoutDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
        <div style={{marginBottom:'40px'}}/>
        <DialogTitle
          sx={{
            background:'#F6F8FA',
            margin:'0 20px 0 20px',
            textAlign:'center'
          }}
        >
          로그아웃 하시겠습니까?
        </DialogTitle>
        <div style={{marginBottom:'10px'}}/>
        <Divider/>
        <DialogActions sx={{ margin: 1 }}>
          <Button
            variant='contained'
            color='cancel'
            onClick={() => setLogoutDialogOpen(false)}
          >
            취소
          </Button>
          <Button
            variant='contained'
            onClick={() => onLogout()}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}