import React, {useState} from 'react';
import 'Styles/MyPageStudyRoom.css';
import {useRecoilValue} from 'recoil';
import {myOrderState} from 'GlobalState';
import {Tabs, Tab } from '@mui/material';
import {grammarWorkbook, imageWordbook} from 'Assets/studyroom';
import {ReactComponent as PlayIcon} from 'Assets/icons/play-icon.svg';
import {ReactComponent as FileIcon} from 'Assets/icons/file-icon.svg';
import {getToday, createDownLoad} from 'Utils/Default';


export default function StudyRommScreen() {
  const [tabIdx, setTabIdx] = useState(0);
  const orderList = useRecoilValue(myOrderState);

  if (!orderList || orderList?.length == 0) {
    return (
      <div id="study-main">
        <h2>수강 신청 후 이용하실 수 있습니다.</h2>
      </div>
    );
  }
  return (
    <div id="study-main">
      <h3>학습 자료실</h3>
      <hr/>
      <Tabs
        value={tabIdx}
        onChange={(e, newVal) => setTabIdx(newVal)}
        variant='fullWidth'
        centered
      >
        <Tab
          label='Grammar Workbook &amp; Class'
          className='tab'
        />
        <Tab
          label="이미지 단어장"
          className='tab'
        />
      </Tabs>
      <hr/>
      {
        tabIdx == 0 &&
        <GrammarWorkbook/>
      }
      {
        tabIdx == 1 &&
        <ImageWordbook/>
      }
    </div>
  );
}

function GrammarWorkbook() {
  const [rowId, setRowId] = useState(null);
  const orderList = useRecoilValue(myOrderState);

  const downLoadFile = async (fileUrl) => {
    window.open(fileUrl);
    const downLoadData = {
      customer_id : orderList[0].customerId,
      download_at : getToday(),
      file_url : fileUrl,
      last_name : orderList[0].shipping.receiverName,
      phone_number : orderList[0].shipping.receiverPhone
    };
    await createDownLoad(downLoadData);
  };
  const renderHeader = () => {
    return (
      <div className='item-row'>
        <div className='section'>
          <span>주차</span>
        </div>
        <div className='title'>
          <span>제목</span>
        </div>
      </div>
    );
  };
  const renderRow = (item) => {
    return (
      <div className='item-row'>
        <div className='section'>
          <h5>{item.section}</h5>
        </div>
        <div className='title'>
          <span>{item.title}</span>
        </div>
        <div className='play-icon' style={item.full === null && item.shorts === null ? {display: 'none'} : {}}>
          <PlayIcon
            className='icon'
            onClick={() => setRowId(item.section)}
          />
          <div className={item.full === null || item.shorts === null ? 'btn-area-s' : 'btn-area'} style={item.section === rowId ? {display: 'block'} : {display: 'none'}}>
            <div className='btn-box'>
              <li onClick={() => window.open(item.full)} style={item.full === null ? {display: 'none'} : {display: 'block'}}>Full</li>
              <li onClick={() => window.open(item.shorts)} style={item.shorts === null ? {display: 'none'} : {display: 'block'}}>Shorts</li>
            </div>
          </div>
        </div>
        <div className='file-icon'>
          <FileIcon
            className='icon'
            onClick={() => downLoadFile(item.fileUrl)}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      {renderHeader()}
      {grammarWorkbook.map((item, idx) => {
        return (
          <div key={idx}>
            <hr/>
            {renderRow(item)}
          </div>
        );
      })
      }
    </div>
  );
}

function ImageWordbook() {
  const orderList = useRecoilValue(myOrderState);

  const downLoadFile = async (fileUrl) => {
    window.open(fileUrl);
    const downLoadData = {
      customer_id : orderList[0].customerId,
      download_at : getToday(),
      file_url : fileUrl,
      last_name : orderList[0].shipping.receiverName,
      phone_number : orderList[0].shipping.receiverPhone
    };
    await createDownLoad(downLoadData);
  };
  const renderHeader = () => {
    return (
      <div className='item-row'>
        <div className='section'>
          <span>순서</span>
        </div>
        <div className='title'>
          <span>제목</span>
        </div>
      </div>
    );
  };
  const renderRow = (item) => {
    return (
      <div className='item-row'>
        <div className='section'>
          <h5>{item.section}</h5>
        </div>
        <div className='title'>
          <span>{item.title}</span>
        </div>
        <div className='file-icon'>
          <FileIcon
            className='icon'
            onClick={() => downLoadFile(item.fileUrl)}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      {renderHeader()}
      {imageWordbook.map((item, idx) => {
        return (
          <div key={idx}>
            <hr/>
            {renderRow(item)}
          </div>
        );
      })
      }
    </div>
  );
}