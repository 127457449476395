import React, {useState} from 'react';
import Carousel from 'react-material-ui-carousel';
import 'Styles/SlideImage.css';
import 'Styles/Business.css';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Navigation, Pagination } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// const ItemCard = () => {
//   var slideImg =[];
//   return (
//     <Swiper
//       className='slide-img'
//       slidesPerView={'auto'}
//       centeredSlides={true}
//       spaceBetween={-70}
//       pagination={{
//         clickable: true,
//       }}
//       loop={true}
//       autoplay={{
//         delay: 2000,
//         disableOnInteraction: false,
//       }}
//       modules={[Autoplay, Pagination, Navigation]}
//     >
//       {slideImg.map((image, index) => (
//         <SwiperSlide zoom key={index}>
//           <img src={image.name}/>
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   );
// };

export function SlideImage(slideImg) {
  return (
    <Swiper
      className='slide-img'
      slidesPerView={'auto'}
      centeredSlides={true}
      spaceBetween={-70}
      pagination={{
        clickable: true,
      }}
      loop={true}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination]}
      navigation ={false}
    >
      {slideImg.name.map((image, index) => (
        <SwiperSlide zoom key={index}>
          <img src={image}/>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export function BusinessSlide(slideImg) {
  return (
    <Swiper
      modules={[Pagination, Navigation]}
      className='business-slide'
      slidesPerView={3}
      autoHeight={true}
      loop={true}
      navigation ={true}
    >
      {slideImg.name.map((image, index) => (
        <SwiperSlide zoom key={index}>
          <img src={image}/>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export function BusinessMobile(slideImg) {
  return (
    <Swiper
      modules={[Pagination, Navigation]}
      className='business-slide'
      slidesPerView={'auto'}
      centeredSlides={true}
      spaceBetween={-150}
      autoHeight={true}
      loop={true}
      navigation ={true}
    >
      {slideImg.name.map((image, index) => (
        <SwiperSlide zoom key={index}>
          <img src={image}/>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export function TextbookSlide(slideImg) {
  return (
    <Swiper
      modules={[Pagination, Navigation]}
      className='business-textbook travel-textbook'
      autoHeight={true}
      loop={true}
      navigation={true}
    >
      {slideImg.name.map((image, index) => (
        <SwiperSlide zoom key={index}>
          <img src={image}/>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}