import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox} from '@mui/material';
import {Button, TextField, Dialog, DialogTitle, DialogActions, DialogContent} from '@mui/material';
import {customerListState} from 'GlobalState';
import {useRecoilState} from 'recoil';
import {updateCustomerKimini} from 'Utils/Customer';
import {listCustomer, convertTime} from 'Utils/Default';
import {kiminiRegisterAdmin, kiminiRevokeAdmin} from 'Utils/Kimini';


export default function UserManagementScreen() {
  const [customerList, setCustomerList] = useRecoilState(customerListState);
  const [clickedCustomer, setClickedCustomer] = useState(null);

  const onRefresh = async () => {
    try {
      setCustomerList([]);
      const data = await listCustomer();
      if (data) {
        setCustomerList(data);
      }
    } catch (e) {
      console.warn(e);
    }
  };


  return (
    <div>
      <Button variant='contained' onClick={onRefresh}>
        유저 내역 다시 불러오기
      </Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>고객번호</TableCell>
              <TableCell>가입일</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>연락처</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>키미니 임시권한</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerList.map((row) => (
              <TableRow key={row.id} onClick={() => setClickedCustomer(row)}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{convertTime(row.createdAt)}</TableCell>
                <TableCell>{row.firstName}{row.lastName}</TableCell>
                <TableCell>{row.phoneNumber}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.adminKimini ? '사용중' : ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdateProductDialog
        clickedCustomer={clickedCustomer}
        onClose={() => setClickedCustomer(null)}
        onUpdate={() => {
          onRefresh();
          setClickedCustomer(null);
        }}
      />
    </div>
  );
}


function UpdateProductDialog({clickedCustomer, onClose, onUpdate}) {
  const [kimini, setKimini] = useState();
  const [name, setName] = useState();
  const [email, setemail] = useState();

  useEffect(() => {
    if (clickedCustomer) {
      console.log(clickedCustomer);
      setKimini(clickedCustomer.adminKimini);
      setName(clickedCustomer.firstName + clickedCustomer.lastName);
      setemail(clickedCustomer.email);
    }
  }, [clickedCustomer]);

  const onApply = async () => {
    if (!clickedCustomer) return;
    try {
      if(!kimini) { setKimini(false); }
      const updateData = {
        admin_kimini: kimini,
      };
      const data = await updateCustomerKimini(clickedCustomer.id, updateData);
      if(kimini) {
        await kiminiRegisterAdmin(clickedCustomer.id, clickedCustomer.lastName === '' ? '님' : clickedCustomer.lastName, clickedCustomer.firstName, email);
      } else {
        await kiminiRevokeAdmin(clickedCustomer.id);
      }
      console.log(data);
      if (data && onUpdate) {
        onUpdate();
      }
    } catch (e) {
      console.warn(e);
    }
  };

  if (!clickedCustomer) {
    return null;
  }
  return (
    <Dialog id='customer-info-update' open={clickedCustomer != null} onClose={onClose}>
      <DialogTitle>{clickedCustomer.id}</DialogTitle>
      <DialogContent>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='name'
            value={name}
            disabled
          />
        </div>
        <div>
          <TextField
            variant='standard'
            fullWidth
            label='email'
            value={email}
            disabled
          />
        </div>
        <div>
          <span>임시 키미니 적용</span>
          <Checkbox
            label='Canceled'
            checked={kimini}
            onChange={() => setKimini(kimini ? false : true)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={() => onApply()}
        >
            적용
        </Button>
      </DialogActions>
    </Dialog>
  );
}