import React, {useState, useEffect} from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import {Button} from '@mui/material';
import { useNavigate } from 'react-router';
import { productListState, currentProductState } from 'GlobalState';
import {useRecoilValue, useRecoilState} from 'recoil';
import {prettyPrice} from 'Utils/Misc';
import {useResponsive} from 'Hooks';

import 'Styles/Product.css';

export function ProductScreen() {
  return (
    <div>
      <ProductMainScreen/>
      <ProductSubScreen/>
    </div>
  );
}

function ProductMainScreen() {
  const {device} = useResponsive();
  const [englishType, setEnglishType] = useState(null); // [travel, business]
  const [ipadType, setIpadType] = useState(null); // [ipad64GB, ipad0GB]
  const [kiminiType, setKiminiType] = useState(null); // [0, 12, 24, 36]
  const [airHover, setAirHover] = useState('none');
  const [proHover, setProHover] = useState('none');

  const productList = useRecoilValue(productListState);
  const [currentProduct, setCurrentProduct] = useRecoilState(currentProductState);

  const hoverController = (type) => {
    if(type === 'air'){
      setAirHover('block');
      setProHover('none');
    }else{
      setProHover('block');
      setAirHover('none');
    }
  };
  const cSelected = '#2388ff';
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // console.log(productList);
    const matched = productList.find((item) => {
      return item.meta.englishType == englishType && item.meta.ipad == ipadType && item.meta.kimini == kiminiType;
    });
    setCurrentProduct(matched);

  }, [englishType, ipadType, kiminiType]);

  // const renderEduImage = () => {
  //   if(renderWeb()) {
  //     if (englishType == 'travel') {
  //       return (
  //         <img src='/images/mainPage/productTravel.png'/>
  //       );
  //     }
  //     // englishType == business
  //     return (
  //       <img src='/images/mainPage/productBusiness.png'/>
  //     );
  //   }
  // };

  const renderFinalProduct = () => {
    if (!currentProduct) {
      return null;
    }
    return (

      <div className='price-box'>
        <div className='annual-price'>
          <span><s>총 {prettyPrice(currentProduct.price)} 원</s> (12개월 무이자 할부시)</span>
          {/* <span>{prettyPrice(currentProduct.price)} 원</span> */}
        </div>
        <div className='monthly-price'>
          <span className='month-small-font'>월 <br/><span style={{fontWeight: 700}}></span> </span>
          <span className='month-large-font'>{prettyPrice(currentProduct?.price / 12)}</span>
          <span className='month-small-font'>원</span>
        </div>

        <Button
          fullWidth
          variant='contained'
          className='btn'
          disabled={currentProduct == undefined}
          onClick={() => navigate('/confirmProduct')}
        >
                구매하기
        </Button>

      </div>
    );
  };

  const renderWeb = () => {
    return (
      <><div>
        <img style={{maxWidth: '100%', cursor: 'pointer', width: '100%'}} src='/images/mainPage/eventBanner.svg' onClick={() => navigate('/eventPage/?tab=benefit1Ref')} />
      </div>
      <div id='product-main-screen'>
        <div className='split-half'>
          <div className='split-image'>
            <Controller>
              <Scene duration={500} pin={true} triggerHook='onLeave'>
                <div className='img-box'>
                  {englishType == 'travel' ? <img src='/images/mainPage/productTravel.png' />
                    : <img src='/images/mainPage/productBusiness.png' />}
                </div>
              </Scene>
            </Controller>
          </div>

          <div className='split-description'>
            <br /><br /><span className='andromeda-package'>안드로메다 패키지</span><br />
            <span className='option-message'>옵션에 따른 총 구매 가격을 확인해 보세요.</span><br /><br />
            <span>원하는 교육 패키지를 선택하세요.</span>

            <div className='package-container'>
              {[{ label: '여행 영어', value: 'travel' }, { label: '비즈니스 영어', value: 'business' }].map((e) => {
                return (
                  <div
                    key={e.value}
                    className='option'
                    onClick={() => setEnglishType(e.value)}
                    style={{ borderColor: englishType == e.value ? cSelected : undefined }}
                  >
                    <div className='row'>
                      <span className='bold'>{e.label}</span>
                      <span STYLE="font-size:11.0pt">52주 과정</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <hr />

            <span>원하는 모델을 선택하세요.</span>
            <br />
            <span className='select-model'>※사양은 64GB, 스페이스 그레이, Wi-Fi 입니다.</span>
            <div className='capacity-container' style={{position: 'relative'}}>
              {[{label: 'iPad Air(5세대)',
                value: 'ipad64GB',
                image: <img src='/images/mainPage/infoCircle.svg' onMouseOver={() => hoverController('air')}></img>,
                hover: <img style={{position: 'absolute', display: airHover,right: '0px', top: '-50px'}} src='/images/mainPage/iPadAirTooltip.svg'></img>,
                closebtn: <img className='hover-close-btn' src='/images/misc/checkButton2.png' style={{display: airHover}} onClick={() => setAirHover('none')}></img>}
              ,{label: '선택안함',
                value: 'ipad0GB',
              }
              ].map((e) => {
                return (
                  <div
                    key={e.value}
                    className='option'
                    onClick={() => setIpadType(e.value)}
                    style={{ borderColor: ipadType == e.value ? cSelected : undefined }}
                  >
                    <div className='row'>
                      <span className='bold'>{e.label}</span>
                      <div style={{position: 'relative', display: 'flex'}}>
                        {e.image}{e.hover}
                        {e.closebtn}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <hr />

            <span>온라인 화상 영어 수업 Kimini 를 선택하세요.</span>
            <br />
            <span className='select-model'>※수강 기간: 12개월</span>

            <div className='kimini-container'>
              {[{ label: '선택 안함', value: 0 }, { label: '월 8회', value: 8 }, { label: '월 20회', value: 20 }, { label: '월 30회', value: 30 }].map((e) => {
                return (
                  <div
                    key={e.value}
                    className='option'
                    onClick={() => setKiminiType(e.value)}
                    style={{ borderColor: kiminiType == e.value ? cSelected : undefined }}
                  >
                    <span className='bold'>{e.label}</span>
                  </div>
                );
              })}
            </div>

            <hr />

            <div className='description-box'>
              <span>안드로메다 패키지 기본 제공(무료)</span>
              <div style={{ padding: '10px' }}>
                <span className='bold'> &#183; 뮤지오 AI 톡 (태블릿 앱) 1년 이용권</span><br />
                <span className='bold'> &#183; 초보자를 위한 안드로메다 문법 워크북</span>
              </div>
            </div>

            {renderFinalProduct()}

          </div>
        </div>


      </div></>
    );
  };

  const renderMobile = () => {
    return (
      // <><div>
      //   <img className='event-banner' src='/images/mainPageMobile/eventBanner_m.svg' />
      // </div>
      <div id='product-main-screen'>
        <img className='event-banner' src='/images/mainPageMobile/eventBanner_m.svg' onClick={() => navigate('/eventPage/?tab=benefit1Ref')} />
        <div className='split-description'>
          <h1>안드로메다 패키지</h1>
          <div className='img-box'>
            {englishType == 'travel' ? <img src='/images/mainPageMobile/productTravel_m.png' />
              : <img src='/images/mainPageMobile/productBusiness_m.png' />}
          </div>
          <span className='option-message'>옵션에 따른 총 구매 가격을 확인해 보세요.</span><br /><br />
          <span className='choose'>원하는 교육 패키지를 선택하세요.</span>

          <div className='package-container'>
            {[{ label: '여행 영어', value: 'travel' }, { label: '비즈니스 영어', value: 'business' }].map((e) => {
              return (
                <div
                  key={e.value}
                  className='option'
                  onClick={() => setEnglishType(e.value)}
                  style={{ borderColor: englishType == e.value ? cSelected : undefined }}
                >
                  <div className='row'>
                    <span className='bold'>{e.label}</span>
                    <div STYLE='font-size: 13px'>52주 과정</div>
                  </div>
                </div>
              );
            })}
          </div>

          <hr />

          <span className='choose'>원하는 모델을 선택하세요.</span>
          <br />
          <span className='select-model'>※사양은 64GB, 스페이스 그레이, Wi-Fi 입니다.</span>
          <div className='capacity-container' style={{position: 'relative'}}>
            {[{label: 'iPad Air(5세대)',
              value: 'ipad64GB',
              image: <img src='/images/mainPage/infoCircle.svg' onClick={() => hoverController('air')}></img>,
              hover: <img style={{position: 'absolute', right: '0px', display: airHover}} src='/images/mainPage/iPadAirTooltip.svg' onClick={() => setAirHover('none')}></img>},

            {label: '선택안함',
              value: 'ipad0GB',
            }
            ].map((e) => {
              return (
                <div
                  key={e.value}
                  className='option'
                  onClick={() => setIpadType(e.value)}
                  style={{ borderColor: ipadType == e.value ? cSelected : undefined }}
                >
                  <div className='row'>
                    {/* {e.label == '(5세대)' ? <span className='bold'>iPad Air</span> :
                  <span className='bold'>iPad</span>} */}
                    <span>{e.label}</span>
                    {e.image}{e.hover}
                    {/* <span className='light-product-bold'>64GB 스페이스그레이</span> */}
                  </div>
                </div>
              );
            })}
          </div>

          <hr />

          <span className='choose'>온라인 화상 영어 수업 Kimini 를 선택하세요.</span>
          <br />
          <span className='select-model'>※수강 기간: 12개월</span>

          <div className='kimini-container'>
            {[{ label: '선택 안함', value: 0 }, { label: '월 8회', value: 8 }, { label: '월 20회', value: 20 }, { label: '월 30회', value: 30 }].map((e) => {
              return (
                <div
                  key={e.value}
                  className='option'
                  onClick={() => setKiminiType(e.value)}
                  style={{ borderColor: kiminiType == e.value ? cSelected : undefined }}
                >
                  <span className='bold'>{e.label}</span>
                </div>
              );
            })}
          </div>

          <hr />

          <div className='description-box'>
            <span className='choose'>안드로메다 패키지 기본 제공(무료)</span>
            <div style={{ padding: '10px' }}>
              <span className='bold'> &#183; 뮤지오 AI 톡 (태블릿 앱) 1년 이용권</span><br />
              <span className='bold'> &#183; 초보자를 위한 안드로메다 문법 워크북</span>
            </div>
          </div>

          {renderFinalProduct()}

        </div>
      </div>
    );
  };

  // return renderMobile();
  if (device == 'mobile')
  // if (width < 500)
    return renderMobile();
  else
    return renderWeb();
}

function ProductSubScreen() {
  const {device} = useResponsive();
  const navigate = useNavigate();

  const renderWebProduct = () => {
    return (
      <div id='product-sub-screen'>
        <div className='section'>
          <img src='images/mainPage/productMain1.png'/>
        </div>

        <div className='section'>
          <img src='images/mainPage/productMain2.svg'/>
        </div>

        <div className='section main3'>
          <img src='images/mainPage/productMain3.png'/>
          <video playsInline={true} autoPlay muted loop className="orgel-video">
            <source src="/videos/AppVideo.mp4" type="video/mp4"/>
          </video>
        </div>

        <div className='section' style={{backgroundColor: '#EEEEEE'}}>
          <img src='images/mainPage/productMain5.svg'/>
        </div>
      </div>
    );
  };
  const renderMobileProduct = () => {
    return (
      <div id='product-sub-screen'>
        <div className='section'>
          <img src='images/mainPageMobile/productMain1_m.svg'/>
        </div>

        <div className='section'>
          <img src='images/mainPageMobile/productMain2_m.svg'/>
        </div>

        <div className='section main3'>
          <img src='images/mainPageMobile/productMain3_m.svg'/>
          <video playsInline={true} autoPlay muted loop className="orgel-video">
            <source src="/videos/AppVideo.mp4" type="video/mp4"/>
          </video>
          <a href="https://apps.apple.com/kr/app/andromeda/id6443482041?l=en">
            <img src='/images/mainPageMobile/downloadApp_m.svg' className='download-app'/>
          </a>
        </div>

        {/* <div className='section'>
          <img src='images/mainPageMobile/productMain4_m.png'/>
        </div> */}

        <div className='section'>
          <img src='images/mainPageMobile/productMain5_m.svg'/>
        </div>

        <div className='section'>
          <div style={{marginTop:'80px'}}/>
          <span>신규 회원 0원 혜택!</span>
          <div
            style={{
              marginTop:'60px'
            }}>
            <a
              className='pretty-btn'
              style={{
                padding: '20px',
                paddingLeft: '50px',
                paddingRight: '50px',
                fontSize: '18px',
                zIndex: 10,
              }}
              onClick={() => navigate('/product')}
            >
                지금 바로 구매하기 〉
            </a>
          </div>
          <div style={{marginTop:'120px'}}/>
        </div>
      </div>
    );
  };
  // return renderMobile();
  if (device == 'mobile')
  // if (width < 500)
    return renderMobileProduct();
  else
    return renderWebProduct();
}