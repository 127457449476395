import React, {useState} from 'react';
import 'Styles/AuthFindEmail.css';
import {Button, TextField} from '@mui/material';
import {useNavigate} from 'react-router-dom';


export function FindEmailScreen() {
  const navigate = useNavigate();

  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [onceSubmitted, setOnceSubmitted] = useState(false);

  const validator = {
    lastName: () => {
      if (userLastName.length < 1 ) return '성을 입력해 주새요.';
      return null;
    },
    firstName: () => {
      if (userFirstName.length < 1 ) return  '이름을 입력해 주세요.';
      return null;
    },
    phone: () => {
      const checkPhone = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
      if (!checkPhone.test(userPhone)) return '올바른 전화번호를 입력해 주세요.';
      return null;
    }
  };

  const checkFormat = () => {
    if (validator.firstName() != null) {
      return false;
    }
    if (validator.lastName() != null) {
      return false;
    }

    if (validator.phone() != null) {
      return false;
    }
    return true;
  };

  return(
    <div id="auth-find-email">
      <div className="auth-options">
        <h1>이메일 찾기</h1>

        <div className="auth-find">
          <span>가입한 이메일을 잊으셨나요?<br/>
                회원가입 시 등록한 정보를 입력해주세요.
          </span>
        </div>
        <div className='auth-option-namefield'>
          <div className='auth-option-field'>
            <span>성</span>
            <TextField
              className="input-text"
              required
              value={userLastName}
              onChange={(e) => setUserLastName(e.target.value)}
              hiddenLabel
              fullWidth
              error={onceSubmitted && validator.name() != null}
              helpderText={onceSubmitted ? validator.name() : undefined}
            />
          </div>
          <div className="auth-option-field">
            <span>이름</span>
            <TextField
              className="input-text"
              required
              value={userFirstName}
              onChange={(e) => setUserFirstName(e.target.value)}
              hiddenLabel
              fullWidth
              error={onceSubmitted && validator.name() != null}
              helperText={onceSubmitted ? validator.name() : undefined}
            />
          </div>
        </div>
        <div className="auth-option-field">
          <span>휴대전화</span>
        </div>
        <TextField
          required
          value={userPhone}
          onChange={(e) => setUserPhone(e.target.value)}
          hiddenLabel
          fullWidth
          placeholder="‘-’ 없이 숫자만 입력해 주세요."
          error={onceSubmitted && validator.phone() != null}
          helperText={onceSubmitted ? validator.phone() : undefined}
        />

        <div>
          <Button
            variant="contained"
            className="auth-button"
            onClick = {() => {
              setOnceSubmitted(true);
              if (checkFormat() == false) {
                return ;
              }
            }}
          >확인</Button>
        </div>
        <div style={{marginTop:'80px'}}/>
      </div>
    </div>

  );
}