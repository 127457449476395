import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import {Button} from '@mui/material';
import {stibeeLogState} from 'GlobalState';
import {useRecoilState} from 'recoil';
import {listStibee, convertTime} from 'Utils/Default';


export default function StibeeLogScreen() {
  const [stibeeLog, setStibeeLog] = useRecoilState(stibeeLogState);

  const onRefresh = async () => {
    try {
      setStibeeLog([]);
      const data = await listStibee();
      if (data) {
        setStibeeLog(data);
      }
    } catch (e) {
      console.warn(e);
    }
  };


  return (
    <div>
      <Button variant='contained' onClick={onRefresh}>
        스티비 로그 다시 불러오기
      </Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>email</TableCell>
              <TableCell>mail_filter</TableCell>
              <TableCell>send_date</TableCell>
              <TableCell>rsp_data</TableCell>
              <TableCell>is_success</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stibeeLog.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.mail_filter}</TableCell>
                <TableCell>{row.send_date != null ? convertTime(row.send_date) : row.send_date}</TableCell>
                <TableCell>{row.rsp_data}</TableCell>
                <TableCell>{row.is_success ? '발송성공' : '발송실패'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}