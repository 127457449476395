import {atom} from 'recoil';

export const orderListState = atom({
  key: 'orderList',
  default: [],
});

export const customerListState = atom({
  key: 'customerList',
  default: [],
});

export const downloadListState = atom({
  key: 'downloadList',
  default: [],
});

export const stibeeLogState = atom({
  key: 'stibeeList',
  default: [],
});