import React from 'react';
import 'Styles/TossResult.css';

// Fail url 은 유저 잘못(잘못된 카드 정보)

export function TossFailScreen() {
  return (
    <div id='toss-fail-screen'>
      <h1>결제에 실패했습니다.</h1>
    </div>
  );
}