import React from 'react';
import 'Styles/Business.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import {BusinessSlide, TextbookSlide, BusinessMobile} from 'Components/SlideImage';
import {useResponsive} from 'Hooks';

const businessUx = [
  '/images/mainPage/businessUX1.svg',
  '/images/mainPage/businessUX2.svg',
  '/images/mainPage/businessUX3.svg',
  '/images/mainPage/businessUX4.svg',
];

const textbook = [
  '/images/mainPage/businessTextbook1.svg',
  '/images/mainPage/businessTextbook2.svg',
  '/images/mainPage/businessTextbook3.svg',
  '/images/mainPage/businessTextbook4.svg',
];
const textbookM = [
  '/images/mainPageMobile/businessTextbook1_m.svg',
  '/images/mainPageMobile/businessTextbook2_m.svg',
  '/images/mainPageMobile/businessTextbook3_m.svg',
  '/images/mainPageMobile/businessTextbook4_m.svg',
];

export function BusinessScreen() {
  const {device} = useResponsive();

  const renderWeb = () => {
    return (
      <div id='business-screen'>
        <div className='section main1'>
          {/* 무엇을 넣을지 생각해 보자 */}
        </div>
        <div className='section main2'>
          {/* 무엇을 넣을지 생각해 보자 */}
        </div>
        <div className='section main3'>
          <img src='/images/mainPage/businessMain3.svg'/>
        </div>
        <div className='section main4'>
          <span>이미 많은 분들이 <br/> <span>비즈니스 영어와 함께 하고 있습니다.</span></span>
          {/* <BusinessSlide name={businessUx}/> */}
          <div style={{marginTop:'80px'}}/>
          <img src='/images/mainPage/businessUX.svg'/>
        </div>
        <div className='section main5'>
          <img src='/images/mainPage/businessMain5-1.svg'/>
          <img src='/images/mainPage/businessMain5-2.svg'/>
        </div>
        <div className='section main6'>
          <TextbookSlide name={textbook} autoplay={false}/>
        </div>
        <div className='section main7'>
          <img src='/images/mainPage/businessMain7.svg'/>
          <div style={{marginTop:'-250px'}}/>
          <a href="https://apps.apple.com/kr/app/andromeda/id6443482041?l=en">
            <img src='/images/mainPage/downloadApp3.svg' className='download-app'/>
          </a>
        </div>
        <div className='section main8'>
          <img src='/images/mainPage/businessMain8.svg'/>
        </div>
        <div className='section main9'>
          <img src='/images/mainPage/businessMain9.svg'/>
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div id='business-screen'>
        <div className='section main1'>
          <img src='/images/mainPageMobile/businessMain1_m.svg'/>
        </div>
        <div className='section main2'>
          <img src='/images/mainPageMobile/businessMain2_m.svg'/>
          <div style={{marginTop:'80px'}}/>
          <span className='slider-header'>이미 많은 분들이 <br/> <span>비즈니스 영어와 함께 하고 있습니다.</span></span>
          <BusinessMobile name={businessUx} autoplay={false}/>
          <div style={{paddingTop:'80px'}}/>
        </div>
        <div className='section'>
          <img src='/images/mainPageMobile/businessMain3_m.svg'/>
          <div style={{paddingTop:'100px'}}/>
        </div>
        <div className='section main3'>
          <TextbookSlide name={textbookM} autoplay={false}/>
        </div>
        <div className='section main4'>
          <img src='/images/mainPageMobile/businessMain5_m.svg'/>
          <div style={{marginTop:'48px'}}/>
          <a href="https://apps.apple.com/kr/app/andromeda/id6443482041?l=en">
            <img src='/images/mainPageMobile/downloadApp3_m.svg' className='download-app'/>
          </a>
        </div>
        <div className='section'>
          <img src='/images/mainPageMobile/businessMain6_m.svg'/>
        </div>
      </div>
    );
  };

  if (device == 'mobile')
  // if (width < 500)
    return renderMobile();
  else
    return  renderWeb();

}