export const faqs =  [
  {
    'title': '뮤지오 잉글리쉬 서비스에 대해',
    'faqs': [
      {
        'question': '무슨 서비스인가요?',
        'answer': '뮤지오 잉글리쉬는 타사의 단순 암기식, 인강 주입식 학습과 달리 \n비즈니스, 여행 상황의 실전 스토리 기반의 학습지와 자율대화 AI 앱, 이미지 단어장으로 구성되어 재미있게 영어 회화를 배울 수 있는 서비스입니다. '
      },
      {
        'question': 'App 다운로드 방법',
        'answer': 'https://sites.google.com/akaai.kr/musioenglish-download/home \n*유의 : Andromeda 앱의 경우, iPad 에서만 사용가능한 점 참고 부탁드립니다.'
      },
      {
        'question': '단어장은 어떻게 이용하나요?',
        'answer': '단어장은 토익 필수 단어만 뽑아 총 6권의 책으로 구성되어 있습니다. 간편하게 가지고 다닐 수 있는 사이즈로 제작하여 직장인들의 바쁜 일상 속에서도 틈틈이 학습을 할 수 있으며, 이미지 연상법이라는 효과적인 단어 암기법을 차용하여 어휘력 향상에 도움이 됩니다.'
      },
    ]
  },
  // {
  //   'title': '메타버스 뮤지오에 대해',
  //   'faqs': [
  //     {
  //       'question': '뮤지오를 사용하려면 어떤 환경이 필요한가요?',
  //       'answer': '앱 다운로드 > 영상 링크'
  //     },
  //   ]
  // },
  {
    'title': '화상 영어 수업에 대해',
    'faqs': [
      {
        'question': '무슨 서비스인가요?',
        'answer': 'Kimini 온라인 영어 회화(키미니)는, Gakken 그룹의 주식회사 Glats(글라츠)가 운영하는 온라인 영어 회화 서비스입니다.\n“입에 붙는 영어 회화”, “최고의 기술을 통해 최고의 학습을”을 모토로 하여 쾌적하고 효율적인 영어 학습 환경을 제공하는 것을 목표로 하고 있습니다.'
      },
      {
        'question': '화상영어 시작일이 언제인가요?',
        'answer': '주문을 완료한 날로부터 1년간 사용할 수 있습니다.'
      },
      {
        'question': '다른 화상영어 수업과 무슨 차이점이 있나요?',
        'answer': '1. 별도의 설치 과정 없이 작동이 쉬운 화상 영어\n온라인 수업에 최적화된 교육 시스템을 자체적으로 준비하였습니다.\n또한 Kimini 온라인 영어 회화 시스템은 Skype와 같은 소프트웨어 설치 과정 없이, 브라우저만 실행하면 끝납니다.\n\n2. 독자적인 커리큘럼 = 교육 코스\n온라인 영어 회화는 ‘영어로 잡담하는’ 수준에 그치기 때문에 영어 실력이 늘지 않는다’고 비판, 고민을 품고 있는 분도 많이 계십니다.\n공립 학교와 국제 학교 기준의 커리큘럼을 제공합니다.\n각 코스는 Gakken의 교재 개발 노하우를 살려 온라인 수업에 특화된 내용으로 자체 제작했습니다.\n다양한 코스 중에서 자신의 목표에 맞는 코스를 선택하실 수 있습니다.\n\n3. 직접적인 커리큘럼 관리 시스템\n또한 코스별 진척 상황은 시스템이 자동으로 관리합니다.\n다음에 배울 단원은 무엇인지, 예습이나 복습은 어떻게 하면 좋은지 등, 그때마다 적절한 타이밍에 안내가 제공되므로 ‘오늘은 어떤 내용을 공부할까?’라며 매번 고민하지 않아도 됩니다.'
      },
      {
        'question': '어떤 내용으로 배우는 건가요?',
        'answer': '여행 영어 코스의 경우, 전문 에디터들이 직접 선정한 관광지 및 핫플 정보를 기반으로, 쉽게 배울 수 있는 phrase를 학습하고 실제 발화할 수 있도록 합니다. 초급부터 중급까지의 레벨로, 초급자분들도 쉽게 시작할 수 있습니다.\n\n비즈니스 영어 코스의 경우, 기업이 미국 진출 시 겪었던 상황, 필요한 정보를 바탕으로 실용적인 커뮤니케이션 방법을 학습할 수 있도록 합니다. \n\n이외 엔터테인먼트, 경제, 스포츠 등 다양한 분야의 News를 보며 대화할 수 있는 News Talk, 선생님과 자율 대화를 나눌 수 있는 Free Talk 코스도 있으므로, 자유롭게 선택하실 수 있습니다.'
      },
      {
        'question': '수업은 몇 분 동안 진행되나요?',
        'answer': '1회 25분 수업입니다.'
      },
      {
        'question': '코스 하나당 분량은 어느 정도인가요?',
        'answer': '각 코스별 상이하나, 비즈니스, 여행 영어의 경우 52주차로 1년치의 커리큘럼으로 제공됩니다.\n일주일 2회 수업시 약 6개월 정도가 걸립니다. (개인 진도별 상이)'
      },
      {
        'question': '코스를 도중부터 시작하거나 특정 수업만 수강할 수 있나요?',
        'answer': '학습 효율을 최대한으로 높이기 위해 코스는 처음부터 순서대로 수강하게끔 되어 있습니다.\n다만, 수업을 시작할 때 선생님에게 말해주시면 변경해드릴 수 있습니다.'
      },
      {
        'question': '수업의 난이도는 어떻게 되나요?',
        'answer': '여행 영어의 경우, 초~중급의 난이도이며,\n비즈니스 영어의 경우, 중~상급의 난이도입니다.'
      },
      {
        'question': '매번 같은 강사에 수업을 받는 편이 나을까요?',
        'answer': '다양한 강사와 얘기하는 편이 더욱 실용적이고 효과적입니다.\n선생님들끼리 학생의 학습 이력을 공유하기 때문에 선생님이 바뀌어도 지난번에 이어서 원활한 수업이 가능합니다.'
      },
      {
        'question': '1회 수업을 다 못 끝내면 어떻게 되나요?',
        'answer': '다음 수업에서 해당 수업을 이어서 재개합니다.\n단, News Talk 코스의 경우, 수업을 이어서 하기가 어렵기 때문에 다음 단원으로 넘어갑니다.\n수업이 도중에 끝난 경우, 해당 수업의 진척은 ‘진행중’으로 표시됩니다.'
      },
      {
        'question': '진척에 표시된 \'진행중\'은 무슨 의미인가요?',
        'answer': '1회 수업에서 단원 하나를 전부 끝내지 못한 경우, 해당 단원의 상태 표시가 ‘진행중’이 됩니다. (선생님의 판단기준)\n다음 수업에서는 해당 수업을 이어서 재개합니다.'
      },
      {
        'question': '지난번 받은 수업 상황을 강사가 파악하고 있나요?',
        'answer': '네, 수업을 시작하기 전에 각 강사는 학생의 과거 학습 이력을 확인한 후에 수업에 임합니다.\n수업 초반에 ‘어디부터 시작할지’를 매번 강사에게 전달하지 않아도 되므로 안심하셔도 됩니다.'
      },
      {
        'question': '수업 중 채팅 이력이 남나요?',
        'answer': '남습니다. 수업의 상세 페이지에 표시되므로 나중에 다시 보실 수 있습니다.'
      },
      {
        'question': '채팅 내역이 번역이 되나요?',
        'answer': '따로 번역은 지원되지 않습니다.'
      },
      {
        'question': '수업을 녹화하는 기능이 있나요?',
        'answer': '없습니다. 스스로 녹화하셔도 상관없습니다.'
      },
      {
        'question': '직접 준비한 교재로 수업을 받을 수 있나요?',
        'answer': '안타깝지만 개인 소유 교재로는 수업을 제공해드릴 수 없으므로 양해 바랍니다.'
      },
      {
        'question': '강사는 한국어를 할 줄 아나요?',
        'answer': '선생님 중에는 한국어가 어느정도 가능하신 분도 있지만, 영어로 소통해야 회화 실력이 성장하기 때문에 사용을 금지하고 있습니다.'
      }
    ]
  },
  {
    'title': '화상 영어 수업 수강 환경에 대해',
    'faqs': [
      {
        'question': '스마트폰이나 태블릿에서도 수강할 수 있나요?',
        'answer': 'iPhone, iPad, Android 에서 브라우저로 수업을 수강하셔야 하며, 기종에 따라서 수업 수강이 어려울 수도 있습니다.'
      },
      {
        'question': '카메라가 없이도 수업을 받을 수 있나요?',
        'answer': '수강은 가능하지만 효과적인 학습을 위해서 사용하시는 것을 권장합니다.'
      },
      {
        'question': '헤드셋이 없어도 수업을 받을 수 있나요?',
        'answer': '컴퓨터나 스마트폰에 내장된 마이크와 스피커로 수강하실 수 있습니다. 하지만 보다 안정된 통화를 위해서 헤드셋을 이용하시는 것을 추천 드립니다.\n또한 iPhone 등의 스마트폰 구매 시 받는 대부분의 이어폰에는 마이크도 함께 내장되어 있으므로 문제 없이 이용하실 수 있습니다.'
      },
      {
        'question': '포켓 WiFi나 스마트폰의 데이터 사용으로도 수업을 받을 수 있나요?',
        'answer': '네, 가능합니다.\n다만, 유선랜에 비해 안정성이 떨어지기 때문에 무리 없이 수업을 수강할 수 있다고 보장해드릴 수는 없습니다.\n또한 화상 통화로 진행되기 때문에 그만큼 데이터도 소비된다는 점, 양해 부탁드립니다.'
      },
      {
        'question': '통신이 불안정할 경우 어떻게 하면 되나요?',
        'answer': '1. PC에서 Chrome 또는 Firefox로 접속하지 않은 경우\nPC로 수강하시는 경우, Chrome 또는 Firefox를 이용하셔야 합니다. 설치를 진행하신 다음 브라우저를 열어 본 사이트에 접속하셔야 합니다.\n\n2. 카메라나 마이크를 허용하지 않은 경우\n카메라나 마이크 사용을 허용하지 않으면 통신이 불가능합니다.\n‘이용 환경 확인’ 페이지에서 확인해주세요.\n\n3. 스피커가 음소거로 되어 있는 경우\n강사의 목소리가 들리지 않는 경우, 스피커나 이어폰의 볼륨이 0 (음소거)인 경우가 종종 있습니다.\n\n4. 수업 화면을 중복으로 띄워놓은 경우\n마이크가 음소거로 되어 있는 경우\n이용자의 목소리가 강사에게 들리지 않는 경우, 마이크 볼륨이 0 (음소거)인 경우가 종종 있습니다.\n수업 화면을 동시에 여러 개 띄우지 말아주세요. 화면을 띄울 때마다 통신 용량과 처리량이 2, 3배 이상이 되기 때문에 통신이 불안정해집니다.\n\n5. ‘갱신’을 하지 않은 경우\n통신이 불안정할 때 수업 화면에 표시된 ‘갱신’ 버튼을 눌러 새로고침을 해보세요.\n대부분의 경우 새로고침을 하면 통신이 개선됩니다.'
      }
    ]
  },
  {
    'title': '화상 영어 수업 예약 및 취소에 대해',
    'faqs': [
      {
        'question': '수업 예약은 어떻게 하면 되나요?',
        'answer': '수업 예약은 로그인 후 진행해주세요.\n스마트폰, PC, 어느 쪽에서도 예약하실 수 있습니다.\n‘현재 진행중’ 에서 다음으로 배울 단원에 표시된 ‘수업 예약하기’ 버튼을 눌러 수업을 예약해주세요.\n또한, 예약한 수업 시간이 지나면 수업이 끝난 것으로 간주되며, 다음 수업을 예약하실 수 있게 됩니다.'
      },
      {
        'question': '수업은 언제까지 예약/취소할 수 있나요?',
        'answer': '1. 예약\n수업 개시 시각 15분 전까지 예약하실 수 있습니다.\n\n2. 취소\n수업은 수업 시각 30분 전까지만 취소하실 수 있습니다.\n예시) 5시 수업 시작 시간 > 수업 취소 가능 시간 4시 29분까지\n또한, 하루에 취소할 수 있는 횟수는 5번까지입니다. 하루 동안 예약을 5번 취소하면 날짜가 바뀔 때까지 예약하실 수 없습니다.'
      },
      {
        'question': '수업을 예약했는데 개인사정으로 수강할 수 없었습니다. 시간을 바꿀 수 있나요?',
        'answer': '예약한 수업을 수강하지 못하게 되면 수업을 수강한 경우와 마찬가지로 수강 가능 횟수가 1회 차감됩니다.\n대체 수업은 불가능하므로 수강이 어려울 경우 미리 취소해주시기 바랍니다.\n수업 취소는 수업 시각 30분 전까지만 가능합니다.'
      },
      {
        'question': '강사의 스케줄은 어디까지 공개되나요?',
        'answer': '당일부터 1주일(7일) 뒤까지 스케줄이 공개됩니다.'
      },
      {
        'question': '수업받을 수 있는 시간대와 날짜는 언제인가요?',
        'answer': '매일 오전 6시 ~ 오전 0시입니다.\n※1년에 몇 번씩 있는 휴일에는 수업을 받으실 수 없으므로 양해 부탁드립니다.\n'
      },
      {
        'question': '수업 쿠폰은 뭔가요?',
        'answer': '강사의 사정으로 수업이 취소되거나 강사 측의 실수로 수업이 성립되지 않은 경우 등에 부여되는 수업 예약용 티켓입니다. \n*일반 예약 제한에 상관없이 예약하실 수 있습니다.\n\n단, 다음과 같은 경우에는 티켓을 부여받지 못합니다.\n\n선생님 측 회선에 문제가 있음을 당사에서 확인하지 못한 경우\n학생 측의 지각, 결석, 또는 취소를 깜박한 경우 등, 강사 측에 책임이 없는 경우\n수업이 예약되어 있지 않았거나 예약했어도 수강하지 않은 경우'
      },
      {
        'question': '수업은 하루에 몇 회 할 수 있나요?',
        'answer': '월 8회, 월 20회, 월 30회 코스는 하루에 최대 1개의 수업의 예약 및 수강이 가능합니다.\n\n월 60회 코스는 하루에 최대 2개의 수업의 예약 및 수강이 가능합니다.'
      }
    ]
  },
  {
    'title': '화상 영어 수업에 문제가 발생한 경우에 대해',
    'faqs': [
      {
        'question': '시간이 되어도 \'수업 시작\' 버튼이 나타나지 않습니다.',
        'answer': '수업 개시 시간 15분 전에 페이지 상단에 ‘수업 개시’ 버튼이 나타나며, 수업 개시 5분 전에 그 버튼을 누를 수 있게 됩니다.\n만일 시간이 되어도 버튼이 나타나지 않을 경우, 페이지를 새로고침 해주세요.'
      },
      {
        'question': '수업 시간이 지나도 선생님이 나타나지 않습니다.',
        'answer': '통신이 불안정한 걸 수도 있습니다. 수업 화면의 ‘갱신’ 버튼을 눌러 페이지를 갱신해보세요.'
      },
      {
        'question': '선생님에게 제 영상이나 음성이 전달되지 않습니다.',
        'answer': '우선 다음 네 가지 항목을 확인해주세요.\n1. 카메라, 헤드셋(마이크가 달린 이어폰)이 연결되어 있는지, 컴퓨터가 제대로 인식하는지 확인해주세요.\n2. 마이크 음량이 너무 작지는 않은지, 음소거인지 확인해주세요.\n3. 스피커 음량이 너무 작지는 않은지, 음소거인지 확인해주세요.\n4. 화면에 표시된 ‘갱신’ 버튼을 눌러 수업 화면을 갱신해주세요.\n\n그래도 해결되지 않으면 고객센터(070-4124-1851)로 연락 바랍니다. \n주말/공휴일인 경우, 카카오톡 채널에 내용을 남겨주시면, 영업일에 회신드리겠습니다.'
      },
      {
        'question': '통신 환경 트러블로 수강하지 못한 경우, 어떻게 하면 되나요?',
        'answer': '고객센터(070-4124-1851)로 연락 바랍니다. \n주말/공휴일인 경우, 카카오톡 채널에 내용을 남겨주시면, 영업일에 회신드리겠습니다.'
      },
      {
        'question': '수업을 무단결석하면 패널티가 있나요?',
        'answer': '없습니다. 다만, 수업을 끝낸 것으로 간주하므로 1회 수업이 소모 됩니다.'
      },
      {
        'question': '수업에 지각하면 어떻게 되나요?',
        'answer': '수업 시작 시간부터 14분 59초 까지 입실하신 경우, 수업을 수강하실 수 있습니다.\n다만 수업은 예정대로 종료되니 양해 부탁드립니다.\n\n수업 시작 시간부터 15분이 경과한 후에 학생의 입실 확인이 안 되는 경우, 선생님은 수업 방을 퇴실하며, 수업을 끝낸 것으로 간주하여 1회 수업이 소모 됩니다.'
      }
    ]
  },
  {
    'title': '사이트 이용에 대해',
    'faqs': [
      {
        'question': '회원 가입은 필수인가요?',
        'answer': '회원제 서비스이므로, 신청을 위해서는 회원 가입이 필수입니다.'
      },
      {
        'question': '비밀번호가 기억나지 않습니다.',
        'answer': '비밀번호를 다시 설정해주세요.\n로그인 페이지에 접속하여 ‘비밀번호를 잊어버리신 경우, 여기서 다시 설정하실 수 있습니다’를 클릭하여 안내에 따라 절차를 진행해주세요.'
      },
      {
        'question': '등록한 메일 주소를 잃어버렸습니다.',
        'answer': '고객센터(070-4124-1851)로 연락 바랍니다. \n연락 시 본인 확인을 위해 등록하신 성함과 주소, 전화번호를 알려주세요.'
      },
      {
        'question': '로그인이 안 되면 어떻게 해야 하나요?',
        'answer': '다음을 확인해주세요.\n\n- 메일 주소가 잘못된 경우\n대문자 소문자를 구분했는지, @ 뒤에 오는 도메인명에 오타가 없는지 확인해주세요. 혹은 다른 메일 주소로 시도해주세요. 등록한 메일 주소가 기억 안 나는 경우, 문의처를 통해 등록하신 성함과 주소, 전화번호를 기입하여 문의해주세요.\n\n- 비밀번호가 틀렸을 경우\n대문자 소문자를 잘 구분했는지 확인해주세요. 비밀번호를 잊어버리신 경우, 문의처에 연락하여 아이디를 알려주시면 비밀번호 재설정을 도와드리겠습니다.\n\n- 가입이 완료되지 않을 경우\n‘회원가입이 완료되었습니다’라는 제목의 메일이 오지 않으면 아직 가입이 완료되지 않은 상태입니다. 이럴 경우, 다시 회원가입을 진행해주시기 바랍니다.'
      },
      // {
      //   'question': '등록된 신용카드를 변경 또는 삭제할 수 있나요?',
      //   'answer': '마이 페이지의 ‘결제 카드’에서 변경하실 수 있습니다.'
      // },
      {
        'question': '탈퇴하려면 어떻게 해야 하나요?',
        'answer': '고객센터(070-4124-1851)로 개별 연락 부탁드립니다.'
      }
    ]
  },
  {
    'title': '서비스 가입 및 지불 방법에 대해',
    'faqs': [
      {
        'question': '지불 방법에는 어떤 것들이 있나요?',
        'answer': '신용카드 결제로 지불이 가능하고, 취소수수료는 계좌이체를 통해 입금하셔야 정상 해지가 완료됩니다.'
      },
      {
        'question': '영수증을 받을 수 있나요?',
        'answer': '카드 회사의 사용 명세서 또는 주문 확인 메일을 이용해주세요.\n이외 증빙을 위한 영수증 필요시, 고객센터(070-4124-1851)로 개별 연락 부탁드립니다.'
      },
      {
        'question': '교육 증명서를 발급 받을 수 있나요?',
        'answer': '회사에 제출을 위한 증명서 발급이 가능합니다.\n고객센터(070-4124-1851)로 개별 연락 부탁드립니다.'
      }
      // {
      //   'question': '신용카드 유효기간 만료, 한도액 초과 등으로 요금을 지불하지 못한 경우에는 어떻게 하면 되나요?',
      //   'answer': '유효기간 만료인 경우, 마이 페이지에서 신용카드 정보 갱신을, 한도 초과인 경우, 조속히 해결해주시기 바랍니다. 그 다음달에도 지불이 안 된 경우, 지속적인 이용이 불가능하다고 판단하여 당사 제휴 회사가 대여 제품을 회수하러 방문할 수 있으니 주의해주세요.'
      // }
    ]
  },
  {
    'title': '배송에 대해',
    'faqs': [
      {
        'question': '신청하고 나서 얼마나 걸리나요?',
        'answer': '상품 종류와 거주 지역에 따라 다르나, 평균적으로 신청 후 영업일 1주일 내에 도착합니다. 사전에 확인하시려면 문의 바랍니다.\n아이패드 발송의 경우 별도로 진행되는 점 참고 부탁드립니다.'
      },
      {
        'question': '배송 지역에 제한이 있나요?',
        'answer': '전국 배송 가능합니다.'
      },
      {
        'question': '배송 일시/업체를 지정 혹은 변경할 수 있나요?',
        'answer': '배송 일시 및 업체는 지정/변경하실 수 없습니다.'
      }
    ]
  }
];