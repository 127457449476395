import React from 'react';
import 'Styles/Loading.css';
import spinner from '../Assets/gif/Spinner.gif';

export default function Loading() {
  console.log('call loading');
  return (
    <div className='loading-background'>
      <div className='loading-text'>로그인 중 입니다.</div>
      <img src={spinner} alt='로딩중' width='5%'/>
    </div>
  );
}