import React, {useEffect, useState} from 'react';
import 'Styles/AuthLogin.css';
import {Button, TextField} from '@mui/material';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {login} from 'Utils/Auth.js';
import {myState, authState} from 'GlobalState';
import { useSnackbar } from 'Hooks';
import {useRecoilValue} from 'recoil';


export function LoginScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [onceSubmitted, setOnceSubmitted] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const showSnackbar = useSnackbar();

  const me = useRecoilValue(myState);
  const auth = useRecoilValue(authState);

  useEffect(() => {
    if (auth) {
      const redirect = searchParams.get('redirect');
      if (redirect) {
        navigate('/confirmProduct');
      } else {
        navigate('/mypage/userInfo');
      }
    }
  }, [me, auth]);

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    setUserEmail(savedEmail);
  }, []);

  const validator = {
    email: () => {
      const checkEmail = /^[0-9a-zA-Z]([-_+\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      if (userEmail.length < 1) return '이메일을 입력해 주세요.';
      if (!checkEmail.test(userEmail)) return '이메일을 입력해주세요.';
      return null;
    },
    password: () => {
      // const checkPassword = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/;
      const checkPassword = /^(?=.*[a-zA-z])(?=.*[0-9]).{6,16}$/;
      if (!checkPassword.test(userPassword)) return '6~16자 영문 대 소문자, 숫자를 사용하세요.';
      return null;
    },
  };

  const checkFormat = () => {
    if (validator.email() != null){
      return false;
    }
    if (validator.password() != null) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    setOnceSubmitted(true);
    if (checkFormat() == false) {
      return ;
    }
    try {
      setIsProcessing(true);
      await login(userEmail, userPassword);
      localStorage.setItem('email', userEmail);
      showSnackbar('로그인하였습니다.');

    } catch (e) {
      console.warn(e);
      let errMessage = '로그인에 실패하였습니다.';
      if (e.code == 'auth/invalid-email') { // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
        errMessage = '올바르지 않은 이메일 형식입니다.';
      } else if (e.code == 'auth/user-not-found') {
        errMessage = '등록되지 않은 유저입니다.';
      } else if (e.code == 'auth/wrong-password') {
        errMessage = '비밀번호가 틀렸습니다.';
      }

      showSnackbar(errMessage, 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div id="auth-login">
      <div className="auth-options">
        {/* <img
          src='/images/navbar/logoBlack.png'
          className="auth-aka-logo"
          onClick={() => {
            navigate('/');
          }}
        /> */}
        <h1>로그인</h1>
        <div className="auth-option-field">
          <span>이메일</span>
        </div>
        <TextField
          required
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          hiddenLabel
          fullWidth
          placeholder ="이메일을 입력해 주세요."
          error={onceSubmitted && validator.email() != null ? true : false}
          helperText={onceSubmitted ? validator.email() : undefined}
        />

        <div className="auth-option-field">
          <span>비밀번호</span>
        </div>
        <TextField
          required
          type='password'
          value={userPassword}
          onChange={(e) => setUserPassword(e.target.value)}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              onSubmit();
            }
          }}

          hiddenLabel
          fullWidth
          placeholder="비밀번호를 입력해 주세요."
          error={onceSubmitted && validator.password() != null ?  true: false}
          helperText={onceSubmitted ? validator.password() : undefined}
        />

        <div style={{marginTop:'40px'}}/>
        <Button
          variant="contained"
          className="auth-button"
          onClick = {() => onSubmit()}
          disabled={isProcessing}
        >
            로그인
        </Button>
        <div className="sub-button">
          <Button
            className="join-and-find"
            onClick={() => {
              navigate('/auth/findpassword');
            }}>
                비밀번호 찾기
          </Button>
          <div style={{marginLeft:'40px'}}/>
          <Button
            className="join-and-find"
            onClick={() => {
              navigate('/auth/findemail');
            }}>
                이메일 찾기
          </Button>
          {/* <Button
              className="join-and-find"
              onClick={() => navigate('/auth/signup')}
            >
                회원가입
            </Button> */}
        </div>
        <div style={{marginBottom:'60px'}}/>
        <span className="sign-up-text">아직 뮤지오 잉글리시 아이디가 없으신가요?</span>
        <Button
          variant="contained"
          className="auth-button sign-up"
          color="white"
          onClick={() => navigate('/auth/signup')}
        >
          회원가입
        </Button>
      </div>
    </div>
  );
}



