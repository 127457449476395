import {server, qr} from './Axios';
import { getAuthHeader } from './Auth';
// import { ProductConfirmScreen } from 'Screens/MainPage';
// import axios from 'axios';



export async function getCustomer(id) {
  const rsp = await server.get('/customer', {params: {id: id}, headers: getAuthHeader()});
  return rsp.data;
}

export async function createCustomer(customerData) {
  const rsp = await server.post('/customer', customerData, {headers: getAuthHeader()});
  return rsp.data;
}

export async function updateCustomer(updateData) {
  const body = {
    updateData: updateData
  };
  const rsp = await server.put('/customer', body, {headers: getAuthHeader()});
  return rsp.data;
}
export async function updateCustomerKimini(uid, updateData) {
  const body = {
    uid: uid,
    updateData: updateData
  };
  const rsp = await server.put('/admin', body, {headers: getAuthHeader()});
  return rsp.data;
}

export async function showQRImage() {
  const headers = getAuthHeader();
  // const rsp = await axios.get('https://eb8751kgxb.execute-api.ap-northeast-1.amazonaws.com/api/qr', {headers: headers});
  const rsp = await qr.get('/qr', {headers: headers});

  return rsp.data;
}