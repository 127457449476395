import React from 'react';

export default function ServiceTerms() {
  return (

    <div style={{margin:'50px'}}>
      <h1 style={{fontSize:'21px'}}><span size="6"><strong>Musio English Andromeda&nbsp;</strong></span><span size="6"><strong>이용 약관</strong></span></h1>
      <p>주식회사 아카에이아이(이하 &quot;<strong>회사</strong>&quot;, &ldquo;<strong>당사</strong>&quot;)는 (1)당사의 인공지능 엔진을 활용한 영어 학습 앱 Musio English(이하 &quot;<strong>앱</strong>&quot;), (2)Travel&nbsp;교재, Business&nbsp;교재,&nbsp;단어장,&nbsp;학습지(이하 &ldquo;<strong>교재</strong>&rdquo;), (3)일본 Kimini&nbsp;사가 제공하는 화상 영어 서비스 (이하 &ldquo;<strong>화상 수업</strong>&quot;)로 구성된 성인 영어 학습 서비스 Musio English(이하 &quot;<strong>서비스</strong>&quot;)를 신청한 회원(이하 &ldquo;<strong>회원</strong>&quot;)에게 다음의 조건에 따라 본 서비스를 제공합니다.</p>
      <h3><strong>제&nbsp;</strong><strong>1&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>약관의 적용</strong><strong>)</strong></h3>
      <p>1. Musio English&nbsp;서비스 이용 약관 (이하 &quot;<strong>약관</strong>&quot;)은 본 서비스를 이용하는 모든 회원에게 적용되는 것으로,&nbsp;본 서비스를 이용하는 회원은 본 약관을 준수해야 합니다.</p>
      <p>2.&nbsp;당사가 본 서비스의 내용 등을 기재한 사이트 (https://musioenglish.kr) (이하 &quot;<strong>웹 사이트</strong>&quot;)에 게재하는 본 서비스의 신청 및 이용에 관한 조건은 본 약관의 일부를 구성하는 것으로 합니다.&nbsp;본 약관의 내용과 당사 웹 사이트의 조건이 상충하는 경우,&nbsp;당사 웹 사이트의 조건 규정이 우선하여 적용됩니다.</p>
      <p>3.&nbsp;본 서비스는 여행 영어, 비즈니스 영어&nbsp;두 가지 패키지(이하 &ldquo;<strong>패키지</strong>&quot;)로 구성되어 있으며,&nbsp;패키지 간 콘텐츠 차이에 따른 금액 차이는 없습니다.</p>
      <p>4.&nbsp;본 계약의 약정기간은 패키지 신청기간과 동일하고 약관의 적용 기간은 결제 후부터 패키지 기간이 만료되어 서비스 해지가 완료된 날까지입니다.</p>
      <h3><strong>제&nbsp;</strong><strong>2&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>약관의 내용</strong><strong>)</strong></h3>
      <p>1.&nbsp;본 약관은 회사의 서비스 이용 시 당사와 회원 간에 체결하는 계약입니다.</p>
      <p>2.&nbsp;본 서비스는 다음과 같은 앱에 탑재된 모든 콘텐츠(이하 &ldquo;<strong>콘텐츠</strong>&quot;)를 사용할 수 있는 플랫폼을 제공합니다.&nbsp;본 콘텐츠의 자세한 내용은 당사 웹 사이트에 기재되어 있습니다.</p>
      <p>(1) Edu Mode, Learner&rsquo;s Chat, Free Chat&nbsp;및 그 외 영어 학습&middot;활동 콘텐츠</p>
      <p>(2)&nbsp;콘텐츠 추가 및 편집 기능</p>
      <p>(3)&nbsp;이용 학생의 등록 &middot;&nbsp;편집 기능</p>
      <p>3.&nbsp;본 서비스 이용 시 회원은 당사가 별도로 정하는 바에 따라 iPad를 구입해야 합니다. iPad의 사양이나 설정 방법 등은 제조사 Apple의 웹 사이트(https://www.apple.com/kr/ipad/)에 따라야 합니다.</p>
      <p>4.&nbsp;본 서비스를 이용 시 통신 환경이 필수이며,&nbsp;회원이 직접 통신 환경을 준비해야 합니다.&nbsp;회원이 이용하는 통신 환경의 전파가 약하거나 통신 속도가 느린 경우,&nbsp;본 서비스를 만족스럽게 사용할 수 없을 가능성이 있습니다.</p>
      <h3><strong>제&nbsp;</strong><strong>3&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>요금</strong><strong>)</strong></h3>
      <p>1.&nbsp;회사는 수시로 서비스의 요금제 및 가격을 변경할 수 있으나,&nbsp;결제 시점의 가격과 기간을 기준으로 대금의 결제가 진행됩니다.</p>
      <h3><strong>제&nbsp;</strong><strong>4&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>본 서비스의 신청 자격</strong><strong>)</strong></h3>
      <p>1.&nbsp;본 서비스는 서울을 기준으로 제공됩니다.</p>
      <h3><strong>제&nbsp;</strong><strong>5&nbsp;</strong><strong>조&nbsp;</strong><strong>(Musio English ID&nbsp;</strong><strong>및 비밀번호</strong><strong>)</strong></h3>
      <p>1.&nbsp;본 서비스를 이용함에 있어서 회원은 당사 소정의 방식에 따라 회원 가입 후 Musio English ID&nbsp;및 암호(이하 통칭 &quot;<strong>Musio English ID&nbsp;</strong><strong>등</strong>&rdquo;)를 사용해야 합니다.</p>
      <p>2.&nbsp;당사는 Musio English ID&nbsp;등과 관련하여 회원이 가입 시 동의한 조항에 관련하여,&nbsp;제3자로 인한 손해를 포함한 그 어떠한 손해에 대해서도 당사는 책임을 지지 않습니다.</p>
      <h3><strong>제&nbsp;</strong><strong>6&nbsp;</strong><strong>조&nbsp;</strong><strong>(Musio English&nbsp;</strong><strong>회원 이용 약관의 준수</strong><strong>)</strong></h3>
      <p>본 서비스를 이용하는 회원은 당사가 별도로 정하는 Musio English&nbsp;회원 이용 약관의 내용에 동의하고 이를 준수해야 합니다.</p>
      <p>본 약관이 Musio English&nbsp;회원 이용 약관과 충돌하는 경우 본 약관의 내용이 우선하여 적용됩니다.</p>
      <h3><strong>제&nbsp;</strong><strong>7&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>본 서비스에 가입 신청</strong><strong>)</strong></h3>
      <p>1.&nbsp;본 서비스의 신청을 희망하는 회원은 당사 웹 사이트에서 당사 소정의 주문 절차를 거쳐,&nbsp;해당 주문에 대해 화상 수업,&nbsp;기기,&nbsp;콘텐츠를 구매할 수 있습니다.</p>
      <p>2.&nbsp;회사는 다음의 경우 회원의 신청을 승낙 거부하거나 승낙 후 취소 할 수 있습니다.&nbsp;이 때 회사는 신청 거절 및 취소 사유를 설명하지 않습니다.</p>
      <p>(1)&nbsp;이용 신청 회원이 존재하지 않는 경우 또는 회원이 법인 또는 개인 사업자에 해당하는 경우</p>
      <p>(2)&nbsp;당사가 본 서비스 이용자에 대한 통지&middot;연락이 객관적으로 불가능한 것으로 판단한 경우</p>
      <p>(3)&nbsp;회원이 기입한 정보에 허위 또는 부정확한 내용이 포함되어있는 경우</p>
      <p>(4)&nbsp;회원의 신용 카드 사용에 대해 신용 카드 회사에서 승인을 거절한 경우</p>
      <p>(5)&nbsp;당사 서비스 및 iPad에 대하여 당사 지정 신용 카드 회사를 통한 회원의 할부 구입 요청이 신용 카드 회사의 심사를 통과하지 못한 경우</p>
      <p>(6)&nbsp;본 서비스에 대하여 이용 정지 내역이 있는 회원 등 당사가 회원의 이용 또는 신청을 거절해야 한다고 판단한 경우</p>
      <p>(7)&nbsp;회원이 구매와 동시에 신청한 교재 또는 iPad가 배송 불가능한 상황이 된 경우</p>
      <p>(8)&nbsp;회사의 업무 수행 상 또는 기술적 어려움이 있는 경우</p>
      <p>(9)&nbsp;기타 당사가 부적당하다고 인정하는 경우</p>
      <p>3.&nbsp;회사는 본조 제 1&nbsp;항의 본 서비스 이용 계약의 성립 후 이유 여하를 불문하고 회원의 신청 취소는 접수하지 않습니다.</p>
      <h3><strong>제&nbsp;</strong><strong>8&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>신고 사항 등</strong><strong>)</strong></h3>
      <p>회원은 그 이름,&nbsp;주소 또는 당사에 신고한 신용 카드 정보 등 기타 당사가 지정하는 사항에 변경이 있을 시 당사에 대하여 신속하게 해당 변경 내용을 통지하여야 합니다.</p>
      <h3><strong>제&nbsp;</strong><strong>9&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>요금의 지불</strong><strong>)</strong></h3>
      <p>1.&nbsp;결제 비용은 회원이 이용한 결제 방식에 따라 청구됩니다.</p>
      <p>2.&nbsp;본 서비스는 일시불 또는 할부 결제 방식으로 운영되며,&nbsp;서비스는 신청 당시 결제 일자로부터 약정 기간 12개월 이후 서비스가 자동 만료됩니다.</p>
      <p>3.&nbsp;결제 완료 후 유료 수업 미진행 및 iPad&nbsp;미개봉 시 7일 이내 해당 결제 건에 대한 취소 및 환불이 가능합니다.</p>
      <h3><strong>제&nbsp;</strong><strong>10&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>결제 방법</strong><strong>)</strong></h3>
      <p>1.&nbsp;회원은 본 서비스의 요금을 당사가 지정한 카드사의 납부일까지 회원이 선택한 신용 카드로 지불해야 합니다.</p>
      <p>2.&nbsp;회원은 토스 페이먼츠를 통해 대금을 결제할 수 있습니다.</p>
      <p>3.&nbsp;계정 만료,&nbsp;잔고 부족 또는 기타 사유로 인하여 결제가 정상적으로 처리되지 않았음에도 회원이 등록 결제 수단을 변경하지 않거나 멤버십을 해지하지 않는 경우,&nbsp;유효한 결제 수단이 등록될 때까지 회원의 서비스 이용이 제한될 수 있습니다.</p>
      <p>4.&nbsp;회사에서 발행한 쿠폰은 명시된 기간 내에만 사용할 수 있으며 현금으로 교환은 불가능합니다.</p>
      <h3><strong>제&nbsp;</strong><strong>11&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>배송</strong><strong>)</strong></h3>
      <p>1.&nbsp;서비스 주문 시 입력한 수령 주소로 직접 배송 혹은 회사와 계약한 제휴 업체(SK Networks,&nbsp;택배업체,&nbsp;혹은 기타 물류업체)를 통해 제품을 배송합니다.</p>
      <p>2.&nbsp;회원이 서비스를 통해 제품을 대여한 후 반납을 원할 때는 회사가 규정한 절차에 따라 주문 시 입력한 반납 주소에서 제 1&nbsp;항과 동일한 방식을 통해 제품을 밀봉한 상태로 반납하여야 합니다.&nbsp;</p>
      <p><span size="3"><strong>제&nbsp;</strong></span><span size="3"><strong>12&nbsp;</strong></span><span size="3"><strong>조&nbsp;</strong></span><span size="3"><strong>(</strong></span><span size="3"><strong>제품 이용관리 책임</strong></span><strong>)</strong></p>
      <p>회원은 제품을 사용 및 보유하는 기간 동안 다음과 같은 제품 이용관리 책임이 있습니다.</p>
      <ol>
        <li>
          <p>회원은 제품 사용 전 제품에 이상이 없는지 확인해야 합니다.</p>
        </li>
        <li>
          <p>회원은 확인 후 제품에 손상이 있을 경우 회사에 그 사실을 통보해야 합니다.</p>
        </li>
        <li>
          <p>제품 손상으로 인해 사용이 불가할 경우 최대 세 번 제품 교환이 제공됩니다.&nbsp;</p>
        </li>
        <li>
          <p>제품의 사용을 시작하기 전에 제 2&nbsp;호에 따른 통보를 하지 않았을 경우,&nbsp;해당 상품의 파손 등에 대한 모든 책임은 회원에게 있으며,&nbsp;회사는 회원에게 제품 파손 비용 청구를 하거나 사용 정지 및 회원 자격의 상실 등을 통보할 수 있습니다.</p>
        </li>
        <li>
          <p>회원은 제품의 사용 후 제품의 손상 또는 도난 발생 시 바로 회사에 보고해야 합니다.</p>
        </li>
        <li>
          <p>교환 및 환불 대상 회원은 약속된 반납일 혹은 서비스 사용 중지 시에 제품 박스에 기기를 안전하게 밀봉하여 반납하여야 합니다.</p>
        </li>
        <li>
          <p>교환 및 환불 대상 회원은 제품 인수 시점부터 지정된 반납일의 반납 전까지 제품 사용에 관한 책임을 집니다.</p>
        </li>
      </ol>
      <p><span size="3"><strong>제&nbsp;</strong></span><span size="3"><strong>1</strong></span><span size="3"><strong>3</strong></span><strong>&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>금지 행위</strong><strong>)</strong></p>
      <p>1.&nbsp;본 서비스 이용 시 아래와 같은 행위를 금지하며,&nbsp;위반 시 회사로부터 제재가 가해지거나 손해배상 청구를 받을 수 있습니다.</p>
      <p>(1)&nbsp;허위의 정보를 본 등록 정보로 등록하는 행위</p>
      <p>(2)&nbsp;본 규약 등에 위반하는 행위</p>
      <p>(3)&nbsp;본 서비스를 부정한 수단에 의해 이용하는 행위</p>
      <p>(4)&nbsp;당사 또는 제3자의 저작권,&nbsp;초상권,&nbsp;프라이버시 등 기타 권리 또는 재산,&nbsp;명예,&nbsp;신용을 침해 &middot;&nbsp;훼손하는 행위 (제3자가 만든 교과서와 교육 과정 등을 무단으로 앱 및 웹사이트에 설치 및 게시하는 행위를 포함하되 이에 국한되지 않음)</p>
      <p>(5)&nbsp;당사 또는 제3자를 비방 또는 명예 &middot;&nbsp;신용을 훼손하는 행위</p>
      <p>(6)&nbsp;제3자가 Musio English ID&nbsp;등을 복제,&nbsp;복사,&nbsp;출판,&nbsp;판매 또는 대여 등 기타 일체 처분하는 행위</p>
      <p>(7)&nbsp;본 서비스의 운영을 방해하는 행위</p>
      <p>(8)&nbsp;본 서비스의 이용을 통한 당사의 사전 동의를 받지 않은 영업 행위</p>
      <p>(9)&nbsp;본 서비스의 이용을 통한 정치 활동,&nbsp;종교 활동</p>
      <p>(10)&nbsp;각 호 중 해당될 우려가 있다고 당사가 판단하는 행위</p>
      <p>(11)&nbsp;기타 당사가 본 서비스의 운영상 부적절하다고 판단하는 행위</p>
      <p>2.&nbsp;당사는 회원의 행위가 다음 항목 중 해당하는 경우 Musio English ID&nbsp;등의 사용 중지 또는 본 서비스의 제공 거부 또는 이용 제한 등(이하 &quot;<strong>Musio English ID&nbsp;</strong><strong>등의 사용 정지 등</strong>&quot;)을 할 수 있습니다.&nbsp;그러나 긴급한 필요가 있는 경우 다음 항목의 해당성을 불문하고 Musio English ID&nbsp;등의 사용 정지 등을 할 수 있습니다.</p>
      <p>(1)&nbsp;전항의 금지 사항 중 하나에 해당하는 경우</p>
      <p>(2)&nbsp;대금 등의 지급 채무의 이행 지연 또는 불이행이 있는 경우</p>
      <p>(3)&nbsp;전화,&nbsp;메일,&nbsp;카카오채널을 통해 연락을 취할 수 없는 경우</p>
      <p>(4)&nbsp;회원이 지정한 신용 카드를 사용할 수 없게 된 경우</p>
      <p>(5)&nbsp;제 7&nbsp;조 (본 서비스에 가입 신청)&nbsp;제 2&nbsp;항의 거절 사유에 해당하는 경우</p>
      <p>(6)&nbsp;기타 당사가 부적절하다고 판단한 경우</p>
      <h3><strong>제&nbsp;</strong><strong>14&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>당사에 의한 해제</strong><strong>)</strong></h3>
      <p>당사는 다음에 해당하는 사유가 있을 시 본 서비스의 이용 계약을 해지 할 수 있습니다.&nbsp;이 경우 당사는 본 서비스의 이용 계약이 종료 된 달까지의 이용 요금을 청구 할 수 있으며,&nbsp;이미 수강 완료한 화상 수업 횟수에 대한 요금은 반환하지 않습니다.</p>
      <p>(1)&nbsp;전조(금지 행위 등)의 규정에 따라 본 서비스의 이용이 정지 된 경우 회원이 해당 중단일 다음달 말까지 정지의 원인이 된 사유를 해소하지 않는 경우.&nbsp;다만,&nbsp;중지 사유가 제 1&nbsp;항 제 2&nbsp;호의 사유로 인한 경우에는 본 계약을 즉시 해지 할 수 있습니다.</p>
      <p>(2)&nbsp;회원이 배송 발송 후 1&nbsp;주일 이내에 교재 및 iPad를 받을 수 없는 경우</p>
      <h3><strong>제&nbsp;</strong><strong>15&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>회원 사정 등에 의한 본 서비스의 종료</strong><strong>)</strong></h3>
      <p>1.&nbsp;회원은 당사 소정의 절차에 따라 본 서비스의 취소 신청을 할 수 있습니다.&nbsp;</p>
      <p>2.&nbsp;제 9&nbsp;조의 제 3&nbsp;항 또는 제 12조의 제 2항에 해당하지 않을 경우, iPad&nbsp;기기 비용은 환불이 불가하며,&nbsp;회원은 잔여 할부금액을 지불 완료하여야 합니다.&nbsp;</p>
      <p>3.&nbsp;어떠한 사유로든 회사와 합의 또는 해지 신청을 하지 않고 서비스를 이용하지 않을 경우,&nbsp;서비스가 종료 되지 않고,&nbsp;따라서 서비스를 계속 이용한다고 간주됩니다.</p>
      <p>4.&nbsp;당사는 본 서비스의 계약이 종료되는 달까지의 요금을 청구할 수 있습니다.&nbsp;화상 수업 미 이용기간에 대한 정상 소비자가에서 취소 수수료 10%를 공제한 금액만 환불이 됩니다.</p>
      <p>5.&nbsp;서비스 종료를 원하는 경우 반드시 웹 사이트에서 본 서비스를 해지 신청해야 합니다.&nbsp;해지하지 않으시면 본 서비스의 이용료 및 할부 금액이 계속 부과됩니다.</p>
      <p>6.&nbsp;회원은 Musio English&nbsp;서비스 및 화상 수업 수강권을 다른 사람에게 양도할 수 없습니다.</p>
      <h3><strong>제&nbsp;</strong><strong>16&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>환불 정책</strong><strong>)</strong></h3>
      <p>1.&nbsp;회사는 회원이 수강권을 결제했던 방법으로 환불을 진행합니다.&nbsp;</p>
      <p>2.&nbsp;회사는 청약철회,&nbsp;해제 및 해지에 따른 환불의 경우,&nbsp;통지를 받은 날짜로부터 10&nbsp;영업일 이내에 대금 환불을 완료합니다.&nbsp;결제 대행사를 통해 환불이 이루어지는 경우 환불 시일은 해당 결제사의 사정에 따라 지연될 가능성이 있으며 회사는 결제사의 사정에 의한 환불 지연에 대해서 책임지지 않고 회원이 동의한 결제사의 약관에 따라 처리합니다.</p>
      <p>3.&nbsp;회원은 구매계약의 청약철회,&nbsp;해제 및 해지의 경우 반환이 가능한 일체의 콘텐츠를 반환하여야 할 의무가 있으며,&nbsp;콘텐츠 등을 반납하지 않을 경우 해당 콘텐츠 등의 비용을 제외한 금액만 환불됩니다.</p>
      <p>4.&nbsp;콘텐츠 환불 시 은행,&nbsp;카드사 등의 결제 수단에 대한 수수료는 제외하고 환불될 수 있습니다.</p>
      <p>5.&nbsp;회사는 다음과 같은 경우 환불을 거절할 수 있습니다.</p>
      <ol>
        <li>
          <p>회원의 실수로 구매로부터 첫 7일 이후의 이용 기간 내에 서비스를 이용하지 못한 경우</p>
        </li>
        <li>
          <p>환불 관련 비용이 잔여 이용료를 초과할 경우</p>
        </li>
        <li>
          <p>이미 서비스에서 탈퇴한 후,&nbsp;환불을 요구할 경우</p>
        </li>
        <li>
          <p>단시간 또는 일회성으로 이용할 수 있는 콘텐츠에 대한 환불을 요구하는 경우</p>
        </li>
        <li>
          <p>기타 정당한 환불사유로 판명되지 않은 경우</p>
        </li>
      </ol>
      <p>6.&nbsp;본 계약이 해지될 경우 회원은 월의 실제 사용일까지 사용 기간에 비례하여 정산된 사용금액을 회사에 지급하여야 합니다.&nbsp;단,&nbsp;제품의 반납 시 훼손,&nbsp;분실 된 경우에는 비용을 추가로 부담하셔야 합니다.</p>
      <p>7.&nbsp;계약의 중도해지 시 회사에 제품 및 구성품(포장박스와 사은품 포함)을 반납하고,&nbsp;아래와 같은 위약금을 지급하여야 합니다.</p>
      <ol>
        <li>
          <p>정상적인 상태의 상품 및 구성품(내부 포장박스와 사은품 포함)을 반납하는 경우</p>
          <ol type="a">
            <li>
              <p>중도해지 위약금:&nbsp;할인반환율 100%&nbsp;</p>
            </li>
          </ol>
        </li>
        <li>
          <p>회원이 반납한 제품 및 구성품(내부 포장박스와 사은품 포함)이 정상적으로 작동되지 않거나,&nbsp;제품 및 구성품(내부 포장박스와 사은품포함)이 전부 반납되지 않은 경우</p>
          <ol type="a">
            <li>
              <p>중도해지 위약금:&nbsp;할인반환율 100% +&nbsp;미반납 혹은 손상된 상품 및 구성품의 금액</p>
            </li>
          </ol>
        </li>
      </ol>
      <h3><strong>제&nbsp;</strong><strong>17&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>수강권</strong><strong>)</strong></h3>
      <ol>
        <li>
          <p>화상 수업은 1회 25분이며,&nbsp;구매한 총 횟수 내에서 자유롭게 수업을 예약하고 진행 가능합니다.</p>
        </li>
        <li>
          <p>화상 수업은 일일 수업 횟수에 제한이 없으며,&nbsp;월 수업 횟수는 패키지에 따른 제한이 있습니다.&nbsp;</p>
        </li>
        <li>
          <p>구매하신 패키지에 포함된 콘텐츠는 수강 기간 내내 사용할 수 있습니다.</p>
        </li>
        <li>
          <p>수강권은 이용 중 정지가 불가능합니다.</p>
        </li>
        <li>
          <p>화상 수업은 크롬에서만 사용이 가능하며,&nbsp;크롬 사용 불가능한 상황일 시 7일 내에 수강 기록 없이 환불을 요청하면 환불이 가능합니다.</p>
        </li>
        <li>
          <p>모든 수업시간은 KST(UTC+09:00)&nbsp;한국 기준으로 운영됩니다.</p>
        </li>
      </ol>
      <h3><strong>제&nbsp;</strong><strong>18&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>수강권의 환불</strong><strong>)</strong></h3>
      <ol>
        <li>
          <p>수강권 환불 가능 기간은 결제일을 기준으로 계산됩니다.</p>
        </li>
        <li>
          <p>수강권 구매 후 7일 이내 수강 기록이 없고,&nbsp;상품 배송 전일 경우 배송비를 제외한 전액 환불이 가능합니다.</p>
        </li>
        <li>
          <p>회사가 상품 발송 후 서비스 취소 시에는 왕복 배송료 1만원을 제외하고 회원에게 환불 됩니다.</p>
        </li>
        <li>
          <p>회원이 상품을 배송 받은 후 다음 중 해당 사항이 있을 시 반품 및 교환이 불가합니다.</p>
        </li>
        <li>
          <p>이용자에게 책임있는 사유로 상품 등이 멸실 또는 훼손된 경우</p>
        </li>
        <li>
          <p>이용자의 사용 또는 일부 소비에 의하여 상품 등의 가치가 현저히 감소한 경우</p>
        </li>
        <li>
          <p>시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우</p>
        </li>
        <li>
          <p>같은 성능을 지닌 상품 등으로 복제가 가능한 경우 원본 상품의 포장을 훼손한 경우</p>
        </li>
        <li>
          <p>수강권의 취소는 반드시 다음 결제일 전에 회사에 통지해야 합니다.</p>
        </li>
      </ol>
      <h3><strong>제&nbsp;</strong><strong>19&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>권리 의무의 양도 제한 등</strong><strong>)</strong></h3>
      <p>회원은 본 서비스의 이용 계약에 의해 얻은 계약상의 권리 및 의무의 전부 또는 일부 혹은 해당 계약 상의 지위를 타인에게 양도하거나 담보로 제공 또는 승계하거나 기타 방법으로 처분을 해서는 안됩니다.</p>
      <h3><strong>제&nbsp;</strong><strong>20&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>면책 사항</strong><strong>)</strong></h3>
      <p>1.&nbsp;당사는 회원이 본 서비스의 이용과 관련하여 입은 직접 또는 간접적 손해 (데이터 손실 또는 손상,&nbsp;통신 장애,&nbsp;업무 정체,&nbsp;업체의 요구 등을 포함하되 이에 국한되지 않음)에 대해 책임을 지지 않습니다.&nbsp;그러나 해당 손해에 대해서 당사에 고의 또는 중과실이 있는 경우에는 그러하지 않습니다.</p>
      <p>2.&nbsp;회원이 본 약관을 위반하는 행위 또는 부정 또는 불법 행위로 인하여 제3자로부터 받은 클레임이나 손해 배상 청구 등에 대해서는 회원의 책임과 부담으로 해결하며 당사는 책임을 지지 않습니다.</p>
      <h3><strong>제&nbsp;</strong><strong>21&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>지적 재산권</strong><strong>)</strong></h3>
      <p>1.&nbsp;당사는 본 약관에 따른 서비스를 제공함으로써 Musio English에 관한 모든 지적 재산권(저작권,&nbsp;특허권,&nbsp;실용신안권,&nbsp;의장권 및 상표권 및 기밀 정보의 공개 후 본 프로그램을 진행 과정에서 기밀 정보에서 파생 생긴 이러한 권리를 말하며,&nbsp;이러한 권리를 취득 또는 등록 등을 출원 할 권리,&nbsp;기타 아이디어,&nbsp;노하우,&nbsp;개념 및 기술 정보 등을 포함.&nbsp;저작권에 대해 저작권법 제 27&nbsp;조 및 동 제 28&nbsp;조에 규정 된 권리를 포함)을 회원에게 양도하거나 허가하지 않습니다.</p>
      <p>2.&nbsp;당사는 회원이 본 서비스의 이용에 있어서 Musio English에 대해 리버스 엔지니어링,&nbsp;디컴파일,&nbsp;분해 등의 분석 행위,&nbsp;소스 코드,&nbsp;알고리즘,&nbsp;노하우 등의 정보를 얻으려고하는 행위 등 당사의 권리 또는 이익을 침해하는 행위를 금하며,&nbsp;제3자에게 행하지 않는 것으로 합니다.</p>
      <p>3.&nbsp;회원이 본 서비스 이용 과정에서 전항을 위반하지 않고 Musio English&nbsp;관련 기술의 발명,&nbsp;노하우 등의 기술적 성과를 얻기에 이르렀을 경우 즉시 그 사실을 당사에 보고합니다.&nbsp;이에 대한 기술적 성과에 관한 일체의 지적 재산권은 당사에 귀속합니다.</p>
      <p>4.&nbsp;전항의 기술적 성과에 관한 일체의 지적 재산권의 귀속 및 취급에 대해서는 당사와 회원이 별도 협의하여 이를 결정하는 것으로,&nbsp;이러한 협의에 의해 귀속이 결정 할 때까지 해당 기술 성과 및 이와 관련된 정보는 타인에게 공개 또는 누설할 수 없는 기밀 정보로 간주합니다.</p>
      <h3><strong>제&nbsp;</strong><strong>22&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>손해 배상</strong><strong>)</strong></h3>
      <p>회원이 본 약관을 위반하는 행위 또는 부정 또는 불법 행위로 인하여 회사에 손해를 끼친 경우,&nbsp;회원은 당사에 대하여 당사가 직접 또는 간접적으로 입은 모든 손해(직간접적 손해 및 변호사 비용을 포함하되 이에 국한되지 않음)을 배상 할 책임을 갖습니다.</p>
      <h3><strong>제&nbsp;</strong><strong>23&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>약관의 변경</strong><strong>)</strong></h3>
      <p>우리는 미리 당사 웹 사이트를 통해 사전에 고지한 후 본 약관의 내용을 변경할 수 있습니다.&nbsp;그 변경의 효력이 발생한 후,&nbsp;본 서비스를 이용하는 회원은 본 약관의 변경 사항에 동의 한 것으로 간주합니다.</p>
      <h3><strong>제&nbsp;</strong><strong>24&nbsp;</strong><strong>조&nbsp;</strong><strong>(</strong><strong>협의 해결의 원칙 및 관할 법원</strong><strong>)</strong></h3>
      <p>본 규약 등에 정하지 아니한 사항이나 약관 등에 관한 의문이 생겼을 경우는 회원과 회사 사이에서 성실하게 협의 해결하는 것으로 합니다.&nbsp;협의를 해도 해결되지 않으면 본 서비스에 관한 분쟁은 서울 지방 법원을 관할 법원으로 합니다.</p>
      <p><br/></p>
      <p>2022 년 08 월 26 일</p>
    </div>
  );
}

