import React, {useState, useEffect} from 'react';
import {Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {kakaoAuth} from 'Utils/Kakao';
import {authState, myState} from 'GlobalState';
import {useRecoilValue} from 'recoil';
import Loading from '../../Components/Loading';
import 'Styles/AuthSocialLogin.css';



export function KakaoLoginScreen() {
  const CLIENT_ID = '6b23f84b45b5fb06d9395fee33a9e03c';
  const REDIRECT_URI = 'https://musioenglish.kr/auth/kakaologin';
  const SCOPE = 'openid';
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}`;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const me = useRecoilValue(myState);
  const auth = useRecoilValue(authState);
  let code = new URL(window.location.href).searchParams.get('code');

  useEffect(() => {
    if(code) {
      setLoading(true);
      const kakaoData = {
        code: code,
        redirectUri: REDIRECT_URI
      };
      kakaoAuth(kakaoData);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if(auth) {
        setLoading(false);
        navigate('/mypage/userInfo');
        window.location.reload();
      }
    }, 3000,
    );

  }, [me, auth]);

  return (
    <div id='auth-kakao'>
      {loading ? <Loading/> : null}
      <div className='auth-options'>
        <span className='kakao-header'>실전 스토리 학습지와<br/>인공지능 자율 회화까지<br/><span>뮤지오 잉글리시를 시작하세요!</span></span>
        {/* <span>⬇️ 지금 회원가입하고 무료 샘플 받기 ⬇️</span> 화살표 부분이 제대로 표시되지 않아 이미지로 적용예정*/}
        <img className='signup-sample' src='/images/mainPage/signupSample.svg'/>
        <Button
          className='login-button'
          variant="contained"
          style={{backgroundColor: '#FEE500', color: '#392020'}}
          href={KAKAO_AUTH_URL}
        >
          <img src='/images/misc/kakaoIcon2.svg'/>
         카카오 계정으로 시작하기
        </Button>
        <Button
          variant="contained"
          className="login-button email"
          color="white"
          onClick={() => navigate('/auth/login')}
        >
          이메일로 시작하기
        </Button>
        <div style={{marginBottom:'80px'}}/>
      </div>
    </div>
  );
}

