import React, {useState} from 'react';
import {Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import {useResponsive} from 'Hooks';
import ServiceTerms from 'Components/ServiceTerms.js';
import PrivacyTerms from 'Components/PrivacyTerms.js';

import 'Styles/Navbar.css';

export default function Footer() {
  const {device} = useResponsive();
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  return (
    <div id='footer'>
      <div className='limit-section'>
        <div className='responsive-align'>
          <img className='logo-img' src='/images/navbar/logoWhite.png'/>
          <span className='title'>미래형 영어교육의 시작, 뮤지오 잉글리시</span>

          <div className='family-dropdown'>
            <div className="footer-button">
              <span className="family-text">Family Site</span>
              <img className='before-hover' src='/images/misc/open.svg'/>
              <img className='after-hover' src='/images/misc/close.svg'/>
            </div>
            <ul className='dropdown-menu'>
              <li><a href='https://akaintelligence.com/kr/'>AKA Intelligence</a></li>
              <li><a href='https://kids.musioenglish.kr'>Musio English KIDS</a></li>
            </ul>
          </div>

        </div>

        <hr className='divide-section'/>

        <span className='company-content'>
          주식회사 아카에이아이 | 사업자 번호: 346-88-01621 | 통신판매신고번호: 2021-서울중구-0219호 <br/>
          대표자: 정명원 | 소재지: 서울특별시 중구 청계천로 100, 9층, 10층 (수표동, 시그니쳐타워) <br/>
          <br/><br/><a className='company-content' href="tel:02-537-7201">Tel : 02-537-7201</a><br/>
          <a
            className='company-content' href="mailto:support@akaai.kr">Email: support@akaai.kr</a>
        </span>



        <div className='responsive-align'>
          {device != 'mobile' && <div style={{marginTop: '250px'}}/>}
          <span className='content expanded'>COPYRIGHT © 2022 (주)아카에이아이. ALL RIGHTS RESERVED.</span>
          <div className='policy-align'>
            <a className='content' onClick={() => setOpenTerms(true)}>이용약관</a>
            <Dialog
              open={openTerms}
              onClose={() => setOpenTerms(false)}
            >
              <DialogTitle>
                {'약관 체결의 성립 동의'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <ServiceTerms/>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="cancel" onClick={() => setOpenTerms(false)}>취소</Button>
                <Button variant="contained" onClick={() => setOpenTerms(false)}>확인</Button>
              </DialogActions>
            </Dialog>
            <a className='content' onClick={() => setOpenPrivacy(true)}>개인정보방침</a>
            <Dialog
              open={openPrivacy}
              onClose={() => setOpenPrivacy(false)}
            >
              <DialogTitle>
                {'개인정보 처리 방침'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <PrivacyTerms/>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="cancel" onClick={() => setOpenPrivacy(false)}>취소</Button>
                <Button variant="contained" onClick={() => setOpenPrivacy(false)}>확인</Button>
              </DialogActions>
            </Dialog>
            <div className='icon-row'>
              <a href="https://www.youtube.com/channel/UCsg7gJOqwwZ6H4REN9O6PoA"><img className='icon youtube' src='/images/misc/youtubeIcon.png'/></a>
              <a href="https://blog.naver.com/akaai2021"><img className='icon blog' src='/images/misc/blogIcon.png'/></a>
              <a href="https://pf.kakao.com/_MCBMb"><img className='icon kakao' src='/images/misc/kakaoIcon.png'/></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}